const transitions: any = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd'
};
const animations: any = {
  animation: 'animationend',
  OAnimation: 'oAnimationEnd',
  MozAnimation: 'animationend',
  WebkitAnimation: 'webkitAnimationEnd'
};

const el: HTMLElement = document.createElement('fakeelement');

function whichTransitionEvent() {
  for (const t in transitions) {
    if (el.style[t as any] !== undefined) {
      return transitions[t];
    }
  }
}

function whichAnimationEvent() {
  for (const t in animations) {
    if (el.style[t as any] !== undefined) {
      return animations[t];
    }
  }
}

const transitionEvent = whichTransitionEvent();
const animationEvent = whichAnimationEvent();

function customFunction(this: any, resolve: any, trigEvent: any, event?: any): void {
  this.removeEventListener(trigEvent, customFunction);
  if (resolve) {
    resolve();
  }
}

export function whenTransitionEnds(elem: HTMLElement): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    elem.addEventListener(transitionEvent, customFunction.bind(elem, resolve, transitionEvent));
  });
}

export function whenAnimationEnds(elem: HTMLElement): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    elem.addEventListener(animationEvent, customFunction.bind(elem, resolve, animationEvent));
  });
}
