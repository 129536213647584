import { Directive, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[trackMouseFocus]'
})
export class TrackMouseFocusDirective implements OnDestroy {
  private _usingMouse = false;

  private readonly _bodyKeyDownFunc: () => void;
  private readonly _bodyMouseDownFunc: () => void;
  private readonly _bodyFocusInFunc: () => void;
  private readonly _bodyFocusOutFunc: () => void;

  constructor(renderer: Renderer2) {
    this._bodyKeyDownFunc = renderer.listen('body', 'keydown', this.preFocus.bind(this));
    this._bodyMouseDownFunc = renderer.listen('body', 'mousedown', this.preFocus.bind(this));
    this._bodyFocusInFunc = renderer.listen('body', 'focusin', this.addFocus.bind(this));
    this._bodyFocusOutFunc = renderer.listen('body', 'focusout', this.removeFocus.bind(this));
  }

  ngOnDestroy() {
    // Remove the listeners!
    this._bodyKeyDownFunc();
    this._bodyMouseDownFunc();
    this._bodyFocusInFunc();
    this._bodyFocusOutFunc();
  }

  private preFocus(event: any) {
    this._usingMouse = (event.type === 'mousedown');
  }

  private addFocus(event: any) {
    if (this._usingMouse) {
      event.target.classList.add('focus--mouse');
    }
  }

  private removeFocus(event: any) {
    event.target.classList.remove('focus--mouse');
  }
}
