export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function deepRemoveProperty(obj: any, propToDelete: string) {
  for (const property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue;
    }
    if (typeof obj[property] === 'object') {
      obj[property] = deepRemoveProperty(obj[property], propToDelete);
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
}

export function deepObjectUpdate(obj: any, update: (propName: string, currentValue: any) => any) {
  for (const property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue;
    }
    if (typeof obj[property] === 'object') {
      obj[property] = deepObjectUpdate(obj[property], update);
    } else {
      obj[property] = update(property, obj[property]);
    }
  }
  return obj;
}
