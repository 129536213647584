import { readOnlyEntity } from '@myia/ngx-core';
import { ICalendarEventReservation } from './calendarEventReservation.interface';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function CalendarEventReservation(props: ICalendarEventReservation): ICalendarEventReservation {
    return readOnlyEntity({
      // @ts-ignore: TS2783
        id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.event),
      // @ts-ignore: TS2783
        type: undefined,
      // @ts-ignore: TS2783
        start: undefined,
        end: undefined,
        title: undefined,
        color: undefined,
        icon: undefined,
        template: undefined,
        meta: undefined,
        //
      // @ts-ignore: TS2783
        reservationId: undefined,
      // @ts-ignore: TS2783
        reservationDesc: undefined,
      // @ts-ignore: TS2783
        reservationLocation: undefined,
        slotId: undefined,
      // @ts-ignore: TS2783
        reserved: false,
        //
        updating: false,
        ...props
    });
}

