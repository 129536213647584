import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input,
  OnChanges, Output, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Side } from '@floating-ui/dom';
import { copyToClipboard, NgChanges } from '@myia/ngx-core';
import { PickerComponent } from '@myia/ngx-forms';
import { hexStringToRgb, rgbToHex } from '../utils/colorUtils';

@Component({
  selector: 'color-picker',
  templateUrl: './colorPicker.component.html',
  styleUrls: ['./colorPicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent extends PickerComponent<string> implements ControlValueAccessor, OnChanges {
  @Input() selectedColor?: string;

  @Input() palette?: Array<string>;

  @Input() nullable = false;

  @Input() popupSide = 'left' as Side;

  @Output() pickerVisible = new EventEmitter<boolean>();

  @HostBinding('class') hostClasses = 'colorPicker formControl';

  @ViewChild('field') field?: ElementRef;

  touched = false;

  @HostBinding('class.disabled')
  disabled = false;

  editedRGBValue?: { r?: string, g?: string, b?: string };

  private _onChange?: (color: string | undefined) => void;
  private _onTouched?: () => void;

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  override toggle() {
    if (!this.disabled) {
      super.toggle();
    }
  }

  ngOnChanges(changes: NgChanges<ColorPickerComponent>) {
    if (changes.selectedColor) {
      this.setSelectedColor(changes.selectedColor.currentValue, true);
    }
  }

  expand() {
    if (!this.dropDownDisplayed) {
      this.toggle();
    }
  }

  selectColor(color: string | undefined) {
    this.markAsTouched();
    if (!this.disabled) {
      this.setSelectedColor(color);
      if (this._onChange) {
        this._onChange(color);
      }
      this.itemSelected.emit(color);
    }
  }

  registerOnChange(onChange: (value: string | undefined) => void) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: string): void {
    this.selectedColor = value;
  }

  markAsTouched() {
    if (!this.touched) {
      if (this._onTouched) {
        this._onTouched();
      }
      this.touched = true;
    }
  }

  pickerColorChanged(color: string) {
    this.selectColor(color);
  }

  rgbColorChanged(value: { r?: string, g?: string, b?: string }) {
    this.editedRGBValue = {
      ...this.editedRGBValue,
      ...value
    };
    if (this.editedRGBValue.r && this.editedRGBValue.g && this.editedRGBValue.b) {
      this.selectColor(rgbToHex(parseInt(this.editedRGBValue.r, 10), parseInt(this.editedRGBValue.g, 10), parseInt(this.editedRGBValue.b, 10)));
    } else {
      if (!this.editedRGBValue.r && !this.editedRGBValue.g && !this.editedRGBValue.b) {
        this.selectColor(undefined);
      }
    }
  }

  copyHexColorToClipboard() {
    if (this.selectedColor) {
      copyToClipboard(this.selectedColor).then((success) => {
        if (success) {
          console.log(`Color ${this.selectedColor} copied to clipboard.`);
        }
      });
    }
  }

  pickerVisibilityChanged(visible: boolean) {
    this.pickerVisible.emit(visible);
  }

  clear($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectColor(undefined);
  }

  private setSelectedColor(color: string | undefined, force = false) {
    this.selectedColor = color;
    const rgb = color ? hexStringToRgb(color) : null;
    this.editedRGBValue = {
      ...this.editedRGBValue,
      ...(rgb || force ? {
        r: rgb?.r.toString(),
        g: rgb?.g.toString(),
        b: rgb?.b.toString(),
      } : {})
    };
  }

}
