import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { TranslateModule } from '@ngx-translate/core';
import { DefaultTranslationPipe } from './defaultTranslationPipe';

import { FormatDatePipe } from './formatDatePipe';
import { TransPipe } from './transPipe';
import { CultureService } from './services/cultureService';
import { ILocalizationModuleConfig } from './localizationConfig';
import { TranslateLoaderWrapper } from './translateLoaderWrapper';
import { TranslateWithLinksDirective } from './translateWithLinksDirective';
import { ParseDatePipe } from './parseDatePipe';

export function appInitializerFactory(
    cultureService: CultureService
): () => Promise<void> {
    return () => {
        return new Promise<void>(resolve => {
          cultureService.initialize().subscribe(() => {
            resolve();
          });
        });
    };
}

export function TranslationLoaderFactory(injector: Injector) {
    return new TranslateLoaderWrapper(injector);
}

// @dynamic
@NgModule({
    imports: [
        CoreModule,
        TranslateModule.forChild({})
    ],
    declarations: [FormatDatePipe, ParseDatePipe, TranslateWithLinksDirective, TransPipe, DefaultTranslationPipe],
    exports: [FormatDatePipe, ParseDatePipe, TranslateModule, TranslateWithLinksDirective, TransPipe, DefaultTranslationPipe],
})
export class LocalizationModule {
    static forRoot(
        localizationModuleConfig: ILocalizationModuleConfig
    ): ModuleWithProviders<LocalizationModule> {
        return {
            ngModule: LocalizationModule,
            providers: [
                localizationModuleConfig.config,
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitializerFactory,
                    deps: [CultureService],
                    multi: true
                }
            ] as Provider[]
        };
    }
}
