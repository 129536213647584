import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input,
  OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { extract, getOppositeColor, hexStringToRgb, IColorStat, rgbToHex } from '@myia/ngx-color-picker';
import {
  calculateAspectRatioFit, EmitterService, getCanvasBlob, getFileObjectUrl, IPhotoBlobData, LAYOUT_CHANGED, resizeImage,
  revokeFileObjectUrl
} from '@myia/ngx-core';
import * as Cropper from 'cropperjs';
import { find, propEq } from 'ramda';
import { fromEvent, Observable, Observer, of, Subscription } from 'rxjs';
import { map, mergeMap, tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'photo-picker',
  styleUrls: ['./photoPickerComponent.component.scss'],
  template: `
    <div class="pickerContent" [ngClass]="{loaded: isLoaded, finished: cropperSourceLoaded && isInitialized}">
      <div class="colors" [ngClass]="{show: isTransparent || showColorsPicker}">
        <div class="colorsTitle">{{'PhotoPicker.PhotoPicker_Select_Photo_Background'|trans}}</div>
        <div class="sectionSwitchBlock">
          <button (click)="toggleColorSections()" class="switchBtn">
            <svg-icon name="eye-dropper" *ngIf="showDominantColors"></svg-icon>
            <svg-icon name="palette" *ngIf="!showDominantColors"></svg-icon>
          </button>
        </div>
        <div class="colorWheelBlock" [ngClass]="{showSection: !showDominantColors}">
          <color-wheel-picker [color]="color" (colorChanged)="pickerColorChanged($event)" [width]="200" [height]="200"
                              [disabled]="!isTransparent && !showColorsPicker"></color-wheel-picker>
        </div>
        <div class="dominantColors" [ngClass]="{showSection: showDominantColors}">
          <div class="colorBlock" *ngFor="let c of colors; let i = index; trackBy: trackColor"
               [style.backgroundColor]="c.hex" (click)="changeColor(i)" [ngClass]="{selected: selectedIndex === i}"
               [tooltip]="{html: c.hex}" tooltipPlacement="top"
               [tooltipEnable]="isInitialized && isCompleted && (selectedIndex === i) && (isTransparent || showColorsPicker)"
               [tooltipOffset]="[0,10]" tooltipTrigger="none" [tooltipDontHideOnChange]="true"></div>
        </div>
        <div>
          <button class="colorPickBtn" (click)="pickColorFromImage()" [ngClass]="{active: isPickerActive}">
            <svg-icon name="eye-dropper"></svg-icon>
            <span>{{'ColorPicker.Pick_Color' |trans}}</span></button>
        </div>
      </div>
      <div class="imageWrapper">
        <div class="imageWrapperInner" [style.backgroundColor]="color">
          <div #wrapper class="cropperWrapper" [ngStyle]="{maxWidth: minWidth | cropperWidth: sourceWidth}"
               [ngClass]="{loaded: cropperSourceLoaded, finished: isCompleted, withPicker: isPickerActive}"
               (mouseWheel)="onMouseWheel($event)" (click)="pickColor()">
            <img #img class="sourceImage"
                 [ngClass]="{vector: isVector, loaded: isLoaded, landscape: (maxWidth ?? MAX_VALUE) > (maxHeight ?? MAX_VALUE)}"/>
          </div>
        </div>
        <div class="imageControls">
          <input-slider [min]="minZoom" [max]="maxZoom" [step]="zoomStep" [value]="zoom" [config]="sliderConfig"
                        (valueChange)="sliderValueChange($event)" (end)="sliderEnd($event)"></input-slider>
        </div>
      </div>
    </div>
    <div class="loading" *ngIf="!isLoaded || !cropperSourceLoaded || !isInitialized">
      <div>{{loadingState|trans}}</div>
      <progress-indicator-circle indicatorClass="busyIndicator"></progress-indicator-circle>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoPickerComponent implements AfterViewInit, OnInit, OnDestroy {

  private static _cropperLib: any = null;
  @Input() sourceImageUrl?: string;
  @Input() sourceFile?: File;
  @Input() sourceWidth?: number;
  @Input() sourceHeight?: number;
  @Input() isVector = false
  @Input() maxWidth?: number;
  @Input() maxHeight?: number;
  @Input() minWidth?: number;
  @Input() aspectRatio?: number;
  @Input() rotation?: number;
  @Input() isTransparent = false;
  @Input() flipVertical = false;
  @Input() flipHorizontal = false;
  @Input() color?: string;
  @Output() initialized = new EventEmitter<PhotoPickerComponent>();

  colors?: Array<IColorStat>;
  showColorsPicker = false;
  selectedIndex = 0;
  isPickerActive = false;
  isInitialized = false;
  isLoaded = false;
  isCompleted = false;
  cropperSourceLoaded = false;
  loadingState = 'Loading';

  showDominantColors = false;

  zoom = 100;
  minZoom = 20;
  maxZoom = 180;
  zoomStep = 1;

  MAX_VALUE = Number.MAX_VALUE;

  sliderConfig = {
    pips: {
      mode: 'values',
      density: 10,
      values: [] as Array<number>
      // values: [20,100,200],
      // format: ({
      //     decimals: 0,
      //     to: (a:any) => {
      //         return `${a}%`;
      //     }
      // })
    }
  };

  @ViewChild('img', {static: true}) imageRef?: ElementRef;
  @ViewChild('wrapper', {static: true}) wrapperRef?: ElementRef;

  private _pickerMouseOverHandler: any;
  private _cropper?: Cropper;
  private _zoomRatio?: number;
  private _mouseWheelObserver?: Observer<void>;
  private _mouseWheelSubscription?: Subscription;
  private _colorPickObserver?: Observer<void>;
  private _colorPickSubscription?: Subscription;
  private _cropperReadyObserver?: Observer<void>;
  private _cropperReadySubscription?: Subscription;

  private _canvasContext: CanvasRenderingContext2D | null = null;
  private _cropperViewBoxEl: any;
  private _sourceBlobUrl?: string;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    this._pickerMouseOverHandler = this.pickColorFromCanvasPoint.bind(this);
  }

  ngOnInit() {
    this.isInitialized = false;
    this.isCompleted = false;
    this.initialized.emit(this);
  }

  ngAfterViewInit(): void {
    const imageElement = new Image();
    fromEvent(imageElement, 'load')
      .pipe(
        mergeMap(() => {
          this.setImageUrl(imageElement.src);
          this.isLoaded = true;
          this._changeDetectorRef.markForCheck();
          return fromEvent(this.imageRef?.nativeElement, 'load');
        }),
        tap(() => {
          setTimeout(() => {
            this.cropperSourceLoaded = true;
            this.loadingState = 'PhotoPicker.Analyzing_Image';
            this._changeDetectorRef.markForCheck();
            setTimeout(() => {
              // create cropper/canvas and analyze colors
              this.createCropper(true, false);
            });
          }, 100);
        })
      )
      .subscribe();

    const minWidth = this.minWidth ?? 0;
    // apply rotation or/and extend image canvas to min width(for bitmaps)
    if (this.sourceFile && (this.rotation || (!this.isVector && minWidth > this.sourceWidth!))) {
      const swapDimensions: boolean = !!(this.rotation && (this.rotation % 180) !== 0);
      const srcWidth = swapDimensions ? this.sourceHeight! : this.sourceWidth!;
      const imageWidth = Math.max(minWidth, srcWidth);
      const srcHeight = swapDimensions ? this.sourceWidth! : this.sourceHeight!;
      resizeImage(this.sourceFile, imageWidth, srcHeight, undefined, {
        angle: this.rotation ?? 0,
        flipVertical: false,
        flipHorizontal: false
      }, this.minWidth, undefined)
        .pipe(
          mergeMap((rotatedImage: IPhotoBlobData) => {
            return getFileObjectUrl(rotatedImage.blob);
          })
        )
        .subscribe((rotatedImageUrl: string) => {
          this._sourceBlobUrl = rotatedImageUrl;
          imageElement.src = rotatedImageUrl;
        });
    } else {
      imageElement.src = this.sourceImageUrl ?? '';
    }
  }

  ngOnDestroy(): void {
    if (this._sourceBlobUrl) {
      revokeFileObjectUrl(this._sourceBlobUrl);
    }
    if (this._mouseWheelSubscription) {
      this._mouseWheelSubscription.unsubscribe();
    }
    this._mouseWheelObserver = undefined;

    if (this._colorPickSubscription) {
      this._colorPickSubscription.unsubscribe();
    }
    this._colorPickObserver = undefined;

    if (this._cropperReadySubscription) {
      this._cropperReadySubscription.unsubscribe();
    }
    this._cropperReadyObserver = undefined;

    if (this._cropper) {
      this._cropper.destroy();
    }

    if (this._cropperViewBoxEl) {
      this._cropperViewBoxEl.removeEventListener('mousemove', this._pickerMouseOverHandler);
    }
  }

  @HostListener('window:resize')
  windowResize() {
    this.createCropper(false, true);
  }


  pickerColorChanged(color: string) {
    if (color) {
      this.setPickerColor(color);
      this._changeDetectorRef.markForCheck();
    }
  }

  pickColor() {
    if (this.isPickerActive) {
      this.pickColorFromImage();
      this._changeDetectorRef.markForCheck();
    }
  }

  pickColorFromImage() {
    if (!this.isPickerActive) {
      this.isPickerActive = true;
      this.setPickerColor(this.color);
      this._cropperViewBoxEl = this.wrapperRef?.nativeElement.querySelector('.cropper-view-box');
      if (this._cropperViewBoxEl) {
        this._cropperViewBoxEl.addEventListener('mousemove', this._pickerMouseOverHandler, false);
      }
    } else {
      this.isPickerActive = false;
      if (this._cropperViewBoxEl) {
        this._cropperViewBoxEl.removeEventListener('mousemove', this._pickerMouseOverHandler);
      }
    }
    this._changeDetectorRef.markForCheck();
  }

  pickColorFromCanvasPoint(event: any) {
    if (!this._colorPickObserver) {
      this._colorPickSubscription =
        new Observable((observer: Observer<any>) => {
          this._colorPickObserver = observer;
        }).pipe(
          throttleTime(100, undefined, {
            leading: true,
            trailing: true
          }) // wait 100ms after the last event before emitting last event
        ).subscribe((e: any) => {
          if (!this._canvasContext) {
            this.updateCanvas();
          }
          if (this._canvasContext && this._zoomRatio) {
            const {clientX, clientY} = e;
            const boundingRect = this._cropperViewBoxEl.getBoundingClientRect();
            // get image data
            const scale = this._zoomRatio * this.zoom;
            const p = this._canvasContext.getImageData((clientX - boundingRect.left) / scale, (clientY - boundingRect.top) / scale, 1, 1).data;
            // set pick color
            this.setPickerColor(p[3] ? rgbToHex(p[0], p[1], p[2]) : this.color);
            this._changeDetectorRef.markForCheck();
          }
        });
    }

    this._colorPickObserver?.next(event);
  }

  changeColor(index: number) {
    if (index !== this.selectedIndex && this.colors) {
      this.selectedIndex = index;
      const color = this.colors[index];
      this.color = color.hex;
      this._changeDetectorRef.markForCheck();
    }
  }

  trackColor(index: number, color: IColorStat): string {
    return color.hex;
  }

  toggleColorSections() {
    this.showDominantColors = !this.showDominantColors;
    setTimeout(() => {
      EmitterService.getEvent(LAYOUT_CHANGED).emit(); // notify 'current color' tooltip to update position
    });
  }

  sliderValueChange(newValue: number) {
    if (this._cropper && this._zoomRatio) {
      this._cropper.zoomTo(newValue * this._zoomRatio);
    }
  }

  sliderEnd(newValue: number) {
    this.zoom = newValue;
    this._changeDetectorRef.markForCheck();
  }

  onMouseWheel(e: any) {
    if (!this._mouseWheelObserver) {
      this._mouseWheelSubscription =
        new Observable((observer: Observer<any>) => {
          this._mouseWheelObserver = observer;
        }).pipe(
          throttleTime(50, undefined, {
            leading: true,
            trailing: true
          }) // wait 50ms after the last event before emitting last event
        ).subscribe((delta: number) => {
          let newZoom = this.zoom + (delta > 0 ? 1 : -1) * 10;
          if (newZoom > this.maxZoom) {
            newZoom = this.maxZoom;
          }
          if (newZoom < this.minZoom) {
            newZoom = this.minZoom;
          }
          this.zoom = newZoom;
        });
    }

    this._mouseWheelObserver?.next(e);
  }

  getPhotoCanvas(): HTMLCanvasElement | undefined {
    if (this._cropper) {
      const canvasSize = this._cropper.getCanvasData();
      const maxWidth = this.maxWidth ?? Number.MAX_VALUE;
      const maxHeight = this.maxHeight ?? Number.MAX_VALUE;
      // scale down if bigger than max size
      const targetSize = this.isVector || canvasSize.naturalWidth > maxWidth || canvasSize.naturalHeight > maxHeight ? calculateAspectRatioFit(canvasSize.width, canvasSize.height, maxWidth, maxHeight) : {
        width: canvasSize.naturalWidth,
        height: canvasSize.naturalHeight
      };
      return this._cropper ? this._cropper.getCroppedCanvas({
        fillColor: this.color,
        width: targetSize.width,
        height: targetSize.height,
        imageSmoothingQuality: 'high',
        sourceIsVector: this.isVector
      } as any) : undefined;
    }
    return undefined;
  }

  getPhotoBlob(): Observable<IPhotoBlobData | undefined> {
    const canvas = this.getPhotoCanvas();
    return canvas ? of(canvas).pipe(
      mergeMap((sourceCanvas) => {
        return getCanvasBlob(sourceCanvas);
      }),
      map(blob => {
        return {
          blob,
          width: canvas.width,
          height: canvas.height
        };
      })
    ) : of(undefined);
  }

  private setPickerColor(color: string | undefined) {
    this.color = color;
    if (color && this.colors?.length) {
      const rgbColor = hexStringToRgb(color);
      this.colors[this.selectedIndex].r = rgbColor.r;
      this.colors[this.selectedIndex].g = rgbColor.g;
      this.colors[this.selectedIndex].b = rgbColor.b;
      this.colors[this.selectedIndex].hex = color;
    }
  }

  private setImageUrl(rotatedImageUrl: string) {
    if (this.imageRef) {
      this.imageRef.nativeElement.src = rotatedImageUrl;
    }
  }

  private checkColors() {
    if (this._cropper) {
      const canvas = this._cropper.getCroppedCanvas();
      const canvasContext = canvas.getContext('2d');
      if (canvasContext) {
        const id = canvasContext.getImageData(0, 0, canvas.width, canvas.height);
        const imgData = {data: id.data, channels: 4};
        // get 5 colors
        const colors = extract(imgData, 5);
        this.colors = colors;
        // add default opposite color
        const opColor = colors.length === 1 ? getOppositeColor(colors[0]) : '#ffffff';
        const opColorChannels = hexStringToRgb(opColor);
        colors.splice(0, 0, {
          hex: opColor,
          r: opColorChannels.r,
          g: opColorChannels.g,
          b: opColorChannels.b,
          amount: -1
        });
        // add default bg colors white/black/gray(used for wall message background)
        const defaultColors = ['#ffffff', '#000000', '#2a2a2a'];
        defaultColors.reverse().forEach(c => {
          const color = find(propEq('hex', c))(colors);
          if (!color) {
            const colorChannels = hexStringToRgb(c);
            colors.splice(1, 0, {
              hex: c,
              r: colorChannels.r,
              g: colorChannels.g,
              b: colorChannels.b,
              amount: -1
            });
          }
        });
        this._changeDetectorRef.markForCheck();
        // set color in next phase to render color blocks first then show tooltip(for proper tooltip position)
        setTimeout(() => {
          this.selectedIndex = -1;
          this.changeColor(0);
          this.isInitialized = true;
          this._changeDetectorRef.markForCheck();
          setTimeout(() => {
            this.isCompleted = true;
            this._changeDetectorRef.markForCheck();
            setTimeout(() => {
              // re-create cropper after layout change
              this.createCropper(false, true);
            });
          });
        }, 100);
      }
    }
  }

  private updateCanvas() {
    if (this._cropper) {
      const canvas = this._cropper.getCroppedCanvas();
      this._canvasContext = canvas.getContext('2d');
    }
  }

  private createCropper(analyzeColors: boolean, showCropBox: boolean) {
    this._cropper?.destroy();
    const cropperOptions = {
      checkOrientation: false,
      zoomOnWheel: false, // do zoom by wheel in code (default behavior is using mouse cursor for center of zoom)
      autoCrop: showCropBox, // show cropbox manually when source image loaded/transformed
      autoCropArea: 1,
      background: false,

      modal: false, // modal layer (semi-transparent dark layer makes color used to fill background darker in preview
      highlight: false,
      viewMode: 0,
      crop: () => {
        this._canvasContext = null; // invalidate canvas context
        if (!this._cropperReadyObserver) {
          this._cropperReadySubscription =
            new Observable((observer: Observer<void>) => {
              this._cropperReadyObserver = observer;
            }).pipe(
              throttleTime(500, undefined, {
                leading: true,
                trailing: true
              }) // wait 500ms after the last event before applying check
            ).subscribe(() => {
              this.checkOverlappingArea();
            });
        }
        this._cropperReadyObserver?.next(void 0);
      },
      ready: () => {
        if (this._cropper) {
          // set zoom constraints
          const canvasData = this._cropper.getCanvasData();
          this._zoomRatio = canvasData.width / canvasData.naturalWidth / this.zoom;
          if (analyzeColors) {
            // check colors
            this.checkColors();
          }
          this.checkOverlappingArea();
          this._changeDetectorRef.markForCheck();
        }
      }
    };
    this.loadCropperLib().subscribe(() => {
      this._cropper = new PhotoPickerComponent._cropperLib(this.imageRef?.nativeElement, cropperOptions);
    });
  }

  private checkOverlappingArea() {
    if (this._cropper) {
      const canvasData = this._cropper.getCanvasData();
      const cropBoxData = this._cropper.getCropBoxData();
      // check if cropper area is larger than scaled image
      const showColorsPicker = canvasData.left > cropBoxData.left || canvasData.left + canvasData.width < cropBoxData.left + cropBoxData.width || canvasData.top > cropBoxData.top || canvasData.top + canvasData.height < cropBoxData.top + cropBoxData.height;
      if (this.showColorsPicker !== showColorsPicker) {
        this.showColorsPicker = showColorsPicker;
        this._changeDetectorRef.markForCheck();
      }
    }
  }

  private loadCropperLib(): Observable<void> {
    return PhotoPickerComponent._cropperLib ? of(void 0) : new Observable((observer: Observer<void>) => {
      import('cropperjs').then(({default: _cropperLib}) => {
        PhotoPickerComponent._cropperLib = _cropperLib;
        observer.next(void 0);
        observer.complete();
      });
    });

  }

}
