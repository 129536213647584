import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from '@myia/ngx-color-picker';
import { LocalizationModule } from '@myia/ngx-localization';
import { TooltipModule } from '@myia/ngx-tooltip';
import { SliderModule } from '@myia/ngx-slider';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { PhotoPickerComponent } from './components/photoPickerComponent';
import { PhotoPickerCropperWidthPipe } from './pipes/photoPickerCropperWidth.pipe';
import { CoreModule } from '@myia/ngx-core';

@NgModule({
    imports: [
        ColorPickerModule,
        CoreModule,
        CommonModule,
        CoreUIModule,
        LocalizationModule,
        SliderModule,
        TooltipModule
    ],
    declarations: [
        PhotoPickerComponent,
        PhotoPickerCropperWidthPipe
    ],
    exports: [
        PhotoPickerComponent
    ]
})
export class PhotoPickerModule {
    static forRoot(): ModuleWithProviders<PhotoPickerModule> {
        return {
            ngModule: PhotoPickerModule
        };
    }
}
