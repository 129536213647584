import { ModuleWithProviders, NgModule } from '@angular/core';

import { CORE_CONFIGURATION, ICoreModuleConfig } from './coreModuleConfig';

@NgModule({})
export class CoreModule {
    static forRoot(coreConfig?: ICoreModuleConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: CORE_CONFIGURATION,
                    useValue: coreConfig || {}
                }
            ]
        };
    }
}
