import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'progress-indicator-bar',
    styleUrls: ['./progressIndicatorBar.component.scss'],
    template: `
            <div class="barLoader {{indicatorClass}}"></div>
            `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressIndicatorBarComponent {
    @Input() indicatorClass: string | undefined;
    @HostBinding('class') hostClasses = 'progressIndicatorBar';
}
