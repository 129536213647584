import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { LocalizationModule } from '@myia/ngx-localization';
import { CountersCompletedPipe } from './components/countersCompletedPipe';
import { IsCounterActivePipe } from './components/isCounterActivePipe';
import { JitsiMeetComponent } from './components/jitsiMeet.component';
import { CountDownComponent } from '@myia/ngx-live-meeting-components';

@NgModule({
  imports: [CommonModule, CoreModule, CoreUIModule, LocalizationModule, CountDownComponent],
  declarations: [
    CountersCompletedPipe,
    IsCounterActivePipe,
    JitsiMeetComponent,
    CountersCompletedPipe,
  ],
  exports: [
    CountersCompletedPipe,
    IsCounterActivePipe,
    JitsiMeetComponent,
    CountersCompletedPipe,
  ]
})
export class LiveMeetingModule {
}
