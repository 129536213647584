import { NgModule, ModuleWithProviders, APP_INITIALIZER, Injector, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ISplashModuleConfig, SPLASH_CONFIGURATION } from './splashConfig';
import { CoreModule, EmitterService, Logger, APP_VIEWCONTAINERREF_CHANGED } from '@myia/ngx-core';
import { Subscription } from 'rxjs';

export function appInitializerFactory(splashModuleConfig: ISplashModuleConfig, injector: Injector): () => Promise<any> {
  return () => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        const appViewContainerRefChangedEvent = EmitterService.getValueChangedEvent<ViewContainerRef>(APP_VIEWCONTAINERREF_CHANGED);
        let defaultViewContainerRef = appViewContainerRefChangedEvent.value;
        appViewContainerRefChangedEvent.subscribe((viewContainerRef: ViewContainerRef) => {
          defaultViewContainerRef = viewContainerRef;
        });

        const router: Router = injector.get(Router); // router must be injected by injector. Direct injection is not possible due cyclic router<->http detected by angular compiler.
        let splashHidden = false;
        let splashTimeout: any;
        const logger: any = injector.get(Logger) || console;
        let splashRouterSubscription: Subscription | undefined;
        const hideSplash = () => {
          logger.log('Splash hiding started ...');
          if (splashTimeout) {
            clearTimeout(splashTimeout);
            splashTimeout = null;
          }
          if (splashRouterSubscription) {
            splashRouterSubscription.unsubscribe();
            splashRouterSubscription = undefined;
          }
          if (!splashHidden) {
            splashHidden = true;
            const splashEl = document.getElementsByClassName(splashModuleConfig.splashSelector)[0];

            setTimeout(() => {
              splashEl.classList.add('hide');
              if (defaultViewContainerRef) {
                defaultViewContainerRef.element.nativeElement.classList.add('ready');
              }
            }, 0);
          }
        };
        // hide splash after route loaded
        splashRouterSubscription = router.events.subscribe(event => {
          logger.log('Check router state to hide splash ...');
          if (event instanceof NavigationEnd) {
            hideSplash();
          }
        });
        resolve();
      });
    });
  };
}

// @dynamic
@NgModule({
  imports: [
    CoreModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [SPLASH_CONFIGURATION, Injector],
      multi: true
    }
  ]
})
export class SplashModule {
  static forRoot(splashModuleConfig: ISplashModuleConfig): ModuleWithProviders<SplashModule> {
    return {
      ngModule: SplashModule,
      providers: [
        {
          provide: SPLASH_CONFIGURATION, useValue: splashModuleConfig
        }
      ]
    };
  }
}
