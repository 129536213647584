import { BehaviorSubject, Subject } from 'rxjs';
import { compareObjects } from '../readOnlyEntity';

export class EntitiesArrayTracker<TEntity extends object, TKey extends keyof TEntity = keyof TEntity> {

  edited$ = new BehaviorSubject<ReadonlyArray<TEntity>>([]);
  added$ = new BehaviorSubject<ReadonlyArray<TEntity>>([]);
  removed$ = new BehaviorSubject<ReadonlyArray<TEntity>>([]);
  updated$ = new BehaviorSubject<ReadonlyArray<TEntity>>([]);

  get edited() {
    return this.edited$.value;
  }

  get added() {
    return this.added$.value;
  }

  get removed() {
    return this.removed$.value;
  }

  get updated() {
    return this.updated$.value;
  }

  changed = new Subject<void>();

  private _originalEntities: ReadonlyArray<Readonly<TEntity>> = [];

  constructor(private _options: { key: TKey, notTrackedProps?: TKey[], updateSupported?: boolean | ((oldEntity: TEntity | undefined, newEntity: TEntity) => boolean), logState?: boolean }) {
  }

  initialize(originalEntities: ReadonlyArray<Readonly<TEntity>>) {
    this._originalEntities = originalEntities;
    this.edited$.next(originalEntities);
    this.added$.next([]);
    this.removed$.next([]);
    this.updated$.next([]);
    if (this._options.logState) {
      this.logTracker(true);
    }
  }

  add(entity: TEntity) {
    const alreadyRemovedEntity = this.findEntityByKey(this.removed, entity);
    this.edited$.next([...this.edited, entity]);
    if (alreadyRemovedEntity) {
      this.removed$.next(this.filterEntityByKey(this.removed, entity));
      const originalEntity = this.findEntityByKey(this._originalEntities, entity);
      if (originalEntity) {
        const isModified = originalEntity ? !compareObjects(originalEntity, entity, false, this._options?.notTrackedProps) : true;
        if (isModified) {
          if (this.updateSupported(originalEntity, entity)) {
            this.updated$.next([...this.updated, entity]);
          } else {
            this.added$.next([...this.added, entity]);
          }
        }
      } else {
        this.added$.next([...this.added, entity]);
      }
    } else {
      this.added$.next([...this.added, entity]);
    }
    this.emitUpdateEvent();
  }

  remove(entity: TEntity) {
    const alreadyAddedEntity = this.findEntityByKey(this.added, entity);

    this.edited$.next(this.filterEntityByKey(this.edited, entity));

    if (alreadyAddedEntity) {
      this.added$.next(this.filterEntityByKey(this.added, entity));
      const originalEntity = this.findEntityByKey(this._originalEntities, entity);
      if (originalEntity) {
        this.removed$.next([...this.filterEntityByKey(this.removed, entity), originalEntity]);
      }
    } else {
      this.removed$.next([...this.removed, entity]);
    }
    const alreadyUpdatedEntity = this.findEntityByKey(this.updated, entity);
    if (this.updateSupported(alreadyUpdatedEntity, entity)) {
      if (alreadyUpdatedEntity) {
        this.updated$.next(this.filterEntityByKey(this.updated, entity));
      }
    }
    this.emitUpdateEvent();
  }

  update(entity: TEntity) {
    this.edited$.next([...this.filterEntityByKey(this.edited, entity), entity]);

    const originalEntity = this.findEntityByKey(this._originalEntities, entity);
    const isModified = originalEntity ? !compareObjects(originalEntity, entity, false, this._options.notTrackedProps) : true;
    if (this.updateSupported(originalEntity ?? this.findEntityByKey(this.added, entity), entity)) {
      if (originalEntity) {
        this.updated$.next([...this.filterEntityByKey(this.updated, entity), ...(isModified ? [entity] : [])]);
      } else {
        this.added$.next([...this.filterEntityByKey(this.added, entity), entity]);
      }
    } else {
      if (originalEntity) {
        this.removed$.next([...this.filterEntityByKey(this.removed, entity), ...(isModified ? [originalEntity] : [])]);
      }
      this.added$.next([...this.filterEntityByKey(this.added, entity), ...(isModified ? [entity] : [])]);
    }
    this.emitUpdateEvent();
  }

  private findEntityByKey(entities: ReadonlyArray<TEntity>, entity: TEntity) {
    const entityKeyName = this._options.key;
    return entities.find(e => e[entityKeyName] === entity[entityKeyName])
  }

  private filterEntityByKey(entities: ReadonlyArray<TEntity>, entity: TEntity) {
    const entityKeyName = this._options.key;
    return entities.filter(e => e[entityKeyName] !== entity[entityKeyName])
  }

  private emitUpdateEvent() {
    if (this._options.logState) {
      this.logTracker();
    }
    this.changed.next();
  }


  private logTracker(showInitData = false) {
    console.log('entity tracker:');
    if (showInitData) {
      console.log('initialized:' + JSON.stringify(this._originalEntities));
    } else {
      console.log('added:' + JSON.stringify(this.added));
      console.log('removed:' + JSON.stringify(this.removed));
      console.log('updated:' + JSON.stringify(this.updated));
    }
  }

  private updateSupported(oldEntity: TEntity | undefined, newEntity: TEntity) {
    return typeof this._options.updateSupported === 'function' ? this._options.updateSupported(oldEntity, newEntity) : this._options.updateSupported;
  }
}
