import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, ViewContainerRef, } from '@angular/core';
import { ICalendarEvent } from '../entities/calendarEvent.interface';
import { CalendarEventActions } from '../entities/calendarEventActions';

@Component({
  selector: 'scheduler-event',
  styleUrls: ['./schedulerEvent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div #content></div>`
})
export class SchedulerEventComponent {
  @ViewChild('content', {read: ViewContainerRef, static: true}) content?: ViewContainerRef;

  get event(): ICalendarEvent | undefined {
    return this._event;
  }

  @Input() set event(value: ICalendarEvent | undefined) {
    if (this._event !== value) {
      this._event = value;
      this.updateRenderedComponent();
    }
  }

  get language(): string | undefined {
    return this._language;
  }

  @Input() set language(value: string | undefined) {
    if (this._language !== value) {
      this._language = value;
      this.updateRenderedComponent();
    }
  }

  get edit(): boolean {
    return this._edit;
  }

  @Input() set edit(value: boolean) {
    if (this._edit !== value) {
      this._edit = value;
      this.updateRenderedComponent();
    }
  }

  get defaultLanguage(): string | undefined {
    return this._defaultLanguage;
  }

  @Input() set defaultLanguage(value: string | undefined) {
    if (this._defaultLanguage !== value) {
      this._defaultLanguage = value;
      this.updateRenderedComponent();
    }
  }

  @HostBinding('id') get elId(): string | undefined {
    return SchedulerEventComponent.getEventElementId(this._event);
  }

  get eventTemplate(): any {
    return this._eventTemplate;
  }

  @Input() set eventTemplate(template: any) {
    if (this._eventTemplate !== template) {
      this._eventTemplate = template;
      this.renderEvent();
    }
  }


  @Output() actionCalled = new EventEmitter<{ action: CalendarEventActions, data?: any }>();

  private _event?: ICalendarEvent;
  private _eventTemplate: any;
  private _eventComponent: any;
  private _language?: string;
  private _defaultLanguage?: string;
  private _edit = false;

  constructor() {
  }

  static getEventElementId(event: ICalendarEvent | undefined) {
    return event ? `cal_${event.id}` : undefined;
  }

  private renderEvent() {
    if (!this.eventTemplate) {
      throw new Error(`No template resolved for calendar event: ${JSON.stringify(this._event)}`);
    }
    this.content?.clear();
    this._eventComponent = this.content?.createComponent(this.eventTemplate);
    this.updateRenderedComponent();

    // resend selected event
    if (this._eventComponent.instance.actionCalled) {
      this._eventComponent.instance.actionCalled.subscribe((e: any) => {
        this.actionCalled.emit(e);
      });
    }

    // accessing the component template view
    // (this._eventComponent.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
  }

  private updateRenderedComponent() {
    if (this._eventComponent) {
      // sending @Input() values
      this._eventComponent.instance.event = this._event;
      this._eventComponent.instance.language = this.language;
      this._eventComponent.instance.defaultLanguage = this.defaultLanguage;
      this._eventComponent.instance.edit = this._edit;
    }
  }
}
