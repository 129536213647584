import { Directive, ElementRef, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgResizeObserver, ngResizeObserverProvidersWithPonyfill } from 'ng-resize-observer';
import { map } from 'rxjs/operators';

// <div id="container" [container-sizes]='{"small":{"maxWidth": 400}, "medium":{"minWidth": 400}, "large":{"minWidth": 700}}'></div>

type queryDef = {
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
    mode?: 'all' | 'any'
};

@Directive({
    selector: '[container-sizes]',
    providers: [...ngResizeObserverProvidersWithPonyfill]
})
export class ContainerSizesDirective implements AfterViewInit {
    @Input('container-sizes') sizes: { [key: string]: queryDef } | undefined;
    @Output() sizeChanged = new EventEmitter();


    private static onSizeChanged(elem: HTMLElement, size: { width: number; height: number }) {
        // @ts-ignore
      return size.width !== parseInt(elem.dataset.width ?? '', 10) || size.height !== parseInt(elem.dataset.height ?? '', 10);
    }

    private static inRange(width: number, height: number, range: queryDef) {
        const minWidth = range.minWidth || 0;
        const maxWidth = range.maxWidth || Infinity;
        const minHeight = range.minHeight || 0;
        const maxHeight = range.maxHeight || Infinity;
        const widthMatch = width >= minWidth && width <= maxWidth;
        const heightMatch = height >= minHeight && height <= maxHeight;
        return range.mode === 'any' ? widthMatch || heightMatch : widthMatch && heightMatch;
    }

    constructor(private _elementRef: ElementRef, private resize$: NgResizeObserver) {
    }

    ngAfterViewInit() {

        this.setElementSize({width: this._elementRef.nativeElement.offsetWidth, height: this._elementRef.nativeElement.offsetHeight});
        this.updateSize();

        this.resize$.pipe(
            map((entry) => {
                const size = {width: entry.contentRect.width, height: entry.contentRect.height};
                if (ContainerSizesDirective.onSizeChanged(this._elementRef.nativeElement, size)) {
                    this.setElementSize(size);
                    this.updateSize(size);
                }
            })
        ).subscribe();
    }

    private updateSize(size?: { width: number; height: number }) {
        let rangeFlag = 0;
        const width = size?.width ?? this._elementRef.nativeElement.offsetWidth;
        const height = size?.height ?? this._elementRef.nativeElement.offsetHeight;
        for (const sz in this.sizes) {
            if (ContainerSizesDirective.inRange(width, height, this.sizes[sz])) {
                this._elementRef.nativeElement.dataset.size = sz;
                rangeFlag = 1;
            }
        }
        if (!rangeFlag) this._elementRef.nativeElement.dataset.size = '';
    }

    private setElementSize(size: { width: number; height: number }) {
        this._elementRef.nativeElement.dataset.width = size.width;
        this._elementRef.nativeElement.dataset.height = size.height;
    }
}

