import { NgModule, ModuleWithProviders } from '@angular/core';
import { NouisliderModule } from 'ng2-nouislider';
import { SliderComponent } from './sliderComponent';

@NgModule({
    imports: [
        NouisliderModule
    ],
    declarations: [
        SliderComponent,
    ],
    exports: [
        SliderComponent
    ]
})
export class SliderModule {
    static forRoot(): ModuleWithProviders<SliderModule> {
        return {
            ngModule: SliderModule
        };
    }
}
