import { NgModule } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { DragulaDirective } from './dragula.directive';

@NgModule({
    imports: [
        CoreModule
    ],
    declarations: [
        DragulaDirective
    ],
    exports: [
        DragulaDirective
    ],
})
export class DragDropModule {}
