import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Coords } from '@floating-ui/core/src/types';
import { arrow, autoUpdate, computePosition, flip, hide, offset, Placement, shift, Middleware } from '@floating-ui/dom';
import { EmitterService, getParentScroll, isDescendant, LAYOUT_CHANGED, NgChanges } from '@myia/ngx-core';
import { Subject, takeUntil } from 'rxjs';

declare type ToolTipContent = string | { html: string } | TemplateRef<unknown> | undefined | null;

@Directive({
  selector: '[tooltip]'
})
export class ToolTipDirective implements AfterViewInit, OnDestroy, OnChanges {

  get tooltipPlacement(): Placement | 'auto' {
    return this._tooltipPlacement;
  }

  @Input() set tooltipPlacement(val: Placement | 'auto') {
    this._tooltipPlacement = val;
    if (this._tooltipElement) {
      this.destroyTooltip();
      if (this._enable) {
        this.createTooltip();
      }
    }
  }

  get tooltip(): ToolTipContent {
    return this._tooltip;
  }

  @Input() set tooltip(val: ToolTipContent) {
    this._tooltip = val;
    if (this._tooltipElement) {
      this.destroyTooltip();
      if (this._enable) {
        this.createTooltip();
      }
    }
  }

  get tooltipTarget(): HTMLElement | null {
    return this._tooltipTarget || this.element?.nativeElement;
  }

  @Input() set tooltipTarget(val: HTMLElement | null) {
    this._tooltipTarget = val;
    if (this._tooltipElement) {
      this.destroyTooltip();
      if (this._enable) {
        this.createTooltip();
      }
    }
  }

  get hideWhenReferenceHidden(): boolean {
    return this._hideWhenReferenceHidden;
  }

  @Input() set hideWhenReferenceHidden(val: boolean) {
    this._hideWhenReferenceHidden = val;
    if (this._tooltipElement) {
      this.destroyTooltip();
      if (this._enable) {
        this.createTooltip();
      }
    }
  }

  get enable() {
    return this._enable;
  }

  @Input('tooltipEnable') set enable(val: boolean) {
    if (this._enable !== val) {
      this._enable = val;
      if (this._enable && (this._tooltipElement || this.tooltipTrigger === 'none')) {
        this.createTooltip();
      } else {
        this.destroyTooltip();
      }
    }
  }

  get tooltipMiddleware(): Map<number, Middleware> | undefined {
    return this._middleware;
  }

  @Input() set tooltipMiddleware(val: Map<number, Middleware> | undefined) {
    this._middleware = val;
    if (this._tooltipElement) {
      this.destroyTooltip();
      if (this._enable) {
        this.createTooltip();
      }
    }
  }

  get toolTipReference() {
    return this.tooltipTarget ?? this.element.nativeElement;
  }

  private get showDelay(): number {
    const delayObj = this.tooltipDelay as { show: number };
    return delayObj?.show ?? this.tooltipDelay as number;
  }

  private get hideDelay(): number {
    const delayObj = this.tooltipDelay as { hide: number };
    return delayObj?.hide ?? this.tooltipDelay as number;
  }

  constructor(private _viewContainerRef: ViewContainerRef, private element: ElementRef, private _renderer: Renderer2) {
  }

  @Input() tooltipHideOnClick = false;
  @Input() tooltipHideOnClickOutside = false;
  @Input() tooltipHideOnScroll = false;
  @Input() showArrow = true;

  @Input() tooltipTimeout = 0;

  @Input() tooltipClass: string | undefined;
  @Input() tooltipOffset: [number, number] = [0, 0];
  @Input() tooltipDelay: number | { show: number, hide: number } = { show: 500, hide: 0 };
  @Input() tooltipTrigger: 'hover' | 'focus' | 'click' | 'none' = 'hover';
  @Input() tooltipContainer: string | HTMLElement | undefined;
  @Input() boundaryContainer: string | undefined; // used for tooltip overflow check
  @Input() escapeWithReference = false;
  @Input() tooltipDontHideOnChange = false;
  @Input() enableWhenOverflow = false;
  @Output() tooltipShow = new EventEmitter<void>();
  @Output() tooltipHide = new EventEmitter<void>();
  @Output() tooltipClick = new EventEmitter<void>();

  private _enable = true;
  private _tooltipPlacement: Placement | 'auto' = 'auto';
  private _tooltipTarget: HTMLElement | null = null;

  private _tooltip: ToolTipContent;
  private _initialized = false;
  private _scheduledHideTimeout?: ReturnType<typeof setTimeout>;
  private _scheduledShowTimeout?: ReturnType<typeof setTimeout>;
  private _tooltipElement?: HTMLElement;
  private _arrowElement?: HTMLElement;
  private _hideWhenReferenceHidden = true;
  private _placementClassName?: string;

  private onLayoutChangedBind: EventListener = this.onLayoutChanged.bind(this);
  private _destroy$ = new Subject<void>();
  private _tooltipEventListeners: Array<() => void> = [];
  private _referenceEventListeners: Array<() => void> = [];
  private _autoUpdateSub?: () => void;
  private _isOverflown = false;
  private _middleware?: Map<number, Middleware>;

  private static _clearEventListeners(listeners: Array<() => void>) {
    listeners.forEach(evt => {
      if (evt && typeof evt === 'function') {
        evt();
      }
    });
    listeners.length = 0;
  }

  ngAfterViewInit() {
    this._initialized = true;
    EmitterService.getEvent(LAYOUT_CHANGED).pipe(
      takeUntil(this._destroy$)
    ).subscribe(this.onLayoutChangedBind);
    this.listenReferenceEvents(this.toolTipReference);
  }

  ngOnDestroy() {
    this._destroy$.next();
    if (this._scheduledHideTimeout) {
      clearTimeout(this._scheduledHideTimeout);
    }
    this.destroyTooltip();
    this._clearGlobalEventListeners();
    ToolTipDirective._clearEventListeners(this._referenceEventListeners);
  }

  ngOnChanges(changes: NgChanges<ToolTipDirective>) {
    if (changes.tooltipTarget) {
      if (changes.tooltipTarget.currentValue) {
        this.listenReferenceEvents(changes.tooltipTarget.currentValue);
      } else {
        ToolTipDirective._clearEventListeners(this._referenceEventListeners);
      }
    }
    if (this._initialized) {
      this.update();
    }
  }

  update() {
    const tooltipElement = this._tooltipElement;
    if (!tooltipElement) {
      return Promise.resolve();
    }

    const toolTipPlacement: Placement = this.tooltipPlacement === 'auto' ? 'top' : this.tooltipPlacement;
    const middleware = [
      ...(this._arrowElement ? [arrow({ element: this._arrowElement })] : []),
      offset({ crossAxis: this.tooltipOffset[0], mainAxis: this.tooltipOffset[1] }),
      ...(this._hideWhenReferenceHidden ? [hide()] : []),
      flip({
        boundary: this.boundaryContainer ? (this.boundaryContainer === 'clippingAncestors' ? 'clippingAncestors' : document.querySelector(this.boundaryContainer) ?? undefined) : getParentScroll(this.toolTipReference)
      }),
      shift({ padding: 5 })
    ];
    if (this._middleware) {
      this._middleware.forEach((m, mIndex) => {
        middleware.splice(mIndex, 0, m);
      });
    }
    // compute position
    return computePosition(
      this.toolTipReference,
      tooltipElement,
      {
        placement: toolTipPlacement,
        middleware,
      }
    ).then(({ x, y, placement, middlewareData }) => {

      // add placement class to dropdown content
      const placementClassName = `placement-${placement}`;
      if (this._placementClassName !== placementClassName) {
        if (this._placementClassName) {
          tooltipElement.classList.remove(this._placementClassName);
        }
        this._placementClassName = placementClassName;
        tooltipElement.classList.add(this._placementClassName);
      }

      tooltipElement.setAttribute('data-tooltip-placement', placement);

      Object.assign(tooltipElement.style, {
        left: `${x}px`,
        top: `${y}px`
      });

      if (this._arrowElement) {
        // arrow
        const { x: arrowX, y: arrowY } = middlewareData.arrow as Partial<Coords>;
        const { x: shiftX, y: shiftY } = middlewareData.shift as Partial<Coords>;

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]] as string;

        Object.assign(this._arrowElement.style, {
          left: arrowX != null ? `${arrowX - (shiftX ?? 0)}px` : '',
          top: arrowY != null ? `${arrowY - (shiftY ?? 0)}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });
      }

      if (middlewareData.hide?.referenceHidden) {
        this.hide();
      }
    });
  }

  scheduleShow() {
    // console.log('tooltip - scheduleShow');
    if (this._tooltipElement) {
      // already displayed
      return;
    }
    if (this._scheduledShowTimeout) {
      clearTimeout(this._scheduledShowTimeout);
    }
    this._scheduledShowTimeout = setTimeout(() => {
      this.show();
    }, this.showDelay);
  }

  show() {
    if (this.enable && this._tooltip) {
      this.createTooltip();
    }
  }

  hide() {
    if (this._tooltipElement) {
      this.destroyTooltip();
    }

    this._clearGlobalEventListeners();
  }

  checkOverflow() {
    const wasOverFlown = this._isOverflown;
    const isOverFlown = this.isOverflown();
    if (wasOverFlown !== isOverFlown) {
      this.destroyTooltip();
      if (isOverFlown) {
        this.createTooltip();
      }
    }
  }

  private onLayoutChanged(): void {
    this.update();
  }

  private createTooltip() {
    // console.log('tooltip - createTooltip');
    this.destroyTooltip();
    const tooltipReference = this.toolTipReference;
    const tooltipContainer = this.tooltipContainer ? (typeof this.tooltipContainer === 'string' ? document.querySelector(this.tooltipContainer as string) : this.tooltipContainer as HTMLElement) : document.body;
    if (tooltipReference && tooltipContainer) {
      this._tooltipElement = this._renderer.createElement('div') as HTMLElement;
      this._tooltipElement.className = `tooltip${this.tooltipClass ? ' ' + this.tooltipClass : ''}${this.tooltipHideOnClick ? ' active' : ''}`;
      this._renderer.appendChild(tooltipContainer, this._tooltipElement);
      if (this.tooltip instanceof TemplateRef) {
        // Render the TemplateRef as a SIBLING to THIS component.
        const embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.tooltip as TemplateRef<unknown>);
        // NOTE: I don't if this call is actually needed. It doesn't seem to make a
        // difference in this particular demo; however, it is called in the Angular
        // Material code, so I assume it is important (in at least some cases).
        embeddedViewRef.detectChanges();
        // At this point, the embedded-view DOM (Document Object Model) branch has been
        // wired-together, complete with view-model bindings. We can now move the DOM
        // nodes - which, in this case, is made up of the NgContent-projected nodes -
        // into the BODY without breaking the template bindings.
        for (const node of embeddedViewRef.rootNodes) {
          this._tooltipElement.appendChild(node);
        }
      } else {
        const htmlTooltip = this.tooltip as { html: string };
        if (htmlTooltip?.html) {
          this._tooltipElement.innerHTML = htmlTooltip?.html;
        } else {
          this._tooltipElement.innerText = this.tooltip as string;
        }
      }

      if (this.showArrow) {
        this._arrowElement = this._renderer.createElement('div') as HTMLElement;
        this._renderer.addClass(this._arrowElement, 'arrow');
        this._renderer.appendChild(this._tooltipElement, this._arrowElement);
      }

      this._autoUpdateSub = autoUpdate(tooltipReference, this._tooltipElement, this.update.bind(this));

      if (this.tooltipHideOnClickOutside) {
        this._tooltipEventListeners.push(this._renderer.listen('document', 'touchend', this.hideOnClickOutsideHandler.bind(this)));
        this._tooltipEventListeners.push(this._renderer.listen('document', 'click', this.hideOnClickOutsideHandler.bind(this)));
      }

      if (this.tooltipHideOnScroll) {
        const parentScrolls = [];
        while (true) {
          const parentScroll = getParentScroll(tooltipReference);
          if (!parentScroll) {
            break;
          }
          parentScrolls.push(parentScroll);
        }
        parentScrolls.forEach(scrollEl => {
          this._tooltipEventListeners.push(this._renderer.listen(scrollEl, 'scroll', this.hideOnScrollHandler.bind(this)));
        });
      }

      this._tooltipEventListeners.push(this._renderer.listen(this._tooltipElement, 'click', this.onTooltipClick.bind(this)));

      this._tooltipElement.classList.add('show');
      this.onShow();
    }
  }

  private onShow() {
    this.tooltipShow.emit();
  }

  private onHide() {
    this.tooltipHide.emit();
  }

  private onTooltipClick() {
    this.tooltipClick.emit();
    if (this.tooltipHideOnClick) {
      this.hide();
    }
  }

  private destroyTooltip() {
    if (this._tooltipElement) {
      // console.log('tooltip - destroyTooltip');
      if (this._scheduledShowTimeout) {
        clearTimeout(this._scheduledShowTimeout);
        this._scheduledShowTimeout = undefined;
      }
      if (this._tooltipElement?.parentNode) {
        this._tooltipElement.parentNode.removeChild(this._tooltipElement);
      }
      this._tooltipElement = undefined;

      if (this._autoUpdateSub) {
        this._autoUpdateSub();
      }
      this._clearGlobalEventListeners();
      this.onHide();
    }
  }

  private isOverflown() {
    this._isOverflown = this.element.nativeElement.scrollHeight > this.element.nativeElement.clientHeight || this.element.nativeElement.scrollWidth > this.element.nativeElement.clientWidth;
    return this._isOverflown;
  }

  private scheduleHide() {
    // console.log('tooltip - scheduleHide');
    if (this._scheduledShowTimeout) {
      clearTimeout(this._scheduledShowTimeout);
      this._scheduledShowTimeout = undefined;
    }
    if (!this._enable) {
      return;
    }
    this._scheduledHideTimeout = setTimeout(() => {
      this.hide();
    }, this.hideDelay);
  }

  private hideOnClickOutsideHandler($event: MouseEvent): void {
    if (!this.tooltipHideOnClickOutside || isDescendant(this._tooltipElement, $event.target, true) || isDescendant(this.tooltipTarget, $event.target, true)) {
      return;
    }
    this.hide();
  }

  private hideOnScrollHandler(_: MouseEvent): void {
    if (!this._enable || !this.tooltipHideOnScroll) {
      return;
    }
    this.scheduleHide();
  }

  private _clearGlobalEventListeners() {
    ToolTipDirective._clearEventListeners(this._tooltipEventListeners);
  }

  private listenReferenceEvents(tooltipReference: HTMLElement) {
    ToolTipDirective._clearEventListeners(this._referenceEventListeners);
    switch (this.tooltipTrigger) {
      case 'hover': {
        this._referenceEventListeners.push(this._renderer.listen(tooltipReference, 'mouseenter', this.scheduleShow.bind(this)));
        this._referenceEventListeners.push(this._renderer.listen(tooltipReference, 'mouseleave', this.scheduleHide.bind(this)));
        break;
      }
      case 'focus': {
        this._referenceEventListeners.push(this._renderer.listen(tooltipReference, 'focus', this.scheduleShow.bind(this)));
        this._referenceEventListeners.push(this._renderer.listen(tooltipReference, 'blur', this.scheduleHide.bind(this)));
        break;
      }
      case 'click': {
        this._referenceEventListeners.push(this._renderer.listen(tooltipReference, 'click', () => {
          if (this._tooltipElement) {
            this.hide();
          } else {
            this.show();
          }
        }));
        break;
      }
    }
  }
}
