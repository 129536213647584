import { readOnlyEntity } from '@myia/ngx-core';
import { ICalendarEvent } from './calendarEvent.interface';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function CalendarEvent(props: ICalendarEvent): ICalendarEvent {
    return readOnlyEntity({
      // @ts-ignore: TS2783
        id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.event),
      // @ts-ignore: TS2783
        type: undefined,
      // @ts-ignore: TS2783
        start: undefined,
        end: undefined,
        title: undefined,
        color: undefined,
        icon: undefined,
        template: undefined,
        contentTemplate: undefined,
        meta: undefined,
        selected: undefined,
        recordingUrl: undefined,
        ...props,
        uuid: CalendarUtilsInternal.newCalendarUUID(),
    });
}

