import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, FormControl, FormControlOptions, FormGroup, ValidatorFn } from '@angular/forms';
import { DialogManager } from '@myia/ngx-dialog';
import { LocalizationService } from '@myia/ngx-localization';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, of, Subject, Subscription } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { validateFormGroup, watchFormChanges } from '../formHelper';

export type FormControlTypes = 'control' | 'group' | 'array';

export type IFormServiceControlDef<TValue = any> = {
  valueType?: TValue;
  controlType?: FormControlTypes;
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
};

export type IFormServiceControls =
  {
    [key: string]: IFormServiceControlDef;
  };

export interface IFormServiceOptions {
  controls: IFormServiceControls;
  options?: AbstractControlOptions | null;
  discardDialogText?: string;
  valueConverter?: (value: any) => any;
}

export type TypedFormControl<Type extends IFormServiceOptions['controls']> = {
  [Property in keyof Type]: FormControl<Type[Property]['valueType']>;
}

export type TypedFormData<Type extends IFormServiceOptions['controls'], NotNull extends boolean = false> = {
  [Property in keyof Type]: (NotNull extends true ? NonNullable<Type[Property]['valueType']> : Type[Property]['valueType']);
}

@Injectable()
export class FormService<TOptions extends IFormServiceOptions,
  FieldName extends (keyof TOptions['controls']) & string = (keyof TOptions['controls']) & string> implements OnDestroy {

  get formDataChanged() {
    return this._formGroupValueChanged;
  }

  get controls(): TypedFormControl<TOptions['controls']> {
    return this.abstractControls as TypedFormControl<TOptions['controls']>;
  }

  get groups(): Record<FieldName, FormGroup> {
    return this.abstractControls as Record<FieldName, FormGroup>;
  }

  get arrays(): Record<FieldName, FormArray> {
    return this.abstractControls as Record<FieldName, FormArray>;
  }

  get values(): TypedFormData<TOptions['controls']> {
    return Object.keys(this.abstractControls)
      .reduce((acc, elem) => ({
        ...acc,
        [elem]: this.abstractControls[elem as FieldName].value
      }), {} as TypedFormData<TOptions['controls']>)
  }

  abstractControls: Record<FieldName, AbstractControl> = {} as Record<FieldName, AbstractControl>;

  formGroup!: FormGroup;
  formGroupChanged$!: Observable<boolean>;
  valid$!: Observable<boolean>;
  submitted$!: Observable<boolean>;
  invalidAndSubmitted$!: Observable<boolean>;

  private _formGroupValueChanged = false;
  private _formGroupChangedSub?: Subscription;
  private _formGroupChanged$ = new BehaviorSubject<boolean>(false);
  private _formGroupValid$: BehaviorSubject<boolean>;
  private _submitted$ = new BehaviorSubject<boolean>(false);
  private _destroy$ = new Subject<void>();

  constructor(private _localizationService: LocalizationService, private _dialogManager: DialogManager, @Inject('FormConfig') private formConfig: TOptions) {
    // console.log('Form service created.');
    this.formGroupChanged$ = this._formGroupChanged$.pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true })
    );
    this.createFormGroup();
    this.formGroup?.statusChanges.pipe(
      takeUntil(this._destroy$)
    ).subscribe(status => {
      this._formGroupValid$.next(status === 'VALID');
    });
    this._formGroupValid$ = new BehaviorSubject<boolean>(this.formGroup.valid);
    this.valid$ = this._formGroupValid$.pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true })
    );
    this.submitted$ = this._submitted$.pipe(
      distinctUntilChanged(),
      shareReplay({ refCount: true })
    );
    this.invalidAndSubmitted$ = combineLatest([
      this.submitted$,
      this.valid$
    ]).pipe(
      map(([submitted, valid]) => {
        return submitted && !valid;
      }),
      distinctUntilChanged(),
      shareReplay({ refCount: true })
    );
  }

  ngOnDestroy() {
    // console.log('Form service destroyed.');
    this.dispose();
  }

  setFormData(formData: Partial<TypedFormData<TOptions['controls']>>,
              options?: {
                onlySelf?: boolean;
                emitEvent?: boolean;
              }) {
    this.formGroup?.reset(formData, { emitEvent: false, ...options });
    this.markNotChanged();
  }

  getFormData<NotNull extends boolean>(valid: NotNull = false as NotNull) {
    return this.values as TypedFormData<TOptions['controls'], NotNull>;
  }

  resetForm(options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    this.formGroup.reset({ emitEvent: false, ...options });
    this.watchFormChanges();
    this._formGroupValid$.next(this.formGroup.valid);
    this._submitted$.next(false);
  }

  markNotChanged() {
    this.formGroup?.markAsPristine();
    this.watchFormChanges();
    this._formGroupChanged$.next(false);
    this._formGroupValid$.next(this.formGroup.valid);
    this._submitted$.next(false);
  }

  enable() {
    this.formGroup?.enable();
  }

  disable() {
    this.formGroup?.disable();
  }

  checkFormEdited(confirmationDialogText?: string): Observable<boolean> {
    if (!this.formDataChanged) {
      return of(true);
    }
    return this.showDiscardDialog(confirmationDialogText);
  }

  showDiscardDialog(confirmationDialogText?: string): Observable<boolean> {
    const dialogData = {
      message: this._localizationService.translate(confirmationDialogText || this.formConfig.discardDialogText || ''),
      btnTrue: this._localizationService.translate('Yes'),
      btnFalse: this._localizationService.translate('No')
    };
    return this._dialogManager.showModalDialog<typeof dialogData, boolean>('confirmationDialog', {
      customClass: 'confirmationDialog w640',
      dialogData
    }).result;
  }

  validate() {
    this._submitted$.next(true);
    validateFormGroup(this.formGroup);
    this.formGroup.updateValueAndValidity();
    return this.formGroup.valid;
  }

  dispose() {
    // enable all UI controls before dispose. Fix the issue with disabled UI when form group is re-created
    // https://stackoverflow.com/questions/73008333/angular-reactive-forms-enabled-disabled-form-control-not-correctly-reflected-o
    this.enable();

    this._destroy$.next();
  }

  private createFormGroup() {
    this.formGroup = new FormGroup({}, this.formConfig.options);
    // eslint-disable-next-line guard-for-in
    for (const prop in this.formConfig.controls) {
      const controlName = prop as FieldName;
      const propConfig = this.formConfig.controls[prop];
      switch (propConfig?.controlType) {
        case 'array':
          this.abstractControls[controlName] = new FormArray([], propConfig?.validatorOrOpts, propConfig?.asyncValidator);
          break;
        case 'group':
          this.abstractControls[controlName] = new FormGroup({}, propConfig?.validatorOrOpts, propConfig?.asyncValidator);
          break;
        default:
          this.abstractControls[controlName] = new FormControl(null, propConfig?.validatorOrOpts, propConfig?.asyncValidator);
          break;
      }
      this.formGroup.addControl(prop, this.abstractControls[controlName]);
    }
  }

  private watchFormChanges() {
    this._formGroupValueChanged = false;
    this._formGroupChangedSub?.unsubscribe();
    this._formGroupChangedSub = watchFormChanges(this.formGroup, { valueConverter: this.formConfig.valueConverter }).pipe(
      tap(changed => {
        this._formGroupValueChanged = changed;
        // console.log('_formGroupChanged$:' + changed);
        this._formGroupChanged$.next(changed);
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }
}
