export async function copyToClipboard(text: string): Promise<boolean> {
  try {
    // try to use Clipboard API
    await navigator.clipboard.writeText(text);
    return true;
  } catch (_) {
    // Clipboard API is not supported
    const el = document.createElement('textarea')
    el.style.position = 'absolute';
    el.style.zIndex = '-1';
    el.style.opacity = '0';
    el.value = text;
    document.body.appendChild(el);
    el.select();
    const result = document.execCommand('copy');
    document.body.removeChild(el);
    return result;
  }
}
