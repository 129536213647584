import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { LocalizationModule } from '@myia/ngx-localization';
import { SliderModule } from '@myia/ngx-slider';
import { TooltipModule } from '@myia/ngx-tooltip';
import { CalendarEventTemplatePipe } from '../pipes/calendarEventTemplatePipe';
import { CalendarEventTitlePipe } from '../pipes/calendarEventTitlePipe';
import { CalendarEventSectionTrackHasTitlePipe } from '../pipes/calendarEventTrackHasTitlePipe';
import { CalendarSchedulerComponent } from '../components/calendarScheduler';
import { CalendarSchedulerLinearComponent } from '../components/calendarSchedulerLinear';
import { CalendarTimeLineHoursPipe } from '../pipes/calendarTimeLineHoursPipe';
import { DraggableItemDirective } from '../components/draggableItemDirective';
import { SchedulerEventComponent } from '../components/schedulerEvent';
import { CalendarTemplateDefaultComponent } from '../templates/calendarTemplateDefault.component';

let moduleInitialized = false;
@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        CoreUIModule,
        FormsModule,
        LocalizationModule,
        TooltipModule,
        SliderModule,
    ],
  declarations: [
    CalendarEventSectionTrackHasTitlePipe,
    CalendarEventTemplatePipe,
    CalendarEventTitlePipe,
    CalendarSchedulerComponent,
    CalendarSchedulerLinearComponent,
    CalendarTemplateDefaultComponent,
    CalendarTimeLineHoursPipe,
    DraggableItemDirective,
    SchedulerEventComponent,
  ],
  exports: [
    CalendarSchedulerComponent,
    CalendarSchedulerLinearComponent,
    DraggableItemDirective
  ],
  providers: [
    {
      provide: 'calendarTemplates',
      useValue: [{
        name: 'calendar-template-default',
        component: CalendarTemplateDefaultComponent,
        default: true
      }],
      multi: true
    }
  ]
})
export class CalendarModule {
  static forRoot(): ModuleWithProviders<CalendarModule> {
    if (!moduleInitialized) {
      moduleInitialized = true;
      // insert toast symbols SVG to the document body
      // @ts-ignore
      import(/* webpackChunkName: "calendar-symbols" */'!!raw-loader?!@myia/ngx-calendar/src/lib/icons/symbols/calendar.symbol.svg').then(svgIcons => {
        // load svg sprites icons
        const svgSymbolsElement = document.createElement('div');
        svgSymbolsElement.insertAdjacentHTML('afterbegin', svgIcons.default);
        const symbolsSvg = svgSymbolsElement.lastElementChild as HTMLElement;
        symbolsSvg.setAttribute('id', '__CALENDAR_SPRITES__');
        symbolsSvg.style.position = 'absolute';
        symbolsSvg.style.width = '0px';
        symbolsSvg.style.height = '0px';
        document.body.insertAdjacentElement('afterbegin', symbolsSvg);
      });
    }
    return {
      ngModule: CalendarModule
    };
  }
}
