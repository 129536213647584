import { LocalizationService } from './services/localizationService';
import { TranslatePipe } from '@ngx-translate/core';
import { ChangeDetectorRef, Injectable, Pipe, PipeTransform } from '@angular/core';

interface TransPipeOptions {
  _lang_: string; // explicit language
  _keyPrefix_: string; // key prefix - used to detect if query is a key or value
  _nullValue_: string; // text to display if value is null,
  _nullValueLang_: string; // language used to translate _nullValue_ (if it is different than _lang_)
  _suffix_: string; // text to display at the end
}

@Injectable()
@Pipe({
  name: 'trans',
  pure: false // required to update the value when the promise is resolved
})
export class TransPipe extends TranslatePipe implements PipeTransform {
  constructor(private cdr: ChangeDetectorRef, private _localizationService: LocalizationService) {
    super(_localizationService.translateService, cdr);
  }

  override transform(query: string | null | undefined, ...args: any[]): any {
    if (query && args && args.length > 0) {
      const options = args[0] as TransPipeOptions;
      if (options && options._keyPrefix_) {
        if (query.indexOf(options._keyPrefix_) !== 0) {
          // query contains the value not the key
          return query ? `${query}${options._suffix_ || ''}` : query;
        }
      }
      if (options && (options._lang_ || options._nullValueLang_)) {
        const result = this._localizationService.translateToLang(options._lang_, query, args, options._nullValue_, options._nullValueLang_);
        return result ? result + (options._suffix_ || '') : result;
      }
    }
    const result = query ? super.transform(query, ...args) : null;
    return typeof result === 'string' ? result : query;
  }
}
