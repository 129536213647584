import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[srcLoaded]'
})
export class ImageLoadedDirective implements OnDestroy {

  @Output() srcLoaded = new EventEmitter<void>();
  @Output() srcError = new EventEmitter<void>();

  private readonly nativeElement: HTMLElement;
  private ERROR_EVENT_TYPE = 'error';
  private LOAD_EVENT_TYPE = 'load';
  private cancelOnError: (() => void) | undefined;
  private cancelOnLoad: (() => void) | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.nativeElement = el.nativeElement;

    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.addEvents();
  }

  ngOnDestroy() {
    this.removeErrorEvent();
    this.removeOnLoadEvent();
  }

  private onError() {
    this.srcError.emit();
  }

  private onLoad() {
    this.srcLoaded.emit();
  }

  private removeErrorEvent() {
    if (this.cancelOnError) {
      this.cancelOnError();
    }
  }

  private removeOnLoadEvent() {
    if (this.cancelOnLoad) {
      this.cancelOnLoad();
    }
  }

  private addEvents() {
    this.cancelOnError = this.renderer.listen(this.nativeElement, this.ERROR_EVENT_TYPE, this.onError);
    this.cancelOnLoad = this.renderer.listen(this.nativeElement, this.LOAD_EVENT_TYPE, this.onLoad);
  }
}
