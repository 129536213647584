import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CultureService } from '@myia/ngx-localization';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Directive({
  selector: '[showInputLabel]'
})
export class ShowInputLabelDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() showInputLabel: 'never' | 'always' | 'auto' = 'auto';
  @Input() label?: string;
  @Input() formControl?: FormControl;

  private _labelElement!: HTMLElement;
  private _destroy$ = new Subject<void>();
  private _formControlStateSub?: Subscription;

  private get inputElement(): HTMLInputElement | null {
    return this._element.nativeElement.tagName === 'INPUT' || this._element.nativeElement.tagName === 'TEXTAREA' ? this._element.nativeElement as HTMLInputElement : this._element.nativeElement.querySelector('input');
  }

  constructor(private _element: ElementRef, private _cultureService: CultureService) {
    // update text when culture changed
    this._cultureService.onChange.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      setTimeout(() => {
        this.valueChanged();
      });
    });
  }

  @HostListener('ngModelChange', ['$event']) onModelChange(value: string | undefined) {
    this.valueChanged(undefined, value);
  }

  ngOnChanges(changes: Partial<Record<keyof ShowInputLabelDirective, SimpleChange>>) {
    this.valueChanged();
    if (changes.formControl) {
      this._formControlStateSub?.unsubscribe();
      const formControl = changes.formControl.currentValue as FormControl;
      this._formControlStateSub = formControl.statusChanges.pipe(
        takeUntil(this._destroy$)
      ).subscribe(_ => {
        this.checkFormControlValid(formControl);
      });
      this.checkFormControlValid(formControl);
      const focused = this.inputElement === document.activeElement;
      this.updateLabelState(formControl.value, focused);
    }
  }

  ngAfterViewInit() {
    this._labelElement = document.createElement('label');
    this._labelElement.classList.add('input-label', 'placeholder');
    this._element.nativeElement.parentElement.insertBefore(this._labelElement, this._element.nativeElement);
    this.inputElement?.addEventListener('blur', () => this.valueChanged());
    this.inputElement?.addEventListener('focus', () => this.valueChanged());
    this.checkFormControlValid(this.formControl);
    this.valueChanged();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  setFocusState(focused: boolean) {
    if (focused && !this.inputElement?.readOnly) {
      this._labelElement.classList.add('focused');
    } else {
      this._labelElement.classList.remove('focused');
    }
  }

  private valueChanged(_?: MouseEvent, value?: string) {
    if (this._labelElement) {
      this.checkPlaceholder();
      if (value == null) {
        value = this.inputElement?.value;
      }
      const focused = this.inputElement === document.activeElement;
      this.setFocusState(focused);
      this.updateLabelState(value, focused);
    }
  }

  private checkPlaceholder() {
    const labelText = this.label ?? this.inputElement?.placeholder;
    this._labelElement.innerText = labelText ?? '';
  }

  private checkFormControlValid(formControl?: FormControl) {
    if (!formControl || formControl?.valid) {
      this._labelElement?.classList.remove('invalid');
    } else {
      this._labelElement?.classList.add('invalid');
    }
  }

  private updateLabelState(value: string | undefined, focused: boolean) {
    if ((this.showInputLabel === 'auto' && (value || (focused && !this.inputElement?.readOnly))) || this.showInputLabel === 'always') {
      this._labelElement?.classList.remove('placeholder');
    } else {
      this._labelElement?.classList.add('placeholder');
    }
    if (this.showInputLabel === 'auto' && !value && !focused && (this.inputElement?.readOnly || this.inputElement?.disabled)) {
      this._labelElement?.classList.add('readOnly');
    } else {
      this._labelElement?.classList.remove('readOnly');
    }
  }
}
