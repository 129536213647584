import { Pipe, PipeTransform } from '@angular/core';
import { ICalendarEvent } from '../entities/calendarEvent.interface';
import { ICalendarEventOne2One } from '../entities/calendarEventOne2One.interface';

@Pipe({
  name: 'calendarEventTitle',
})
export class CalendarEventTitlePipe implements PipeTransform {

  transform(event: ICalendarEvent): any {
    if (!event) {
      return null;
    }
    switch (event.type) {
      case 'One2One':
        const meeting = event as ICalendarEventOne2One;
        return meeting.planned ? (typeof meeting.plannedTitle === 'string' ? meeting.plannedTitle : meeting.title) : meeting.title;
      default:
        return event.title;
    }
  }
}
