import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: 'onDestroy',
  standalone: true
})
export class OnDestroyDirective implements OnDestroy {
  private _destroy$ = new Subject<void>();

  get destroy$() {
    return this._destroy$.asObservable();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
