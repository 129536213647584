import { readOnlyEntity, updateReadOnlyEntity } from '@myia/ngx-core';
import { IPersistedState } from './persistedState.interface';

export function createState<T>(stateProps: T): Readonly<T> {
    return readOnlyEntity<T>(stateProps);
}

export function updateState<T>(state: T, update: (state: any) => void): Readonly<T> {
    return updateReadOnlyEntity<T>(state, update);
}

export function createPersistedState<T>(stateProps: T): Readonly<T> {
    return createState<T>({
        _isRehydrating: true,
        ...stateProps
    });
}

export function clearPersistenceFlag(state: IPersistedState) {
    state._isRehydrating = false;
}
