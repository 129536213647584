import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { getNewComponentId } from '@myia/ngx-core';
import { Subject, takeUntil } from 'rxjs';


@Directive({
  selector: '[formField]'
})
export class FormFieldDirective implements OnInit, OnDestroy {
  get formField(): FormGroup | undefined {
    return this._formField;
  }

  @Input() set formField(value: FormGroup | undefined) {
    this._formField = value;
    this.initialize();
  }

  @Input() disabled = false;
  @Input() validator: any;
  @Input() required = false

  private _formField: FormGroup | undefined;
  private _control: FormControl | undefined;
  private _controlName: string | undefined;
  private _destroy$ = new Subject<void>();
  private _initialized = false;

  constructor(private _model: NgModel) {
    _model.update.pipe(
      takeUntil(this._destroy$)
    ).subscribe((newValue: any) => {
      this._control?.patchValue(newValue);
    });
  }

  ngOnInit() {
    const validators = this.validator ? [this.validator] : [];
    if (this.required) {
      validators.push(Validators.required);
    }
    const controlState = { disabled: this.disabled, value: this._model.model }; // control state must have both two properties
    this._control = new FormControl(controlState, Validators.compose(validators));
    this._controlName = getNewComponentId();
    this.initialize();
  }

  ngOnDestroy() {
    if (this._formField) {
      this._formField.removeControl(this._controlName as string);
    }
    this._destroy$.next();
  }

  private initialize() {
    if (this._controlName && this._formField) {
      this._initialized = true;
      this._formField.addControl(this._controlName, this._control as AbstractControl);
    }
  }
}
