import { Pipe, PipeTransform } from '@angular/core';
import { ILocalizedValue } from './ILocalizedValue.interface';

export interface IDefaultTranslationOptions {
  position?: 'before' | 'after';
  nullValue?: string;
  renderHtml?: boolean;
  format?: 'longText' | 'shortText' | 'empty';
}

@Pipe({
  name: 'defaultTranslation'
})
export class DefaultTranslationPipe implements PipeTransform {
  static getDefaultTranslation(value: ILocalizedValue<string>, defaultLang: string, options?: IDefaultTranslationOptions): string | null {
    const defOptions: IDefaultTranslationOptions = {
      position: 'after',
      format: 'longText'
    };
    options = {...defOptions, ...options};
    return value && defaultLang && value[defaultLang] ? (options.position === 'before' ? `${DefaultTranslationPipe.renderLangPart(defaultLang, true, options)}${value[defaultLang]}` : `${value[defaultLang]}${DefaultTranslationPipe.renderLangPart(defaultLang, false, options)}`) : (options.nullValue || null);
  }

  static renderLangPart(lang: string, before: boolean, options: IDefaultTranslationOptions): string {
    if (options.format === 'empty') {
      return '';
    }
    return (options.renderHtml ? '<span class="lang">' : '') + (options.format === 'shortText' ? lang.toUpperCase() : (before ? `(${lang.toUpperCase()}) - ` : ` - (${lang.toUpperCase()})`)) + (options.renderHtml ? '</span>' : '');
  }

  transform(value: ILocalizedValue<string>, defaultLang: string, options?: IDefaultTranslationOptions): any {
    return DefaultTranslationPipe.getDefaultTranslation(value, defaultLang, options);
  }
}
