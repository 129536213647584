import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[isRouterActivated]'
})
export class RouterActivatedDirective {
    @Output() isRouterActivated: EventEmitter<boolean>;

    constructor() {
        this.isRouterActivated = new EventEmitter();
    }

    @HostListener('activate') onRouterActivated() {
        this.isRouterActivated.emit(true);
    }

    @HostListener('deactivate') onRouterDeactivated() {
        this.isRouterActivated.emit(false);
    }

}
