import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';

@Directive({
    selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterViewInit, OnChanges {
    @Input() autoFocus = false

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit() {
        if (this.autoFocus) {
            this.applyFocus();
        }
    }

    ngOnChanges(changes: NgChanges<AutoFocusDirective>) {
        if (changes.autoFocus?.currentValue) {
            setTimeout(() => {
                this.applyFocus();
            }, 100);
        }
    }

    private applyFocus() {
        const tagName = this._element.nativeElement.tagName.toLowerCase();
        const inputElement = tagName === 'input' || tagName === 'textarea' ? this._element.nativeElement : this._element.nativeElement.querySelector('input,textarea');
        // check visibility
        if (inputElement.offsetHeight) {
            inputElement.focus();
        } else {
            setTimeout(() => {
                inputElement.focus();
            });
        }
    }
}
