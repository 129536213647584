import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CultureService } from './services/cultureService';
import { FormatDateService } from './services/formatDateService';
import moment from 'moment';

@Pipe({
  name: 'parseDate',
})
export class ParseDatePipe implements PipeTransform {

  constructor(private _formatDateService: FormatDateService, private _cultureService: CultureService) {
  }

  transform(value: Date | string | null | undefined): Observable<moment.Moment | null> {
    return this._cultureService.currentCulture$.pipe(
      map(_ => {
        return value ? this._formatDateService.parseDate(value) : null;
      })
    );
  }
}
