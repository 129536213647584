import { Component, ChangeDetectorRef, ChangeDetectionStrategy, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Color } from '../utils/color';
import { IHSVColor } from '../utils/colorTypes';

// https://github.com/jaames/iro.js

@Component({
  selector: 'color-wheel-picker',
  styleUrls: ['./colorWheelPickerComponent.component.scss'],
  template: `
    <canvas #overlay [width]="width*pxRatio" [height]="height*pxRatio" class="overlayCanvas" [style.width]="width + 'px'" [style.height]="height + 'px'"></canvas>
    <canvas #main [width]="width*pxRatio" [height]="height*pxRatio" [style.width]="width + 'px'" [style.height]="height + 'px'"></canvas>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorWheelPickerComponent implements AfterViewInit, OnDestroy {
  get color(): string | undefined {
    return this._color ? this._color.hexString : undefined;
  }

  @Input() set color(val: string | undefined) {
    if (this._color.hexString !== val) {
      this._color.hexString = val || '#fff';
      this.update(this._color.hsv);
    }
  }

  @Input() set width(val: number) {
    if (this._width !== val) {
      this._width = val;
      this.resize();
    }
  }

  get width(): number {
    return this._width;
  }

  @Input() set height(val: number) {
    if (this._height !== val) {
      this._height = val;
      this.resize();
    }
  }

  get height(): number {
    return this._height;
  }

  @Input() markerRadius = 8;
  @Input() padding = 4;
  @Input() sliderMargin = 24;
  @Input() sliderHeight?: number;
  @Input() disabled = false;

  @Output() colorChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('main', {static: true}) mainCanvasRef?: ElementRef;
  @ViewChild('overlay', {static: true}) overlayCanvasRef?: ElementRef;

  pxRatio: number;

  private _mainCtx?: CanvasRenderingContext2D;
  private _overlayCtx?: CanvasRenderingContext2D;
  private _layout: any;
  private _color: Color;
  private _width = 200;
  private _height = 200;
  private _target?: string;
  private _active = false;
  private _browserIsIE: boolean;
  private _overlayMarkers: any = {};

  private _bodyListeners?: any[];
  private _bodyInputMoveHandler: any;
  private _bodyInputStartHandler: any;
  private _bodyInputEndHandler: any;

  constructor(private _changeDetectorRef: ChangeDetectorRef, private _renderer: Renderer2) {
    this.pxRatio = window.devicePixelRatio || 1;
    this._color = new Color();
    this._color.colorChanged = this.update.bind(this);
    this._color.rgbString = this.color || '#fff';
    this._browserIsIE = /MSIE ([0-9]+)/g.test(navigator.userAgent);
    this._overlayMarkers = {};
    this._bodyInputMoveHandler = this.bodyInputMove.bind(this);
    this._bodyInputStartHandler = this.bodyInputStart.bind(this);
    this._bodyInputEndHandler = this.bodyInputEnd.bind(this);
  }

  ngAfterViewInit(): void {
    this._mainCtx = this.mainCanvasRef?.nativeElement.getContext('2d');
    this._overlayCtx = this.overlayCanvasRef?.nativeElement.getContext('2d');
    this._mainCtx?.scale(this.pxRatio, this.pxRatio);
    this._overlayCtx?.scale(this.pxRatio, this.pxRatio);
    this.resize();
    this.attachEvents();
  }

  ngOnDestroy(): void {
    this._color.colorChanged = undefined;
    this.detachEvents();
  }

  private resize() {
    if (this._mainCtx && this.width && this.height) {
      this._mainCtx.clearRect(0, 0, this.width, this.height);
      this._layout = this.solveLayout();
      this.drawSlider();
      this.update(this._color.hsv);
    }
  }

  private drawMarkerRing(x: number, y: number, color: string, lineWidth: number) {
    const ctx = this._overlayCtx;
    if (ctx) {
      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.arc(x, y, this._layout.Mr, 0, 2 * Math.PI);
      ctx.stroke();
    }
  }

  private drawMarker(x: number, y: number) {
    this.drawMarkerRing(x, y, '#333', 4);
    this.drawMarkerRing(x, y, '#fff', 2);
  }

  private solveLayout() {
    const padding = this.padding + 2 || 6;
    const sliderMargin = this.sliderMargin || 24;
    const markerRadius = this.markerRadius || 8;
    const sliderHeight = this.sliderHeight || (markerRadius * 2) + (padding * 2);
    const ringDiameter = Math.min(this.height - sliderHeight - sliderMargin, this.width);
    const horizontalMargin = (this.width - ringDiameter) / 2;

    return {
      // marker radius
      Mr: markerRadius,
      // ring diameter
      Rd: ringDiameter,
      // ring radius
      Rr: ringDiameter / 2,
      // ring marker limit (Ring End)
      Re: (ringDiameter / 2) - (markerRadius + padding),
      // ring center x
      Rcx: horizontalMargin + (ringDiameter / 2),
      // ring center y
      Rcy: ringDiameter / 2 + 2,
      // ring bounds x1 (left)
      Rx1: horizontalMargin,
      // ring bounds x2 (right)
      Rx2: horizontalMargin + ringDiameter,
      // ring bounds y1 (top)
      Ry1: 0,
      // ring bounds y2 (bottom)
      Ry2: ringDiameter,
      // slider width
      Sw: ringDiameter,
      // slider height
      Sh: sliderHeight,
      // slider radius
      Sr: sliderHeight / 2,
      // slider rail width
      Srw: ringDiameter - sliderHeight,
      // slider rail start
      Srs: horizontalMargin + (sliderHeight / 2),
      // slider rail end
      Sre: (horizontalMargin + ringDiameter) - (sliderHeight / 2),
      // slider bounds x1 (left)
      Sx1: horizontalMargin,
      // slider bounds x1 (right)
      Sx2: horizontalMargin + ringDiameter,
      // slider bounds y1 (top)
      Sy1: ringDiameter + sliderMargin,
      // slider bounds y2 (bottom)
      Sy2: ringDiameter + sliderHeight + sliderMargin,
      // test if point x,y falls within the slider area (ignores border radius for simplicity)
      isPointInSlider(x: number, y: number) {
        return (x > this.Sx1) && (x < this.Sx2) && (y > this.Sy1) && (y < this.Sy2);
      },
      // test if point x,y falls within the ring area
      isPointInRing(x: number, y: number) {
        const dx = Math.abs(x - this.Rcx);
        const dy = Math.abs(y - this.Rcy);
        return Math.sqrt(dx * dx + dy * dy) < this.Rr;
      }
    };
  }

  private inputHandler(x: number, y: number) {
    const layout = this._layout;
    if (this._target === 'slider') {
      x = Math.max(Math.min(x, layout.Sre), layout.Srs) - layout.Srs;
      // update color
      // eslint-disable-next-line no-bitwise
      this.updateColor({v: ~~((100 / layout.Srw) * x)});
    } else if (this._target === 'ring') {
      // angle in radians, anticlockwise starting at 12 o'clock
      const r = Math.atan2(x - layout.Rcx, y - layout.Rcy);
      // hue in degrees, clockwise from 3 o'clock
      // eslint-disable-next-line no-bitwise
      const h = 360 - ~~(((r * (180 / Math.PI)) + 270) % 360);
      // distance from center
      const d = Math.min(Math.sqrt((layout.Rcx - x) * (layout.Rcx - x) + (layout.Rcy - y) * (layout.Rcy - y)), layout.Re);
      // update color
      // eslint-disable-next-line no-bitwise
      this.updateColor({h, s: ~~((100 / layout.Re) * d)});
    }
  }

  private bodyInputStart(e: any) {
    if (this.disabled) {
      return;
    }
    e = e.touches ? e.changedTouches[0] : e;
    const rect = this.mainCanvasRef?.nativeElement.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (this._layout.isPointInSlider(x, y)) {
      this._target = 'slider';
      e.preventDefault();
    } else if (this._layout.isPointInRing(x, y)) {
      this._target = 'ring';
      e.preventDefault();
    } else {
      return;
    }
    this._active = true;
    this.inputHandler(x, y);
  }

  private inputMove(e: TouchEvent) {
    if (this._active) {
      e.preventDefault();
      const inp = (e.touches ? e.changedTouches[0] : e) as Touch;
      const rect = this.mainCanvasRef?.nativeElement.getBoundingClientRect();
      this.inputHandler(inp.clientX - rect.left, inp.clientY - rect.top);
    }
  }

  private updateMarker(marker: string, x: number, y: number) {
    this._overlayMarkers[marker] = {x, y};
    this._overlayCtx?.clearRect(0, 0, this.width, this.height);
    if (this._overlayMarkers.ring) {
      this.drawMarker(this._overlayMarkers.ring.x, this._overlayMarkers.ring.y);
    }
    if (this._overlayMarkers.slider) {
      this.drawMarker(this._overlayMarkers.slider.x, this._overlayMarkers.slider.y);
    }
  }

  private drawWheel(value: number) {
    if (this._layout) {
      const layout = this._layout;
      const ctx = this._mainCtx;
      if (ctx) {
        ctx.save();
        ctx.clearRect(0, 0, this.width, layout.Rd + 10);
        // approximate a suitable line width based on the ring diameter
        ctx.lineWidth = Math.round(2 + layout.Rd / 100);
        ctx.clearRect(layout.Rx1, layout.Ry1, layout.Rd, layout.Rd);
        // draw the ring with a series of line segments
        for (let hue = 0; hue < 360; hue++) {
          // h = hue, a = hue angle in radians
          const hueRadians = hue * Math.PI / 180;
          ctx.beginPath();
          ctx.strokeStyle = ['hsl(', hue, ', 100%, ', value / 2, '%)'].join('');
          ctx.moveTo(layout.Rcx, layout.Rcy);
          ctx.lineTo(layout.Rcx + layout.Rr * Math.cos(hueRadians), layout.Rcy + layout.Rr * Math.sin(hueRadians));
          ctx.stroke();
        }
        // draw saturation gradient
        const grad = ctx.createRadialGradient(layout.Rcx, layout.Rcy, 0, layout.Rcx, layout.Rcy, layout.Re);
        grad.addColorStop(0, 'hsla(0, 0%, ' + value + '%, 1)');
        grad.addColorStop(1, 'hsla(0, 0%, ' + value + '%, 0)');
        ctx.fillStyle = grad;
        ctx.fillRect(layout.Rx1, layout.Ry1, layout.Rd, layout.Rd);

        // draw white border circle
        ctx.beginPath();
        ctx.arc(layout.Rcx, layout.Rcy, layout.Rr - 1, 0, 2 * Math.PI, false);
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#ffffff';
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(layout.Rcx, layout.Rcy, layout.Rr - 2, 0, Math.PI * 2, false);
        ctx.clip();

        ctx.beginPath();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 5;
        ctx.shadowBlur = 25;
        ctx.shadowColor = 'black';
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.arc(layout.Rcx + 5, layout.Rcy + 5, layout.Rr + 8, 0, Math.PI * 2, false);
        ctx.stroke();
        ctx.arc(layout.Rcx + 5, layout.Rcy + 5, layout.Rr + 8, 0, Math.PI * 2, false);
        ctx.stroke();
        ctx.arc(layout.Rcx + 5, layout.Rcy + 5, layout.Rr + 8, 0, Math.PI * 2, false);
        ctx.stroke();
        ctx.restore();
      }
    }
  }

  private drawSlider() {
    if (this._layout) {
      const layout = this._layout;
      const ctx = this._mainCtx;
      if (ctx) {
        const grad = ctx.createLinearGradient(layout.Sx1, layout.Sy1, layout.Sx2, layout.Sy1);
        grad.addColorStop(0, '#000');
        grad.addColorStop(1, '#fff');
        ctx.fillStyle = grad;
        ctx.clearRect(layout.Sx1, layout.Sy1, layout.Sw, layout.Sh);
        ctx.beginPath();
        ctx.moveTo(layout.Sx1 + layout.Sr, layout.Sy1);
        // IE 9 has an issue with arcTo() not working properly, so we have to use the slightly-less-accurate quadraticCurveTo method
        if (this._browserIsIE) {
          // top edge
          ctx.lineTo(layout.Sx2 - layout.Sr, layout.Sy1);
          // top-right corner
          ctx.quadraticCurveTo(layout.Sx2, layout.Sy1, layout.Sx2, layout.Sy1 + layout.Sr);
          // right edge
          ctx.lineTo(layout.Sx2, layout.Sy2 - layout.Sr);
          // bottom-right corner
          ctx.quadraticCurveTo(layout.Sx2, layout.Sy2, layout.Sx2 - layout.Sr, layout.Sy2);
          // bottom edge
          ctx.lineTo(layout.Sx1 + layout.Sr, layout.Sy2);
          // bottom-left corner
          ctx.quadraticCurveTo(layout.Sx1, layout.Sy2, layout.Sx1, layout.Sy2 - layout.Sr);
          // left edge
          ctx.lineTo(layout.Sx1, layout.Sy1 + layout.Sr);
          // top-left corner
          ctx.quadraticCurveTo(layout.Sx1, layout.Sy1, layout.Sx1 + layout.Sr, layout.Sy1);
        } else {
          ctx.arcTo(layout.Sx2, layout.Sy1, layout.Sx2, layout.Sy2, layout.Sr);
          ctx.arcTo(layout.Sx2, layout.Sy2, layout.Sx1, layout.Sy2, layout.Sr);
          ctx.arcTo(layout.Sx1, layout.Sy2, layout.Sx1, layout.Sy1, layout.Sr);
          ctx.arcTo(layout.Sx1, layout.Sy1, layout.Sx2, layout.Sy1, layout.Sr);
        }
        ctx.closePath();
        ctx.fill();
      }
    }
  }

  private update(newValue: IHSVColor | undefined) {
    if (this._layout) {
      const layout = this._layout;
      this.drawWheel(newValue?.v ?? 0);
      const x = ((newValue?.v ?? 0) / 100) * layout.Srw;
      this.updateMarker('slider', layout.Srs + x, layout.Sy1 + (layout.Sh / 2));
      const hue = newValue?.h ?? 0;
      const saturation = newValue?.s ?? 0;
      const hueRadians = hue * (Math.PI / 180);
      const distance = (saturation / 100) * layout.Re;
      this.updateMarker('ring', layout.Rcx + distance * Math.cos(hueRadians), layout.Rcy + distance * Math.sin(hueRadians));
    }
  }

  private bodyInputMove(e: any) {
    if (this.disabled) {
      return;
    }
    if (this._active) {
      this.inputMove(e);
    }
  }

  private bodyInputEnd(e: any) {
    if (this.disabled) {
      return;
    }
    if (this._active) {
      e.preventDefault();
      e.stopPropagation();
      this._target = undefined;
      this._active = false;
    }
  }

  private attachEvents() {
    this._bodyListeners = [];
    this._bodyListeners.push(this._renderer.listen('body', 'touchstart', this._bodyInputStartHandler));
    this._bodyListeners.push(this._renderer.listen('body', 'touchmove', this._bodyInputMoveHandler));
    this._bodyListeners.push(this._renderer.listen('body', 'touchend', this._bodyInputEndHandler));
    this._bodyListeners.push(this._renderer.listen('body', 'mousedown', this._bodyInputStartHandler));
    this._bodyListeners.push(this._renderer.listen('body', 'mousemove', this._bodyInputMoveHandler));
    this._bodyListeners.push(this._renderer.listen('body', 'mouseup', this._bodyInputEndHandler));
  }

  private detachEvents() {
    this._bodyListeners?.forEach(listener => listener());
    this._bodyListeners = undefined;
  }

  private updateColor(color: IHSVColor) {
    this._color.hsv = color;
    this.colorChanged.emit(this.color);
    this._changeDetectorRef.detectChanges();
  }
}
