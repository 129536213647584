import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, createNgModule, EnvironmentInjector,
  EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef
} from '@angular/core';
import { CultureService, FormatDateService, LocalizationService } from '@myia/ngx-localization';
import { of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ICalendar } from '../entities/calendar.interface';
import { ICalendarEvent } from '../entities/calendarEvent.interface';
import { CalendarEventActions } from '../entities/calendarEventActions';
import { ICalendarEventOne2One } from '../entities/calendarEventOne2One.interface';
import { ICalendarEventReservation } from '../entities/calendarEventReservation.interface';
import { ICalendarSection } from '../entities/calendarSection.interface';
import { ICalendarSectionBlock } from '../entities/calendarSectionBlock.interface';
import { ICalendarSectionBlockTrack } from '../entities/calendarSectionBlockTrack.interface';
import { CalendarUtils } from '../entities/calendarUtils';

export interface ICalendarTimeColumn {
  events: Array<ICalendarEvent>;
  span?: number;
}

export interface ICalendarTimeRow {
  from: Date;
  to: Date;
  text: string;
  columns: Array<ICalendarTimeColumn>;
  hidden?: boolean;
  busy?: boolean;
}

export interface ITimelineBlock {
  blockId: string;
  tracks: Array<ICalendarSectionBlockTrack>;
  timeline: Array<ICalendarTimeRow>;
}

@Component({
  selector: 'calendar-scheduler',
  styleUrls: ['./calendarScheduler.component.scss'],
  template: `
    <div *ngIf="loadingCalendar" class="calendarLoaderBlock">
      <progress-indicator-circle></progress-indicator-circle>
    </div>
    <div *ngIf="!loadingCalendar && calendar" class="blocksWrapper">
      <div *ngIf="selectedSection" class="sectionBlocks">
        <div class="timelineBlock" *ngFor="let timeLineBlock of timeLineBlocks; trackBy: trackTimelineBlock"
             [ngClass]="{edit: edit}">
          <div class="timelineBtns" *ngIf="edit">
            <ng-container
              *ngTemplateOutlet="timeLineBlockMenuTmpl, context: { $implicit: timeLineBlock }"></ng-container>
          </div>
          <table class="timelineTable">
            <tr class="row title" [ngClass]="{collapse: !edit && !(timeLineBlock.tracks | anySectionTrackHasTitle)}">
              <td class="title_hours_column" [ngClass]="{edited: edit}"></td>
              <th *ngFor="let track of timeLineBlock.tracks; trackBy: trackCalendarTrack" class="tracks"
                  [ngStyle]="{width: 100/timeLineBlock.tracks.length + '%'}">
                <div *ngIf="!edit">
                  <span
                    [innerHTML]="track.title|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: (track.title|trans:{_lang_: defaultLanguage, _keyPrefix_: transKeyPrefix, _nullValue_: '', _suffix_: ' <span class=\\'defLang\\'>(' + defaultLanguage + ')</span>'})}"></span><span
                  *ngIf="!track.events?.length"
                  class="emptyFlag">&nbsp;{{'Program.Program_Empty_Track'|trans}}&nbsp;</span>
                </div>
                <div *ngIf="edit" class="trackTitleEditBlock">
                  <input type="text"
                         [ngModel]="track.title|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''}"
                         (ngModelChange)="trackTitleChanged(track, $event)"
                         placeholder="{{'EventAgenda.EventAgenda_Track_Title'|trans}}"/><span class="trackBtns"><ng-container
                  *ngTemplateOutlet="trackMenuTmpl, context: { block: timeLineBlock, track: track }"></ng-container></span>
                </div>
              </th>
            </tr>
            <tr
              *ngFor="let time of timeLineBlock.timeline; trackBy: trackTimelineTime; let odd=odd; let even=even; let first = first; let last = last"
              class="row" [ngClass]="{ odd: odd, even: even, first: first, last: last}">
              <td class="tt_hours_column" [ngClass]="{endTime: time.hidden, busy: time.busy}">{{time.text}}</td>
              <td *ngFor="let column of time.columns; trackBy: trackTimelineColumn;" class="event tt_single_event"
                  [attr.rowspan]="column.span">
                <div class="eventsBlock">
                  <scheduler-event *ngFor="let event of column.events; trackBy: trackCalendarEvent; let last=last"
                                   [ngClass]="{ lastInSpan: last }" [event]="event" [edit]="edit" [language]="language"
                                   [defaultLanguage]="defaultLanguage"
                                   [eventTemplate]="event | calendarTemplate: eventTemplates"
                                   (actionCalled)="onEventActionCalled(event, $event)"></scheduler-event>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarSchedulerComponent implements OnInit, OnDestroy {
  eventTemplates?: Array<{ name: string, component: any }>;
  loadingCalendar = false;
  timeLineBlocks?: Array<ITimelineBlock>;

  transKeyPrefix = CalendarUtils.TRANSLATION_KEY_PREFIX;

  @ContentChild('timelineMenu', {static: true}) timeLineBlockMenuTmpl: TemplateRef<any> | null = null;
  @ContentChild('trackMenu', {static: true}) trackMenuTmpl: TemplateRef<any> | null = null;

  @Output() actionCalled = new EventEmitter<{ event: ICalendarEvent, action: CalendarEventActions, data?: any }>();

  get selectedSection() {
    return this._selectedSection;
  }

  @Input() set selectedSection(section: ICalendarSection | undefined) {
    this.selectSection(section);
  }

  @Output() selectedSectionChange = new EventEmitter<ICalendarSection>();

  get calendar(): ICalendar | undefined {
    return this._calendar;
  }

  @Input() set calendar(newCalendar: ICalendar | undefined) {
    if (this._calendar !== newCalendar) {
      const loadTemplates = newCalendar && (!this._calendar || this._calendar.id !== newCalendar.id);
      this._calendar = newCalendar;
      if (loadTemplates) {
        this.loadingCalendar = true;
        this._cdr.markForCheck();
        this.loadEventTemplates().pipe(
          tap(eventTemplates => {
            this.eventTemplates = eventTemplates;
            this.loadingCalendar = false;
            this._cdr.markForCheck();
          })
        ).subscribe();
      }
    }
  }

  @Input() edit = false
  @Input() language?: string;
  @Input() defaultLanguage?: string;

  private _calendar?: ICalendar;
  private _selectedSection?: ICalendarSection;
  private _onCultureChange?: Subscription;

  constructor(private _cdr: ChangeDetectorRef, private _cultureService: CultureService, private _formatDateService: FormatDateService, private _environmentInjector: EnvironmentInjector, private _localizationService: LocalizationService) {
  }

  ngOnInit() {
    // subscribe to onChange event, in case the culture changes
    this._onCultureChange = this._cultureService.onChange.subscribe(() => {
      this.createTimeline(this.selectedSection);
    });
  }

  ngOnDestroy() {
    if (this._onCultureChange) {
      this._onCultureChange.unsubscribe();
      this._onCultureChange = undefined;
    }
  }

  onEventActionCalled(event: ICalendarEvent, e: any) {
    this.actionCalled.emit({
      event,
      ...e
    });
  }

  updateTimeline(section?: ICalendarSection) {
    this.createTimeline(section || this.selectedSection);
    this._cdr.markForCheck();
  }

  getTimeLineBlock(blockId: string) {
    return this.timeLineBlocks?.find(b => b.blockId === blockId);
  }

  trackTimelineBlock(index: number, timelineBlock: ITimelineBlock): string {
    return timelineBlock.blockId;
  }

  trackTimelineTime(index: number, timelineTime: any): string {
    return timelineTime.id;
  }

  trackTimelineColumn(index: number, column: any): string {
    return column.id;
  }

  trackCalendarTrack(index: number, track: ICalendarSectionBlockTrack): string {
    return track.id;
  }

  trackCalendarEvent(index: number, event: ICalendarEvent): string {
    return event.uuid;
  }

  trackTitleChanged(track: ICalendarSectionBlockTrack, title: string) {
    if (this.language) {
      const oldValue = track.title;
      const oldValueIsKey = CalendarUtils.isCalendarTranslationKey(oldValue);
      const key = CalendarUtils.getCalendarTranslationKey(oldValue, this.calendar);
      if (!oldValueIsKey && this.calendar?.translations) {
        // copy old value to all other languages
        this.calendar.translations.filter(t => t.lang !== this.language).forEach(t => {
          this.updateLocalizedCalendarString(t.lang, key, oldValue ?? '');
        });
      }
      track.title = this.updateLocalizedCalendarString(this.language, key, title);
    } else {
      track.title = title;
    }
  }

  private updateLocalizedCalendarString(lang: string, key: string | undefined, text: string) {
    if (lang) {
      CalendarUtils.updateLocalizedCalendarString(this.calendar, lang, key, text);
      this._localizationService.addTranslations(lang, [{id: key, text}]);
      return key;
    } else {
      return text;
    }
  }

  private selectSection(section: ICalendarSection | undefined) {
    if (this._selectedSection !== section) {
      this._selectedSection = section;
      this.createTimeline(this._selectedSection);
      this.selectedSectionChange.emit(section);
    }

  }

  private loadEventTemplates() {
    if (this._calendar?.eventTemplates) {
      return of(this._calendar.eventTemplates.map(template => {
        if (template.module) {
            return template.module;
        }
        throw new Error(`The plugin ${template.templateModuleName} must be compiled in AOT mode.`);
      })).pipe(
        map((moduleFactories: Array<any>) => {
          const externalTemplates = moduleFactories.filter(f => f).map(module => {
            // resolve component factory
            const moduleRef = createNgModule(module, this._environmentInjector);
            return this.getTemplateComponentsProviders(moduleRef.injector);
          });
          const defaultTemplates = this.getTemplateComponentsProviders(this._environmentInjector);
          return [...externalTemplates, defaultTemplates];
        }),
        map(providerTemplates => {
          return [].concat(...providerTemplates); // flatten arrays
        })
      );
    } else {
      const defaultTemplates = this.getTemplateComponentsProviders(this._environmentInjector);
      return of(defaultTemplates);
    }
  }

  private getTemplateComponentsProviders(environmentInjector: EnvironmentInjector) {
    // get the custom made provider name 'calendarTemplates'
    const templateComponentsProviders = environmentInjector.get('calendarTemplates');

    return templateComponentsProviders[0].map((provider: any) => {
      return {
        name: provider.name,
        component: provider.component,
        default: provider.default
      };
    });
  }

  private createTimeline(section: ICalendarSection | undefined) {
    if (section) {
      this.timeLineBlocks = section.blocks.map(block => {
        return {
          blockId: block.id,
          tracks: block.tracks,
          timeline: this.createBlockTimeline(block)
        };
      });
    }
  }


  private createBlockTimeline(block: ICalendarSectionBlock): Array<ICalendarTimeRow> {
    // create points from events start & end times
    const distinctEventKeyPoints = ([] as { time: string | undefined, hidden: boolean }[]).concat(...block.tracks.map(track => ([] as { time: string | undefined, hidden: boolean }[]).concat(...track.events.map(event => [{
      time: event.start,
      hidden: false
    }, {time: event.end, hidden: true}]))))
      .sort((x, y) => {
        const sameTimes = (x.time ?? '').localeCompare(y.time ?? '');
        // hidden times to the end
        if (sameTimes === 0) {
          return x.hidden ? 1 : -1;
        }
        return sameTimes;
      })
      .filter((value, index, self) => {
        return self.findIndex(tm => tm.time === value.time) === index;
      });
    const blockRange = CalendarUtils.calculateBlockDates(block);

    const localizedTimeline = this._formatDateService.getTimeline(block.from || blockRange.from, block.to || blockRange.to, 0, true, distinctEventKeyPoints);

    const collapsedTrackRows = new Array(block.tracks.length).fill(0);
    const timeLine = localizedTimeline.map((locTime, timeLineRowIndex) => {
      const timeColumns = block.tracks.map((track, trackIndex) => {
        let eventsInTimelineBlock = collapsedTrackRows[trackIndex] === 0 ? track.events.filter(ev => {
          return this._formatDateService.isDateBetween(ev.start, locTime?.from, locTime?.to, '[)');
        }) : null;
        if (collapsedTrackRows[trackIndex] > 0) {
          collapsedTrackRows[trackIndex]--;
        }
        // check end time of last event in block to calculate rowspan
        let span = 1;
        if (eventsInTimelineBlock && eventsInTimelineBlock.length) {
          const lastEvent = eventsInTimelineBlock[eventsInTimelineBlock.length - 1];
          const endTimeLineRowIndex = localizedTimeline.findIndex(row => this._formatDateService.isDateBetween(lastEvent.end, row?.from, row?.to, '(]'));
          if (endTimeLineRowIndex > timeLineRowIndex) {
            const rowSpan = endTimeLineRowIndex - timeLineRowIndex + 1;
            if (span !== rowSpan) {
              span = rowSpan;
              // select all events from collected rows
              eventsInTimelineBlock = track.events.filter(ev => {
                return this._formatDateService.isDateBetween(ev.start, localizedTimeline[timeLineRowIndex]?.from, localizedTimeline[endTimeLineRowIndex]?.to, '[)');
              });
              collapsedTrackRows[trackIndex] = span - 1;
            }
          }

        }
        return {
          id: `time${locTime?.id}_column${track.id}`,
          events: eventsInTimelineBlock,
          span
        };
      }).filter(tr => tr.events);
      return locTime ? {
        id: `${block.id}_${locTime.id}`,
        from: locTime.from,
        to: locTime.to,
        text: locTime.text,
        columns: timeColumns,
        hidden: locTime.hidden,
        busy: !!block.tracks.find(track => !!track.events.find(ev => (ev.selected || (ev as ICalendarEventReservation).reserved || (ev as ICalendarEventOne2One).planned) && this._formatDateService.dateRangesOverlaps(locTime.from, locTime.to, ev.start, ev.end)))
      } : null;
    }).filter(b => !!b) as Array<ICalendarTimeRow>;
    return timeLine;
  }
}
