import { readOnlyEntity } from '@myia/ngx-core';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { ICalendarSectionBlockTrack } from './calendarSectionBlockTrack.interface';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function CalendarSectionBlockTrack(props: ICalendarSectionBlockTrack): ICalendarSectionBlockTrack {
    return readOnlyEntity({
      // @ts-ignore: TS2783
        id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.track),
        title: undefined,
      // @ts-ignore: TS2783
        events: undefined,
        ...props
    });
}


