import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormatDateService, LocalizationModule } from '@myia/ngx-localization';
import { fadeAnimation } from './fadeAnimation';
import { IsCounterWarningActivePipe } from './isCounterWarningActivePipe';

@Component({
    selector: 'count-down',
    standalone: true,
    imports: [
      CommonModule,
      LocalizationModule,
      IsCounterWarningActivePipe
    ],
    animations: [
        trigger('fade', [
            transition(':leave', [
                useAnimation(fadeAnimation, {
                    params: {
                        opacityFrom: 1,
                        opacityTo: 0,
                        time: '1s'
                    }
                })
            ]),
        ])
    ],
    template: `
        <div class="countdown" [ngClass]="{warning: end | isCounterWarningActive: warningDuration}">
            <div *ngFor="let part of displayNumbers; index as i; last as isLast; trackBy: trackDn" class="measurements {{part.id}}" [@fade] [@.disabled]="noFinalFade">
                <div class="measurements-number" [ngClass]="{last: isLast}">
                    {{ (showZero && part.number < 10) ? '0' + part.number : part.number}}
                </div>
                <div class="measurements-text" *ngIf="showLabels">
                    {{part.id}}
                </div>
            </div>
        </div>
        <ng-content></ng-content>
    `,
    styleUrls: ['./countDown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountDownComponent implements OnInit, OnDestroy {
    @Input() showZero = false;
    @Input() showLabels = false;
    @Input() noFinalFade = false; // useful when timer(end prop) is switching
    @Input() warningDuration = 0; // last n seconds will be displayed as red
    @Output() reached: EventEmitter<Date> = new EventEmitter();
    displayNumbers: Array<{ id: string, number: number }> = [];
    wasReached = false;

    private _end: any | undefined;

    private _lastDif: {
        d: number;
        h: number;
        m: number;
        s: number;
    } | undefined;
    private _interval: any;

    get end() {
        return this._end;
    }

    @Input() set end(value: any) {
        this._end = value;
        this.wasReached = false;
    }

    constructor(private _formatDateService: FormatDateService, private _cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this._updateTime();
        this._interval = setInterval(() => this._updateTime(), 100);
    }

    ngOnDestroy() {
        clearInterval(this._interval);
    }

    trackDn(index: number, dn: any) {
        return dn.id;
    }

    private _updateTime() {
        if (this.wasReached)
            return;

        const endDate = this._formatDateService.parseDate(this._end);
        const now: any = this._formatDateService.now();
        const dateDifference: number = endDate.diff(now);

        if (dateDifference <= 0) {
            this.wasReached = true;
            this.reached.next(now.toDate());
            this._cdr.markForCheck();
            return;
        }
        const secondsLeft = Math.ceil(dateDifference / 1000);
        this._formatDateService.getDurationPartsFromSeconds(secondsLeft).subscribe(dif => {
            if (!this._lastDif || !dif || (this._lastDif.d !== dif.d || this._lastDif.h !== dif.h || this._lastDif.m !== dif.m || this._lastDif.s !== dif.s)) {
                this._lastDif = dif;
                let updated = false;
                if (dateDifference > 0) {
                    const hideSeconds = !(dif.d || dif.h || dif.m || dif.s);
                    updated = this._updateDisplayNumber('days', dif.d, true) || updated;
                    updated = this._updateDisplayNumber('hours', dif.h, !(dif.d || dif.h)) || updated;
                    updated = this._updateDisplayNumber('minutes', dif.m, (this.showLabels || hideSeconds) && !(dif.d || dif.h || dif.m)) || updated;
                    updated = this._updateDisplayNumber('seconds', dif.s, hideSeconds) || updated;
                }
                if (!this.displayNumbers.length && !this.wasReached) {
                    this.wasReached = true;
                    this.reached.next(now.toDate());
                    this._cdr.markForCheck();
                }
                if (updated) {
                    this._cdr.markForCheck();
                }
            }
        });

    }


    private _updateDisplayNumber(numberId: string, value: number, removeWhenZero: boolean) {
        const currentNumberIndex = this.displayNumbers.findIndex(dn => dn.id === numberId);
        if (removeWhenZero && value === 0) {
            if (currentNumberIndex !== -1) {
                this.displayNumbers.splice(currentNumberIndex, 1);
                return true;
            }
            return false;
        }
        const displayNumber = {
            id: numberId,
            number: value
        };
        if (currentNumberIndex !== -1) {
            if (value === this.displayNumbers[currentNumberIndex].number) {
                return false; // not changed
            }
            this.displayNumbers.splice(currentNumberIndex, 1, displayNumber); // replace old value
            return true;
        }
        this.displayNumbers.push(displayNumber);
        return true;
    }
}
