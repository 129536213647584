import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value:any, prms?: {ignoreItems: Array<string>}): Array<string> {
    const keys = [];
    // eslint-disable-next-line guard-for-in
    for (const key in value) {
      if (!prms?.ignoreItems || prms.ignoreItems.indexOf(key) === -1) {
        keys.push(key);
      }
    }
    return keys;
  }
}
