import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, APP_INITIALIZER } from '@angular/core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { ToastrModule } from 'ngx-toastr';
import { SvgToastComponent } from '../svgToastComponent';
import { TOAST_CONFIGURATION } from '../toastModuleTokens';
import { IToastOptions } from '../toastOptions';

@NgModule({
  imports: [
    CommonModule,
    CoreUIModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    SvgToastComponent
  ],
  exports: [
    SvgToastComponent
  ]
})
export class ToastModule {
    static forRoot(config?: IToastOptions): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIGURATION,
          useValue: config ? config : {}
        },
        // Here, we're also providing a "run block" which is a function that will execute
        // at the very end of the bootstrapping process, right before the root component
        // is instantiated.
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [],
          useFactory: () => () => {
                    // insert toast symbols SVG to the document body
                    // @ts-ignore
                    import(/* webpackChunkName: "toast-symbols" */'!!raw-loader?!@myia/ngx-toast/src/lib/icons/symbols/toast.symbol.svg').then(toastIcons => {
                      const toastSymbolsElement = document.createElement('div');
                      toastSymbolsElement.insertAdjacentHTML('afterbegin', toastIcons.default || toastIcons);
                      const toastSymbolsSvg = toastSymbolsElement.lastElementChild as HTMLElement;
                      toastSymbolsSvg.setAttribute('id', '__TOAST_SPRITES__');
                      toastSymbolsSvg.style.position = 'absolute';
                      toastSymbolsSvg.style.width = '0px';
                      toastSymbolsSvg.style.height = '0px';
                      document.body.insertAdjacentElement('afterbegin', toastSymbolsSvg);
                    }); // load svg sprites icons
                  }
                }
            ]
    };
  }
}
