import { readOnlyEntity } from '@myia/ngx-core';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { ICalendarSection } from './calendarSection.interface';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function CalendarSection(props: ICalendarSection): ICalendarSection {
    return readOnlyEntity({
      // @ts-ignore: TS2783
        id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.section),
      // @ts-ignore: TS2783
        title: undefined,
      // @ts-ignore: TS2783
        icon: undefined,
      // @ts-ignore: TS2783
        blocks: undefined,
        ...props
    });
}

