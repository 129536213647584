/*
 * Example use
 *		Basic Array of single type: *ngFor="#todo of todoService.todos | orderBy : '-'"
 *		Multidimensional Array Sort on single column: *ngFor="#todo of todoService.todos | orderBy : ['-status']"
 *		Multidimensional Array Sort on multiple columns: *ngFor="#todo of todoService.todos | orderBy : ['status', '-title']"
 */

import { Pipe, PipeTransform } from '@angular/core';

// pipe class must have different name than 'OrderByPipe' -> it is in collision and cant be built by ngc
@Pipe({name: 'orderBy', pure: false})
export class OrderByPipe implements PipeTransform {

  static isFunction(functionToCheck: any) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  static _orderByComparator(a: any, b: any): number {

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      a = a ? a.toString() : '';
      b = b ? b.toString() : '';
      const result = a.localeCompare(b);
      // Isn't a number so lowercase the string to properly compare
      if (result < 0) {
        return -1;
      }
      if (result > 0) {
        return 1;
      }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) {
        return -1;
      }
      if (parseFloat(a) > parseFloat(b)) {
        return 1;
      }
    }

    return 0; // equal each other
  }

  transform(input: any, config: ((a: any, b: any) => number) | string | string[] = '+'): any {
    if (!Array.isArray(input)) {
      return input;
    }

    if (OrderByPipe.isFunction(config)) {
      // call slice to make a copy and do not mutate original array
      return input.slice().sort(config as (a: any, b: any) => number);
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = (!Array.isArray(config) ? config : config[0]) as string;
      const desc = propertyToCheck.substr(0, 1) === '-';

      // call slice to make a copy and do not mutate original array
      const output = input.slice();
      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? output.sort() : output.sort().reverse();
      } else {
        const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
          ? propertyToCheck.substr(1)
          : propertyToCheck;

        return output.sort((a: any, b: any) => {
          return !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      // Call slice to make a copy and do not mutate original array
      return input.slice().sort((a: any, b: any) => {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const property = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
            ? config[i].substr(1)
            : config[i];

          const comparison = !desc
            ? OrderByPipe._orderByComparator(a[property], b[property])
            : -OrderByPipe._orderByComparator(a[property], b[property]);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; // equal each other
      });
    }
  }
}
