import { Pipe, PipeTransform } from '@angular/core';
import { lightenColor } from '../utils/colorUtils';

@Pipe({
  name: 'lightenColor'
})
export class LightenColorPipe implements PipeTransform {
  transform(inputColor: string, amount: number): string {
    return lightenColor(inputColor, amount);
  }
}
