import { Injectable, EventEmitter, Inject, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { LOCALIZATION_CONFIGURATION } from '../localizationConfig';
import { ILocalizationConfig } from '../ILocalizationConfig.interface';
import { Logger } from '@myia/ngx-core';

@Injectable({providedIn: 'root'})
export class CultureService {

  onChange = new EventEmitter<string>();

  currentCulture$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(private _translateService: TranslateService, @Inject(LOCALIZATION_CONFIGURATION) private _configuration: ILocalizationConfig, private _logger: Logger, private _injector: Injector) {
    from(this.onChange).subscribe(this.currentCulture$);
  }

  get currentCulture() {
    return this._translateService.currentLang;
  }

  initialize() {
    return this.getStoredCulture().pipe(
      map((culture) => {
        if (!culture) {
          culture = this.getDefaultLang();
        }
        this._translateService.setDefaultLang(culture); // this language will be used as a fallback when a translation isn't found in the current language
        return culture;
      }),
      mergeMap(culture => {
        return this.use(culture);
      })
    );
  }

  use(culture: string): Observable<void> {
    // CultureService.putLanguageToStorage(culture);
    return this._translateService.use(culture).pipe(
      tap(() => {
        this._configuration.storeCulture(this._injector, culture);
        this.onChange.emit(culture);
      })
    );
  }

  getSupportedCultures(): Array<string> | null {
    return this._configuration.supportedCultures;
  }

  switchCulture(culture: string) {
    if (culture && culture !== this.currentCulture && this.getSupportedCultures()?.find(c => c === culture)) {
      this.use(culture).subscribe();
    }
  }

  private getStoredCulture(): Observable<string | undefined> {
    return this._configuration.getStoredCulture(this._injector);
  }

  private getDefaultLang(): string {
    return this._configuration && this._configuration.defaultCulture ? this._configuration.defaultCulture : 'en';
  }

}
