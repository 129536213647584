import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { addListener, removeAllListeners } from '@myia/ngx-core';

@Directive({
    selector: '[highlightInvalidFields]',
})
export class HighlightInvalidFieldsDirective {
    @Input() validationFormGroup: FormGroup | undefined;
    @Input() validatingCss = 'validating';
    private _fallbackTimeout: any;


    constructor(private _element: ElementRef) {
        if (_element.nativeElement.tagName !== 'FORM') {
            throw new Error('HighlightInvalidFields directive could be used on FORM elements only !');
        }
    }

    @HostListener('ngSubmit')
    highlightFields() {
        if (this.validationFormGroup && !this.validationFormGroup.valid) {
            // angular2 animation temporary disabled (css will be supported in next release)

            // temporary implement animation in JS
            const element = this._element.nativeElement;
            this.clearTimeout();

            const transitionEvents = ['transitionend', 'webkitTransitionEnd', 'oTransitionEnd', 'otransitionend', 'MSTransitionEnd'];
            addListener(element, transitionEvents, () => {
                removeAllListeners(element, transitionEvents);
                this.clearTimeout();
                element.classList.remove(this.validatingCss);
            });
            element.classList.add(this.validatingCss);
            // fallback when transitionend is not called
            this._fallbackTimeout = setTimeout(() => {
                this.clearTimeout();
                element.classList.remove(this.validatingCss);
            }, 500);

            //     const a = this._animationBuilder.css();
            //     a.addAnimationClass(this.validatingCss)
            //      .start(this._element.nativeElement)
            //      .onComplete(() => {
            //          a.removeClass('this.validatingCss)
            //           .start(this._element.nativeElement);
            //      });
            //
            //
        }
    }

    clearTimeout() {
        if (this._fallbackTimeout) {
            clearTimeout(this._fallbackTimeout);
            this._fallbackTimeout = null;
        }
    }
}
