import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'templateHasCountDown'
})
export class TemplateHasCountDownPipePipe implements PipeTransform {
    transform(template: string | null | undefined): boolean {
        return (template?.indexOf('<count-down') ?? -1) !== -1;
    }
}
