import {
  AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, createNgModule, ElementRef,
  EnvironmentInjector, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild,
} from '@angular/core';
import { getElementOffset, sortBy } from '@myia/ngx-core';
import { CalendarUtils } from '../entities/calendarUtils';
import { CultureService, FormatDateService, LocalizationService } from '@myia/ngx-localization';
import { forkJoin, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ICalendar } from '../entities/calendar.interface';
import { CalendarEventActions } from '../entities/calendarEventActions';
import { ICalendarSection } from '../entities/calendarSection.interface';
import { ICalendarSectionBlockTrack } from '../entities/calendarSectionBlockTrack.interface';
import { ICalendarEvent } from '../entities/calendarEvent.interface';

interface ICalendarItem {
  event: ICalendarEvent;
  track: ICalendarSectionBlockTrack;
  startSeconds: number;
  endSeconds: number;
  snapSteps: Array<number>;
  left?: number
  top?: number;
  width?: number;
  widthPercent?: number;
  height?: number;
  dragging?: boolean;

  updateEvent(): void;
}

interface ICalendarColumn {
  tracks: Array<ICalendarSectionBlockTrack>;
  id: string;
  title?: string;
  from?: number; // time in seconds
  to?: number; // time in seconds
  items?: Array<ICalendarItem>
  width?: number;
  itemDragging?: boolean;
}

class CalendarItem implements ICalendarItem {
  left?: number;
  top?: number;
  width?: number;
  widthPercent?: number;
  height?: number;
  dragging?: boolean;
  startSeconds!: number;
  endSeconds!: number;
  snapSteps!: Array<number>;

  constructor(public event: ICalendarEvent, public track: ICalendarSectionBlockTrack, private _formatDateService: FormatDateService) {
    this.updateEvent();
  }

  updateEvent() {
    this.startSeconds = this.getTotalSeconds(this.event.start);
    this.endSeconds = this.getTotalSeconds(this.event.end);
  }

  private getTotalSeconds(date?: string) {
    return date ? this._formatDateService.getLocalDateTimeFromISO8601String(date).unix() : 0;
  }

}

@Component({
  selector: 'calendar-scheduler-linear',
  styleUrls: ['./calendarSchedulerLinear.component.scss'],
  template: `
    <div *ngIf="loadingCalendar" class="calendarLoaderBlock">
      <progress-indicator-circle></progress-indicator-circle>
    </div>
    <div *ngIf="!loadingCalendar && calendar" class="blocksWrapper">
      <div *ngIf="selectedSection" class="sectionBlocks">
        <div #draggableBlock class="draggableBlock" [ngClass]="{dragging: dragging, init: layoutInitialized}">
          <div class="timeLineTrack"
               [ngStyle]="{width: timeLineWidth + 'px', paddingTop: (trackTitleHeight + 1) + 'px'}">
            <input-slider [min]="0" [max]="2" [step]="1" [value]="timeScale"
                          (valueChange)="timeScaleChanged($event)"></input-slider>
            <div class="time" *ngFor="let hr of sectionTimeFrom | timeLineHours: sectionTimeTo"
                 [ngStyle]="{height: hourHeight + 'px'}">{{hr}}</div>
          </div>
          <div class="track" *ngFor="let column of columns; trackBy: trackTrack"
               [ngClass]="{itemDragging: column.itemDragging, itemDragOver: column === dragOverColumn}">
            <div class="trackTitleBlock">
              <span
                [innerHTML]="column.title|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: (column.title|trans:{_lang_: defaultLanguage, _keyPrefix_: transKeyPrefix, _nullValue_: '', _suffix_: ' <span class=\\'defLang\\'>(' + defaultLanguage + ')</span>'})}"></span><span
              *ngIf="!column.items?.length" class="emptyFlag">&nbsp;{{'Program.Program_Empty_Track'|trans}}&nbsp;</span>
            </div>
            <div #programItem class="programItem" [ngClass]="{edit: edit, dragging: item.dragging}"
                 *ngFor="let item of column.items; trackBy: trackItem" draggableItem [width]="item.widthPercent!"
                 [height]="item.height!" [snapStepsHorizontal]="item.snapSteps" [trackBorderWidth]="trackBorderWidth"
                 [snapHeight]="timeStepHeight" [left]="item.left!" [top]="item.top!" [parent]="draggableBlock"
                 [topPadding]="trackTitleHeight" [leftPadding]="timeLineWidth" [edit]="edit"
                 (dragStart)="onProgramItemDragStart(column, item)" (move)="onProgramItemDragMove(column, item, $event)"
                 (dragEnd)="onProgramItemDragEnd(column, item, $event)"
                 (resizeUp)="onProgramItemResizeUp(column, item, $event)"
                 (resizeDown)="onProgramItemResizeDown(column, item, $event)"
                 (resizeUpEnd)="onProgramItemResizeUpEnd(column, item, $event)"
                 (resizeDownEnd)="onProgramItemResizeDownEnd(column, item, $event)" [tooltipDelay]="0"
                 [tooltip]="itemTooltip" tooltipContainer="body">
              <div class="itemResizeHandle up"></div>
              <scheduler-event [event]="item.event" [language]="language" [defaultLanguage]="defaultLanguage"
                               [eventTemplate]="item.event | calendarTemplate: eventTemplates"
                               (actionCalled)="onEventActionCalled(item, $event)"></scheduler-event>
              <div class="itemResizeHandle down"></div>
              <ng-template #itemTooltip class="calendarItemTooltip">
                <div class="title" *ngIf="item.event?.icon || (item.event|calendarEventTitle) ">
                  <svg-icon *ngIf="item.event.icon" prefix="calendarIcon" className="eventIcon"
                            [name]="item.event.icon"></svg-icon>
                  <span
                    [innerHTML]="item.event|calendarEventTitle|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: (item.event|calendarEventTitle|trans:{_lang_: defaultLanguage, _keyPrefix_: transKeyPrefix, _nullValue_: '', _suffix_: ' <span class=\\'defLang\\'>(' + defaultLanguage + ')</span>'})}"></span>
                </div>
                <div class="time">
                  <svg-icon name="clock" prefix="calendarIcon"></svg-icon>
                  <span class="timeParts"><span
                    class="timePart">{{item.event.start | formatDate: localTimeFormat | async }}</span><span
                    class="timePart second">{{item.event.end | formatDate: localTimeFormat | async}}</span></span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarSchedulerLinearComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  eventTemplates?: Array<{ name: string, component: unknown }>;
  loadingCalendar = false;
  transKeyPrefix = CalendarUtils.TRANSLATION_KEY_PREFIX;

  timeStepHeight = 10; // pixels per minutesStep
  trackBorderWidth = 1; // $trackBorderWidth = 1;
  trackTitleHeight = 30;
  columns?: Array<ICalendarColumn>;
  draggableViewRect = {left: 0, top: 0};
  layoutInitialized = false;

  zoom = 1.3;
  minutesStep = 5; // step size in minutes
  timeScale = 2; // slider values: 0,1,2

  timeLineWidths: Map<string, number> = new Map([['cs', 50], ['en', 80]]); // widths of timeline column for languages
  timeLineWidth?: number; // width of timeline column
  sectionTimeFrom?: number; // selected section start time in unix format
  sectionTimeTo?: number; // selected section end time in unix format

  dragging = false;
  dragOverColumn: ICalendarColumn | undefined;

  @Output() actionCalled = new EventEmitter<{ event: ICalendarEvent, action: CalendarEventActions, data?: any }>();

  private _lastDraggableBlockElWidth?: number;
  private _gridBGImage?: { url: string, width: number, height: number };

  @ViewChild('draggableBlock', {static: false}) set draggableBlockEl(val: ElementRef) {
    this._draggableBlockEl = val;
    this.updateLayout(true);
  };

  get selectedSection() {
    return this._selectedSection;
  }

  @Input() set selectedSection(section: ICalendarSection | undefined) {
    this.selectSection(section);
  }

  @Output() selectedSectionChange = new EventEmitter<ICalendarSection>();

  get calendar(): ICalendar | undefined {
    return this._calendar;
  }

  @Input() set calendar(newCalendar: ICalendar | undefined) {
    if (this._calendar !== newCalendar) {
      const loadTemplates = newCalendar && (!this._calendar || this._calendar.id !== newCalendar.id);
      this._calendar = newCalendar;

      if (loadTemplates) {
        this.loadingCalendar = true;
        this._cdr.markForCheck();
        this.loadEventTemplates().pipe(
          tap(eventTemplates => {
            this.eventTemplates = eventTemplates;
            this.loadingCalendar = false;
            this._cdr.markForCheck();
          })
        ).subscribe();
      }
    }
  }

  @Input() edit = false;
  @Input() language?: string;
  @Input() defaultLanguage?: string;

  hourHeight?: number;

  localTimeFormat = 'hh:mm A';


  private _calendar?: ICalendar;
  private _selectedSection?: ICalendarSection;
  private _onCultureChange: Subscription | undefined;

  private _draggableBlockEl?: ElementRef;
  private _trackWidth = 100;

  constructor(private _cdr: ChangeDetectorRef, private _cultureService: CultureService, private _formatDateService: FormatDateService, private _environmentInjector: EnvironmentInjector, private _localizationService: LocalizationService) {
  }

  ngOnInit() {
    this.updateLocalTimeFormat();
    this._onCultureChange = this._cultureService.onChange.subscribe(() => {
      this.updateLocalTimeFormat();
    });
  }

  ngOnDestroy() {
    if (this._onCultureChange) {
      this._onCultureChange.unsubscribe();
      this._onCultureChange = undefined;
    }
  }

  ngAfterViewChecked() {
    if (this._draggableBlockEl && this._lastDraggableBlockElWidth !== this._draggableBlockEl.nativeElement.offsetWidth) {
      this.updateLayout(true);
    }
  }

  ngOnChanges() {
    this.updateLayout();
  }

  @HostListener('window:resize')
  windowResize() {
    this.updateLayout(true);
  }

  onProgramItemDragStart(column: ICalendarColumn, item: ICalendarItem) {
    column.itemDragging = true;
    item.dragging = true;
    this.dragging = true;
    this._cdr.markForCheck();
  }

  onProgramItemDragMove(column: ICalendarColumn, item: ICalendarItem, dragEvt: { left: number; top: number }) {
    this.updateItemTime(column, item, dragEvt);
    this.hiliteDragOverColumn(item, dragEvt.left);
  }

  onProgramItemDragEnd(column: ICalendarColumn, item: ICalendarItem, dragEvt: { left: number; top: number }) {
    this.dragOverColumn = undefined;
    this.updateItemTime(column, item, dragEvt);

    // update original event
    const event = item.track.events.find(e => e.id === item.event.id);
    if (event) {
      event.start = item.event.start;
      event.end = item.event.end;
    }

    // detect track change
    const columnIndex = this.getColumnIndexFromPosition(item, dragEvt.left);
    const itemColumn = this.columns?.[columnIndex];
    if (itemColumn && itemColumn.id !== column.id) {
      // move item to different track
      item.track.events.splice(item.track.events.findIndex(e => e.id === item.event.id), 1);
      const targetTrack = itemColumn.tracks[0]; // TODO find best track to add to
      targetTrack.events.push(item.event);
      console.log(`Item column changed: ${columnIndex}`);
    }

    setTimeout(() => {
      this.dragging = false;
      column.itemDragging = false;
      item.dragging = false; // clear flag later to be able to detect dragging state in onEventActionCalled(...)
      this.updateLayout(true);
    });
  }

  onProgramItemResizeUp(column: ICalendarColumn, item: ICalendarItem, top: number) {
    // update item time
    const startTime = this.getColumnTimeFromPosition(column, top);
    item.event = {
      ...item.event,
      start: this._formatDateService.getLocalDateTimeString(new Date(startTime * 1000))
    };
    item.updateEvent();
    if (this.sectionTimeFrom) {
      item.top = this.draggableViewRect.top + this.trackTitleHeight + this.getTimeHeight(item.startSeconds - this.sectionTimeFrom);
      console.log('resize item.top:' + item.top);
      item.height = this.getTimeHeight(item.endSeconds - item.startSeconds);
    }
    this._cdr.markForCheck();
  }

  onProgramItemResizeUpEnd(column: ICalendarColumn, item: ICalendarItem, top: number) {
    this.onProgramItemResizeUp(column, item, top);

    // update original event
    const event = item.track.events.find(e => e.id === item.event.id);
    if (event) {
      event.start = item.event.start;
      event.end = item.event.end;
    }

    this.updateLayout(true);
  }

  onProgramItemResizeDown(column: ICalendarColumn, item: ICalendarItem, bottom: number) {
    // update item time
    const endTime = this.getColumnTimeFromPosition(column, bottom);
    item.event = {
      ...item.event,
      end: this._formatDateService.getLocalDateTimeString(new Date(endTime * 1000))
    };
    item.updateEvent();
    item.height = this.getTimeHeight(item.endSeconds - item.startSeconds);
    this._cdr.markForCheck();
  }

  onProgramItemResizeDownEnd(column: ICalendarColumn, item: ICalendarItem, bottom: number) {
    this.onProgramItemResizeDown(column, item, bottom);

    // update original event
    const event = item.track.events.find(e => e.id === item.event.id);
    if (event) {
      event.start = item.event.start;
      event.end = item.event.end;
    }
    this.updateLayout(true);
  }

  trackTrack(index: number, track: ICalendarColumn): string {
    return track.id;
  }

  trackItem(index: number, item: ICalendarItem): string {
    return item.event.id;
  }

  onEventActionCalled(item: ICalendarItem, e: any) {
    if (!item.dragging) {
      this.actionCalled.emit({
        event: item.event,
        ...e
      });
    }
  }

  calculateSnapSteps(options: { columnIndex: number, left: number } | null) {
    const snapStepsHorizontal: Array<number> = [];
    if (this.timeLineWidth) {
      let lastSnapStepPos = this.timeLineWidth;
      // update columns width && items
      this.columns?.forEach((column, columnIndex) => {
        if (options && options.columnIndex === columnIndex) {
          snapStepsHorizontal.push((columnIndex > 0 ? snapStepsHorizontal[columnIndex - 1] : lastSnapStepPos) + options.left);
        } else {
          snapStepsHorizontal.push(lastSnapStepPos);
        }
        lastSnapStepPos += column?.width ?? 0;
      });
    }
    return snapStepsHorizontal;
  }

  timeScaleChanged(scale: number) {
    this.zoom = 1.3 * (scale + 1);
    this.updateLayout(true);
  }

  private updateLocalizedCalendarString(lang: string, key: string, text: string) {
    if (lang) {
      CalendarUtils.updateLocalizedCalendarString(this.calendar, lang, key, text);
      this._localizationService.addTranslations(lang, [{id: key, text}]);
      return key;
    } else {
      return text;
    }
  }

  private selectSection(section: ICalendarSection | undefined) {
    if (this._selectedSection !== section) {
      this._selectedSection = section;
      this.selectedSectionChange.emit(section);
      setTimeout(() => {
        this.updateLayout(true);
      });
    }

  }

  private loadEventTemplates() {
    if (this._calendar?.eventTemplates) {
      return forkJoin(this._calendar.eventTemplates.map(template => {
        if (template.module) {
          return of(template.module);
        }
        return of(null);
      })).pipe(
        map(modules => {
          const externalTemplates = modules.filter(f => f).map(module => {
            // resolve component factory
            const moduleRef = createNgModule(module!, this._environmentInjector);
            return this.getTemplateComponentsProviders(moduleRef.injector);
          });
          const defaultTemplates = this.getTemplateComponentsProviders(this._environmentInjector);
          return [...externalTemplates, defaultTemplates];
        }),
        map(providerTemplates => {
          return [].concat(...providerTemplates); // flatten arrays
        })
      );
    } else {
      const defaultTemplates = this.getTemplateComponentsProviders(this._environmentInjector);
      return of(defaultTemplates);
    }
  }

  private getTemplateComponentsProviders(environmentInjector: EnvironmentInjector) {
    // get the custom made provider name 'calendarTemplates'
    const templateComponentsProviders = environmentInjector.get('calendarTemplates');

    return templateComponentsProviders[0].map((provider: any) => {
      return {
        name: provider.name,
        component: provider.component,
        default: provider.default
      };
    });
  }

  private getTrackWidth() {
    return this._trackWidth;
  }

  private updateLayout(applyChanges?: boolean) {
    this.timeLineWidth = this.timeLineWidths.get(this._cultureService.currentCulture) || this.timeLineWidths.get('en');
    if (this.selectedSection) {
      const columns: Array<ICalendarColumn> = [];
      this.selectedSection.blocks.forEach(block => {
        block.tracks.forEach((track, trackIndex) => {
          const existingColumn = columns.find(c => c.title === track.title);
          if (!existingColumn) { // match tracks by name across blocks
            columns.push({
              tracks: [track],
              id: track.id,
              title: track.title,
            });
          } else {
            existingColumn.tracks.push(track);
          }
        });
      });
      this.columns = columns;
    }
    if (this._draggableBlockEl && this.columns && this.columns.length && this.timeLineWidth) {
      const width = this._draggableBlockEl.nativeElement.offsetWidth;
      this._lastDraggableBlockElWidth = width;
      this._trackWidth = (width - this.timeLineWidth - this.trackBorderWidth * (this.columns.length - 1)) / this.columns.length;
      this.updateDraggableViewRect();
      this.timeStepHeight = this.zoom * this.minutesStep;

      // update columns width && items
      this.columns.forEach((column, columnIndex) => {
        column.items = [];
        column.tracks.forEach(track => {
          track.events.map((event, eventIndex) => {
            column.items?.push(new CalendarItem(event, track, this._formatDateService));
          });
        });
        column.from = Math.min(...column.items.map(i => i.startSeconds));
        column.to = Math.max(...column.items.map(i => i.endSeconds));
        column.width = this.getTrackWidth();
      });

      this.sectionTimeFrom = Math.min(...this.columns.map(c => c.from ?? 0));
      this.sectionTimeTo = Math.max(...this.columns.map(c => c.to ?? 0));

      // calculate min/max hour
      this.sectionTimeFrom = Math.floor(this.sectionTimeFrom / 3600) * 3600;
      this.sectionTimeTo = Math.floor(this.sectionTimeTo / 3600) * 3600 + 3600;

      this.columns.forEach((column, columnIndex) => {
        column.items?.forEach(item => {
          const itemPos = this.getItemPosition(columnIndex, item);
          item.left = itemPos.left;
          item.top = itemPos.top;
          item.width = itemPos.width;
          item.widthPercent = (itemPos.width / this._trackWidth) * 100;
          item.height = itemPos.height;
          item.snapSteps = this.calculateSnapSteps(itemPos.conflict ? {columnIndex, left: itemPos.leftOffset} : null);
          // console.log(`snapSteps: ${JSON.stringify(item.snapSteps)}`);
        });
      });

      this.hourHeight = this.getTimeHeight(3600); // 1 hour height
      this.createGridBGImage();

      const tracks = this._draggableBlockEl.nativeElement.querySelectorAll('.track');
      tracks.forEach((tEl: HTMLElement) => {
        tEl.style.backgroundImage = `url('${this._gridBGImage?.url}')`;
        tEl.style.backgroundPositionY = `${this.trackTitleHeight + 1}px`;
        tEl.style.backgroundRepeat = 'repeat-y';
      });

      if (applyChanges) {
        this._cdr.detectChanges();
      }

      if (!this.layoutInitialized) {
        setTimeout(() => {
          this.layoutInitialized = true;
          this._cdr.detectChanges();
        });
      }
    }
  }

  private getItemPosition(columnIndex: number, item: ICalendarItem): { left: number, top: number, width: number, height: number, conflict: boolean, leftOffset: number } {
    const column = this.columns?.[columnIndex] as ICalendarColumn;
    const itemBounds = this.getItemBounds(column.items, item);
    const left = columnIndex * (this._trackWidth + this.trackBorderWidth) + itemBounds.left;
    return {
      left: this.draggableViewRect.left + (this.timeLineWidth ?? 0) + left,
      top: this.draggableViewRect.top + this.trackTitleHeight + this.getTimeHeight(item.startSeconds - (this.sectionTimeFrom ?? 0)),
      width: itemBounds.width,
      height: this.getTimeHeight(item.endSeconds - item.startSeconds),
      conflict: itemBounds.conflict,
      leftOffset: left
    };
  }

  private getColumnTimeFromPosition(column: ICalendarColumn, top: number): number {
    this.updateDraggableViewRect();
    top -= this.draggableViewRect.top + this.trackTitleHeight;
    const pixelsPerSecond = this.timeStepHeight / this.minutesStep / 60;
    const time = top / pixelsPerSecond;
    const roundStepInSeconds = (this.minutesStep * 60);
    const timeFromStart = Math.floor(time / roundStepInSeconds) * roundStepInSeconds;
    return (this.sectionTimeFrom ?? 0) + timeFromStart;
  }

  private getItemBounds(allColumnItems: Array<ICalendarItem> | undefined, item: ICalendarItem): { width: number, left: number, conflict: boolean } {
    const conflictingItems = allColumnItems ? sortBy(allColumnItems.filter(i => this.eventsOverlapping(i, item)), i => i.startSeconds) : [];
    const conflictingItemsMargin = 1;
    const totalWidth = this._trackWidth / conflictingItems.length;
    const width = totalWidth - conflictingItemsMargin;
    const index = conflictingItems.indexOf(item);
    return {
      left: index * totalWidth,
      width,
      conflict: conflictingItems.length > 1
    };
  }

  private getTimeHeight(seconds: number) {
    return this.timeStepHeight * seconds / 60 / this.minutesStep;
  }

  private updateItemTime(column: ICalendarColumn, item: ICalendarItem, dragEvt: { left: number; top: number }) {
    // update item time
    const startTime = this.getColumnTimeFromPosition(column, dragEvt.top);
    // console.log(`Start time: ${startTime}`);
    const eventLength = item.event.start && item.event.end ? this._formatDateService.getDurationFromDates(item.event.start, item.event.end) : 0;
    item.event = {
      ...item.event,
      start: this._formatDateService.getLocalDateTimeString(new Date(startTime * 1000)),
      end: this._formatDateService.getLocalDateTimeString(new Date((startTime + eventLength) * 1000))
    };
    item.left = dragEvt.left;
    item.top = dragEvt.top;
    // console.log(`duration: ${item.event.start} - ${item.event.end} = ${eventLength}`);
  }

  private updateDraggableViewRect() {
    this.draggableViewRect = this._draggableBlockEl ? getElementOffset(this._draggableBlockEl.nativeElement) as any : {
      left: 0,
      top: 0
    };
  }

  private eventsOverlapping(a: ICalendarItem, b: ICalendarItem) {
    if (b.startSeconds < a.startSeconds) {
      return b.endSeconds > a.startSeconds;
    } else {
      return b.startSeconds < a.endSeconds;
    }
  }

  private createGridBGImage() {
    const width = this._trackWidth + this.trackBorderWidth as number;
    const height = this.hourHeight as number;
    if (!this._gridBGImage || this._gridBGImage.width !== width || this._gridBGImage.height !== height) {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('width', `${width}`);
      canvas.setAttribute('height', `${height}`);
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = 'rgba(255,255,255, 0.1)';
        ctx.beginPath();
        ctx.moveTo(width - 1, 0);
        ctx.lineTo(width - 1, height - 1);
        ctx.lineTo(0, height - 1);
        ctx.lineTo(0, height - 2);
        ctx.lineTo(width - 2, height - 2);
        ctx.lineTo(width - 2, 0);
        ctx.fill();
      }
      this._gridBGImage = {
        url: canvas.toDataURL(),
        width,
        height
      };
    }
  }

  private updateLocalTimeFormat() {
    this.localTimeFormat = this._formatDateService.getCurrentLocalTimeFormat({withZeroPadding: true});
  }

  private hiliteDragOverColumn(item: ICalendarItem, left: number) {
    const columnIndex = this.getColumnIndexFromPosition(item, left);
    const dragOverColumn = columnIndex === -1 ? undefined : this.columns?.[columnIndex];
    if (this.dragOverColumn !== dragOverColumn) {
      this.dragOverColumn = dragOverColumn;
      this._cdr.markForCheck();
    }
  }

  private getColumnIndexFromPosition(item: ICalendarItem, left: number) {
    return Math.floor((left - this.draggableViewRect.left - (this.timeLineWidth ?? 0) + (item.width ?? 0) / 2) / this._trackWidth);
  }
}
