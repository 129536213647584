import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexColorValue'
})
export class HexColorValuePipe implements PipeTransform {
  transform(inputColorString: string | undefined | null): string | undefined | null {
    return inputColorString?.length && inputColorString[0] === '#' ? inputColorString.substring(1) : inputColorString;
  }
}
