import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { FormatDateService } from '@myia/ngx-localization';

@Pipe({
  name: 'timeLineHours',
  pure: false
})
export class CalendarTimeLineHoursPipe implements PipeTransform {

  constructor(private _formatDateService: FormatDateService, private _cdr: ChangeDetectorRef) {
  }

  transform(from?: number, to?: number): any {
    const dates = [];
    if (from != null && to != null) {
      const fromDate = new Date(from * 1000);
      fromDate.setMinutes(0, 0, 0);
      const toDate = new Date(to * 1000);
      while (fromDate < toDate) {
        dates.push(this._formatDateService.getTimeString(fromDate, {withZeroPadding: true}));
        fromDate.setTime(fromDate.getTime() + (60 * 60 * 1000)); // add 1 hour
      }
    }
    return dates;
  }
}
