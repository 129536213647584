import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { DragulaService } from './dragula.provider';
import dragula from 'dragula';
import { NgChanges } from '@myia/ngx-core';

@Directive({
  selector: '[dragula]'
})
export class DragulaDirective implements OnInit, OnChanges {
  @Input() dragula!: string;
  @Input() bag!: string;
  @Input() dragulaModel: any;
  private container: any;
  private drake: any;

  constructor(private el: ElementRef, private dragulaService: DragulaService) {
    this.container = el.nativeElement;
  }

  ngOnInit() {
    // console.log(this.bag);
    const bag = this.dragulaService.find(this.bag);
    const checkModel = () => {
      if (this.dragulaModel) {
        if (this.drake.models) {
          this.drake.models.push(this.dragulaModel);
        } else {
          this.drake.models = [this.dragulaModel];
        }
      }
    };
    if (bag) {
      this.drake = bag.drake;
      checkModel();
      this.drake.containers.push(this.container);
    } else {
      const options = {
        containers: [this.container]
      };
      this.drake = dragula(options);
      checkModel();
      this.dragulaService.add(this.bag, this.drake, options);
    }
  }

  ngOnChanges(changes: NgChanges<DragulaDirective>) {
    // console.log('dragula.directive: ngOnChanges');
    // console.log(changes);
    if (changes && changes.dragulaModel) {
      if (this.drake) {
        if (this.drake.models) {
          const modelIndex = this.drake.models.indexOf(changes.dragulaModel.previousValue);
          this.drake.models.splice(modelIndex, 1, changes.dragulaModel.currentValue);
        } else {
          this.drake.models = [changes.dragulaModel.currentValue];
        }
      }
    }
  }
}
