import { Pipe, PipeTransform } from '@angular/core';
import { IRGBColor } from '../utils/colorTypes';
import { hexStringToRgb } from '../utils/colorUtils';

@Pipe({
  name: 'RGBColorValue'
})
export class RGBColorValuePipe implements PipeTransform {
  transform(inputColorString: string | undefined | null): IRGBColor | undefined | null {
    return inputColorString ? hexStringToRgb(inputColorString) : inputColorString as undefined | null;
  }
}
