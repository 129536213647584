import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { CoreModule } from '@myia/ngx-core';
import { FormsModule as SidasFormsModule } from '@myia/ngx-forms';
import { ColorPickerComponent } from './components/colorPicker.component';
import { ColorWheelPickerComponent } from './components/colorWheelPickerComponent';
import { HexColorValuePipe } from './pipes/hexColorValue.pipe';
import { LightenColorPipe } from './pipes/lighterColor.pipe';
import { OppositeColorPipe } from './pipes/oppositeColor.pipe';
import { RGBColorValuePipe } from './pipes/rgbColorValue.pipe';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    CoreUIModule,
    FormsModule,
    SidasFormsModule
  ],
  declarations: [
    ColorPickerComponent,
    ColorWheelPickerComponent,
    HexColorValuePipe,
    LightenColorPipe,
    OppositeColorPipe,
    RGBColorValuePipe
  ],
  exports: [
    ColorPickerComponent,
    ColorWheelPickerComponent,
    HexColorValuePipe,
    LightenColorPipe,
    OppositeColorPipe,
    RGBColorValuePipe
  ]
})
export class ColorPickerModule {
}
