import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { HttpErrorInterceptor } from './httpErrorInterceptor';
import { HTTP_CONFIG, HttpModuleConfig } from './httpModuleConfig';
import { JWTInterceptor } from './JWTInterceptor';

@NgModule({
    imports: [CoreModule, HttpClientModule]
})
export class HttpModule {
  static forRoot(config: HttpModuleConfig): ModuleWithProviders<HttpModule> {
    return {
      ngModule: HttpModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JWTInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true
        },
        {
          provide: HTTP_CONFIG,
          useValue: config,
        },
      ]
    };
  }
}
