import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'input-error',
  styleUrls: ['./inputError.component.scss'],
  template: `
    <div class="signTooltip" [tooltip]="errorsList" [tooltipClass]="'fieldErrorTooltip ' + this.indicatorKind" tooltipContainer="body" tooltipPlacement="top" [tooltipOffset]="[0, 10]" *ngIf="indicatorKind === 'sign'"></div>
    <div *ngIf="indicatorKind === 'text'">
      <ng-container *ngTemplateOutlet="errorsList"></ng-container>
    </div>
    <ng-template #errorsList>
      <div class="errors">
        <div class="error" *ngFor="let errKey of errors | keys">{{'Validations.Error_' + errKey | trans: (errors?.[errKey] ?? {}) }}</div>
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent {
  @HostBinding('class') get hostClasses() {
    return 'inputError ' + this.indicatorKind + (this.errors ? ' show' : '');
  }
  @Input() indicatorKind: 'sign' | 'text' = 'sign';
  @Input() errors: ValidationErrors | null = null;
}
