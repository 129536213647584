import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'formDataChanged' })
export class FormDataChangedPipe implements PipeTransform, OnDestroy {
  private _destroy$ = new Subject<void>();

  transform(form: FormGroup, originalEntity: any): Observable<boolean> {
    this.destroySub();
    if (originalEntity instanceof Function) {
      return originalEntity();
    }
    return form?.valueChanges.pipe(
      map(value => {
        const changed = Object.keys(value).some(key => form.value[key] !== originalEntity[key]);
        return changed;
      }),
      takeUntil(this._destroy$)
    );
  }

  ngOnDestroy() {
    this.destroySub();
  }

  private destroySub() {
    this._destroy$.next();
  }
}
