import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { CalendarUtilsInternal } from './calendarUtilsInternal';
import { ICalendarEventReservation } from './calendarEventReservation.interface';
import { ICalendarEventOne2One } from './calendarEventOne2One.interface';
import { CalendarEventOne2One } from './calendarEventOne2One';
import { CalendarEventReservation } from './calendarEventReservation';
import { CalendarEvent } from './calendarEvent';
import { ICalendarEvent } from './calendarEvent.interface';
import { ICalendar } from './calendar.interface';
import { ICalendarSectionBlock } from './calendarSectionBlock.interface';
import { CalendarSectionBlockTrack } from './calendarSectionBlockTrack';
import { ICalendarSectionBlockTrack } from './calendarSectionBlockTrack.interface';

// @dynamic
export abstract class CalendarUtils {
  static TRANSLATION_KEY_PREFIX = '_calendar_';

  private static transId = 0;

  static initialize(calendar: ICalendar) {
    // init id generator
    CalendarUtilsInternal.initialize(calendar);
  }

  static newCalendarEntityId(prefix: CalendarIdPrefixes): string {
    return CalendarUtilsInternal.newCalendarEntityId(prefix);
  }

  static isCalendarTranslationKey(key: string | undefined): boolean {
    return key?.indexOf(CalendarUtils.TRANSLATION_KEY_PREFIX) === 0;
  }

  static checkCalendarTranslationKeyUsage(key: string, calendar: ICalendar, removeUnused?: boolean): boolean {
    if (CalendarUtils.calendarTranslationKeyExists(calendar, key)) {
      if (CalendarUtils.objectContains(calendar.sections, key)) {
        if (removeUnused) {
          CalendarUtils.removeCalendarTranslationKey(calendar, key);
        }
        return true;
      }
    }
    return false;
  }

  static getCalendarTranslationKey(currentKey: string | undefined, calendar: ICalendar | undefined): string {
    if (currentKey && CalendarUtils.isCalendarTranslationKey(currentKey)) {
      return currentKey;
    }
    // generate new key
    let newKey = '';
    do {
      newKey = `${CalendarUtils.TRANSLATION_KEY_PREFIX}${CalendarUtils.transId++}`;
    } while (CalendarUtils.calendarTranslationKeyExists(calendar, newKey));
    return newKey;
  }

  static calculateBlockDates(block: ICalendarSectionBlock): { from: string | undefined, to: string | undefined } {
    let min = '9';
    let max = '0';
    block.tracks.forEach(t => {
      t.events.forEach(e => {
        if (e.start != null && e.start < min) {
          min = e.start;
        }
        if (e.end && e.end > max) {
          max = e.end;
        }
      });
    });
    return {
      from: min === '9' ? undefined : min,
      to: max === '0' ? undefined : max
    };
  }

  static cloneTrack(calendar: ICalendar, sourceTrack: ICalendarSectionBlockTrack): ICalendarSectionBlockTrack {
    const {id, events, ...sourceTrackProps} = sourceTrack;
    return CalendarSectionBlockTrack({
      ...sourceTrackProps,
      title: CalendarUtils.cloneTranslation(calendar, sourceTrack.title),
      events: events.map(e => CalendarUtils.cloneEvent(calendar, e))
    } as any);
  }

  static cloneEvent(calendar: ICalendar, sourceEvent: ICalendarEvent, preserveId?: boolean): ICalendarEvent | undefined {
    let sourceEventProps = {...sourceEvent};
    if (!preserveId) {
      // clone localized props
      sourceEventProps.title = CalendarUtils.cloneTranslation(calendar, sourceEventProps.title);

      const {id, ...eventProps} = sourceEvent;
      sourceEventProps = eventProps as any;

    }
    switch (sourceEventProps.type) {
      case 'Info':
      case 'Program':
        return CalendarEvent(sourceEventProps as ICalendarEvent);
      case 'Reservation':
        return CalendarEventReservation(sourceEventProps as ICalendarEventReservation);
      case 'One2One':
        return CalendarEventOne2One(sourceEventProps as ICalendarEventOne2One);
    }
    return undefined;
  }

  static updateLocalizedCalendarString(calendar: ICalendar | undefined, lang: string, key: string | undefined, text: string) {
    const currentLangTranslations = calendar?.translations?.find(t => t.lang === lang);
    const currentKeyTrans = currentLangTranslations?.strings.find(t => t.id === key);
    if (!currentKeyTrans) {
      currentLangTranslations?.strings.push({
        id: key,
        text
      });
    } else {
      currentKeyTrans.text = text;
    }
  }

  static cloneTranslation(calendar: ICalendar, key?: string, suffix?: string): string | undefined {
    if (CalendarUtils.calendarTranslationKeyExists(calendar, key)) {
      const clonedKey = CalendarUtils.getCalendarTranslationKey(undefined, calendar);
      calendar.translations?.forEach(t => {
        const keyTrans = t.strings.find(s => s.id === key);
        if (keyTrans) {
          t.strings.push({
            id: clonedKey,
            text: keyTrans.text + (suffix || '')
          });
        }
      });
      return clonedKey;
    }
    return key;
  }

  private static calendarTranslationKeyExists(calendar: ICalendar | undefined, key?: string): boolean {
    return !!calendar?.translations?.find(t => !!t.strings.find(s => s.id === key));
  }

  private static removeCalendarTranslationKey(calendar: ICalendar, key: string) {
    calendar.translations?.forEach(t => {
      const keyIndex = t.strings.findIndex(s => s.id === key);
      if (keyIndex !== -1) {
        t.strings.splice(keyIndex, 1);
      }
    });
  }

  private static objectContains(obj: any, term: string): { obj: any, key: string } | undefined {
    if (obj) {
      if (Array.isArray(obj)) {
        for (const i of obj) {
          const itemResult = CalendarUtils.objectContains(i, term);
          if (itemResult) {
            return itemResult;
          }
        }
      } else {
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].indexOf(term) !== -1) {
            return {obj, key};
          }
        }
      }
    }
    return undefined;
  }

}
