import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { getParentScroll } from '@myia/ngx-core';

@Directive({
  selector: '[errorFocusIn]'
})
export class ErrorFocusInDirective {

  @Input() formGroup: NgForm | undefined;
  @Input() errorFocusIn = false;

  private _selectorToFocus = 'textArea,mat-select,select,input,button';

  constructor(private el: ElementRef) {
  }

  private static getElementToOffset(element: HTMLElement) {
    const defaultElement = element;
    while (!(element.parentElement instanceof HTMLFormElement)) {
      if (element.parentElement) {
        element = element.parentElement;
      } else {
        return defaultElement;
      }
    }
    return element;
  }

  @HostListener('submit', ['$event'])
  onSubmit(event: MouseEvent): void {
    if (this.errorFocusIn && 'INVALID' === this.formGroup?.status) {
      event.preventDefault();
      const formGroupInvalid = this.el.nativeElement.querySelectorAll('.ng-invalid');
      const elementToOffset = ErrorFocusInDirective.getElementToOffset(formGroupInvalid[0]);
      const parentScroll = getParentScroll(elementToOffset);
      if (parentScroll) {
        parentScroll.scrollTop = elementToOffset.offsetTop;
      }
      this.setFocusOnError(elementToOffset);
    }
  }

  private setFocusOnError(elementToOffset: HTMLElement) {
    const els = elementToOffset.querySelectorAll<HTMLElement>(this._selectorToFocus);
    if (els.length > 0) {
      setTimeout(() => {
        els[0].focus();
      });
    }
  }

}
