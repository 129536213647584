import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[detectBrowser]'
})
export class DetectBrowserDirective implements AfterViewInit {

    constructor(private _element: ElementRef) {
    }

    ngAfterViewInit() {
        if (this.isiOS()) {
            this._element.nativeElement.classList.add('ios');
        }
    }

    // detect iOS
    private isiOS(): boolean {
        const sAgent = navigator.userAgent;
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
      // eslint-disable-next-line @typescript-eslint/dot-notation
        return (/iPad|iPhone|iPod/.test(sAgent) && !(window as { [key: string]: any })['MSStream']) && navigator.maxTouchPoints > 1;
    }

}
