import { AfterViewInit, Component, ElementRef, EventEmitter, inject, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DropDownComponent } from './dropDown.component';

@Component({
  template: ``
})
export abstract class PickerComponent<TItem> implements OnInit, AfterViewInit, OnDestroy {

  @Output() initialized = new EventEmitter<PickerComponent<TItem>>();
  @Output() itemSelected = new EventEmitter<TItem | null>();
  @Output() pickerClicked = new EventEmitter<MouseEvent>();

  @ViewChild('field') pickerField?: ElementRef;

  @ViewChild('popup', { read: DropDownComponent, static: false }) set popup(value: DropDownComponent | undefined) {
    this._popup = value;
    if (this._initialized) {
      this._popup?.initialized();
    }
  };

  get popup(): DropDownComponent | undefined {
    return this._popup;
  }

  dropDownVisible$ = new BehaviorSubject<boolean>(false);

  protected get dropDownDisplayed() {
    return this.pickerField?.nativeElement?.classList.contains('withDropDown');
  }

  protected _emptyResult = true;
  protected _popup: DropDownComponent | undefined;
  protected _element?: ElementRef;
  protected _initialized = false;
  private _injector = inject(Injector);

  private _pickerClickedFn = this.clicked.bind(this);


  ngOnInit() {
    this.initialized.emit(this);
  }

  ngAfterViewInit() {
    this._element = this._injector.get(ElementRef);
    this._element?.nativeElement.addEventListener('click', this._pickerClickedFn, true);
  }

  ngOnDestroy() {
    this._element?.nativeElement.removeEventListener('click', this._pickerClickedFn, true);
  }

  toggle() {
    if (this.dropDownDisplayed) {
      this.hidePopup();
    } else {
      this.pickerField?.nativeElement?.classList.add('opened');
      this.openPopup();
      this.pickerField?.nativeElement.focus();
    }
  }

  collapse() {
    this.hidePopup();
  }

  dropDownVisibilityChanged(visible: boolean) {
    this.dropDownVisible$.next(visible);
    if (!visible) {
      this.pickerField?.nativeElement?.classList.remove('opened');
    }
  }

  protected showPopup() {
    this.popup?.initialized();
    this._initialized = true;
    // console.log('picker - showPopup');
    if (this._emptyResult) {
      this.pickerField?.nativeElement?.classList.remove('opened');
    } else {
      this.pickerField?.nativeElement?.classList.add('opened');
    }
  }

  protected hidePopup() {
    this.popup?.hide();
    this.pickerField?.nativeElement?.classList.remove('opened');
  }

  protected openPopup() {
    this.showPopup();
    setTimeout(() => {
      this.popup?.show();
    });
  }

  protected clicked(event: MouseEvent) {
    this.pickerClicked.emit(event);
  }
}
