import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, ViewChild } from '@angular/core';
import { NouisliderComponent } from 'ng2-nouislider';

@Component({
  selector: 'input-slider',
  styleUrls: ['./sliderComponent.component.scss'],
  template: `
    <nouislider [ngModel]="value" [min]="min" [max]="max" [step]="step" [config]="config" (update)="onChange($event)" (start)="sliderStart()" (end)="sliderEnd($event)"></nouislider>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnChanges {
  @Input() value = 0;
  @Output() valueChange = new EventEmitter<number>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() start = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() end = new EventEmitter<number>();

  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;

  @Input() config: any = {};

  @ViewChild(NouisliderComponent, {static: true}) innerSliderComponent?: NouisliderComponent;

  ngOnChanges() {
    // console.log(`slider: value=${this.value} min=${this.min} max=${this.max} step=${this.step}`);
    if (this.innerSliderComponent && this.innerSliderComponent.slider) {
      // fix bug in component ngModel change is not propagated to 'nouislider' automatically
      this.innerSliderComponent.slider.set(this.value);
    }
  }

  onChange(value: number) {
    this.valueChange.emit(value);
  }

  sliderStart() {
    this.start.emit();
  }

  sliderEnd(value: number) {
    this.end.emit(value);
  }
}

