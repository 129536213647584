import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TimerService } from '@myia/ngx-core';
import { combineLatest, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CultureService } from './services/cultureService';
import { FormatDateService } from './services/formatDateService';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {

  static updatingInterval = 15 * 1000; // 30s
  static untilInterval = 300; // 300ms - check 3 times per second when count down
  static formatDatePipeTimerKey = 'formatDatePipeTimerKey';
  static formatDatePipeUntilTimerKey = 'formatDatePipeUntilTimerKey';

  constructor(private _formatDateService: FormatDateService, private _changeDetectorRef: ChangeDetectorRef, private _cultureService: CultureService, private _timerService: TimerService) {
  }

  transform(value: Date | number | string | null | undefined, format?: string, formatParams?: string): Observable<string | null> {
    if (value == null) {
      return of(null);
    }
    return combineLatest([
      this._cultureService.currentCulture$,
      ...(format === 'fromNow' || format === 'until' ? [
        this._timerService.getTimer(format === 'fromNow' ? FormatDatePipe.formatDatePipeTimerKey : FormatDatePipe.formatDatePipeUntilTimerKey, format === 'until' ? FormatDatePipe.untilInterval : FormatDatePipe.updatingInterval)
      ] : []),
    ]).pipe(
      mergeMap(_ => {
        if (format === 'LShort') {
          return of(this._formatDateService.getShortDateTime(value as string));
        }
        if (format === 'until') {
          return this._formatDateService.getTimeFromNow(value as Date);
        }
        if (format === 'duration') {
          return this._formatDateService.getDurationFromSeconds(value as number);
        }
        if (format === 'durationWithFormat' && formatParams) {
          return this._formatDateService.getFormattedDuration(
            value as number,
            formatParams
          );
        }
        return of(this._formatDateService.getFormattedDate(value as Date, { format }));
      })
    );
  }
}
