import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { ICalendar } from './calendar.interface';

// @dynamic
export abstract class CalendarUtilsInternal {
    private static _uuid = 0;
    private static _ids: any = {};

    static newCalendarUUID(): string {
        return `_cal_${CalendarUtilsInternal._uuid++}`;
    }

    static newCalendarEntityId(prefix: CalendarIdPrefixes): string {
        if (CalendarUtilsInternal._ids[prefix] === undefined) {
            CalendarUtilsInternal._ids[prefix] = 0;
        } else {
            CalendarUtilsInternal._ids[prefix]++;
        }
        const newId = CalendarUtilsInternal._ids[prefix];
        // console.log(`CID: ${CalendarIdPrefixes[prefix]}${newId}`);
        return `${CalendarIdPrefixes[prefix]}${newId}`;
    }

    static initialize(calendar: ICalendar) {
        CalendarUtilsInternal._ids = {};
        calendar.sections.forEach(section => {
            CalendarUtilsInternal.updateId(CalendarIdPrefixes.section, section.id);
            section.blocks.forEach(block => {
                CalendarUtilsInternal.updateId(CalendarIdPrefixes.block, block.id);
                block.tracks.forEach(track => {
                    CalendarUtilsInternal.updateId(CalendarIdPrefixes.track, track.id);
                    track.events.forEach(event => {
                        CalendarUtilsInternal.updateId(CalendarIdPrefixes.event, event.id);
                    });
                });
            });
        });
    }

    private static updateId(prefix: CalendarIdPrefixes, value: string) {
        if (value && value.indexOf(CalendarIdPrefixes[prefix]) === 0) {
            if (CalendarUtilsInternal._ids[prefix] === undefined) {
                CalendarUtilsInternal._ids[prefix] = 0;
            }
            const idNumberStr = value.substr(CalendarIdPrefixes[prefix].length);
            let idNumber = parseInt(idNumberStr, 10);
            idNumber = isNaN(idNumber) ? 0 : idNumber;
            if (CalendarUtilsInternal._ids[prefix] < idNumber) {
                CalendarUtilsInternal._ids[prefix] = idNumber;
            }
        }
    }

}
