// http://qnimate.com/passphrase-based-encryption-using-web-cryptography-api/
// https://github.com/safebash/opencrypto/blob/main/src/OpenCrypto.js

import { from, mergeMap, Observable, Observer } from 'rxjs';
import { arrayBufferToBase64, arrayBufferToString, base64ToArrayBuffer, stringToArrayBuffer } from './blobHelper';

const cryptoLib = window.crypto || (window as any).msCrypto;
const cryptoApi = cryptoLib.subtle || (cryptoLib as any).webkitSubtle;

export type CryptoAlgorithm = 'AES-GCM' | 'AES-CBC';

export function encrypt(key: string, data: string, cryptoAlgorithm: CryptoAlgorithm = 'AES-GCM') {
  return getEncryptionKey(key, cryptoAlgorithm).pipe(mergeMap(cryptoKey => {
    const ivAb = getVector(cryptoAlgorithm);
    return new Observable((observer: Observer<string>) => {
      cryptoApi.encrypt(
        {
          name: cryptoAlgorithm,
          iv: ivAb,
          tagLength: 128
        },
        cryptoKey,
        stringToArrayBuffer(data)
      ).then(encryptedAb => {
        const ivB64 = arrayBufferToBase64(ivAb)
        const encryptedB64 = arrayBufferToBase64(encryptedAb)
        observer.next(ivB64 + encryptedB64)
        observer.complete();
      }).catch(err => {
        observer.error(err)
      })
    });
  }));
}


function ab2str(buf: ArrayBuffer) {
  return String.fromCharCode.apply(null, new Uint16Array(buf) as any);
}

export function decrypt(key: string, cryptoAlgorithm: CryptoAlgorithm, data: string) {
  const ivB64 = data.substring(0, cryptoAlgorithm === 'AES-GCM' ? 16 : 24);
  const encryptedB64 = data.substring(cryptoAlgorithm === 'AES-GCM' ? 16 : 24)
  const ivAb = base64ToArrayBuffer(ivB64);
  const encryptedAb = base64ToArrayBuffer(encryptedB64);
  return getEncryptionKey(key, cryptoAlgorithm).pipe(mergeMap(cryptoKey => {
    return new Observable((observer: Observer<string>) => {
      cryptoApi.decrypt(
        {
          name: cryptoAlgorithm,
          iv: ivAb,
          tagLength: 128
        },
        cryptoKey,
        encryptedAb
      ).then(decryptedDataAb => {
        observer.next(arrayBufferToString(decryptedDataAb))
        observer.complete();
      }).catch(err => {
        observer.error(err)
      })
    });
  }));
}

function getEncryptionKey(key: string | null, cryptoAlgorithm: CryptoAlgorithm) {
  if (!key) {
    throw new Error('Auth encryption failed');
  }
  return from(cryptoApi.digest({name: 'SHA-256'}, stringToArrayBuffer(key))).pipe(
    mergeMap(result => {
      return from(cryptoApi.importKey('raw', result, {name: cryptoAlgorithm}, false, ['encrypt', 'decrypt']));
    })
  );
}

function getVector(cryptoAlgorithm: CryptoAlgorithm) {
  return cryptoLib.getRandomValues(new Uint8Array(cryptoAlgorithm === 'AES-GCM' ? 12 : 16))
}
