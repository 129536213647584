import { EventEmitter, Injectable } from '@angular/core';
import { addListener, getParentScroll, linear, removeAllListeners, scrollToWithAnimation } from '@myia/ngx-core';

import dragula from 'dragula';

@Injectable({ providedIn: 'root' })
export class DragulaService {
    cancel: EventEmitter<any> = new EventEmitter();
    cloned: EventEmitter<any> = new EventEmitter();
    drag: EventEmitter<any> = new EventEmitter();
    dragend: EventEmitter<any> = new EventEmitter();
    drop: EventEmitter<any> = new EventEmitter();
    out: EventEmitter<any> = new EventEmitter();
    over: EventEmitter<any> = new EventEmitter();
    remove: EventEmitter<any> = new EventEmitter();
    shadow: EventEmitter<any> = new EventEmitter();
    dropModel: EventEmitter<any> = new EventEmitter();
    removeModel: EventEmitter<any> = new EventEmitter();

    private events: Array<string> = [
        'cancel',
        'cloned',
        'drag',
        'dragend',
        'drop',
        'out',
        'over',
        'remove',
        'shadow',
        'dropModel',
        'removeModel'
    ];

    private bags: Array<any> = [];
    private scrollCanceler: any;
    private lastScrollTop = -1;

    add(name: string, drake: any, options: any): any {
        let bag = this.find(name);
        if (bag) {
            throw new Error('Bag named: "' + name + '" already exists.');
        }
        bag = {
            name,
            drake
        };
        this.bags.push(bag);
        if (drake.models) { // models to sync with (must have same structure as containers)
            this.handleModels(name, drake, options);
        }
        if (!bag.initEvents) {
            this.setupEvents(bag);
        }
        return bag;
    }

    find(name: string): any {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.bags.length; i++) {
            if (this.bags[i].name === name) {
                return this.bags[i];
            }
        }
    }

    destroy(name: string): void {
        const bag = this.find(name);
        if (bag) {
            const i = this.bags.indexOf(bag);
            this.bags.splice(i, 1);
            bag.drake.destroy();
        }
    }

    setOptions(name: string, options: any) {
        const bag = this.add(name, dragula(options), options);
        this.handleModels(name, bag.drake, options);
    }

    addContainers(name: string, containers: Array<HTMLElement>) {
        if (containers) {
            const bagReg = this.find(name);
            if (bagReg) {
                containers.forEach(c => bagReg.drake.containers.push(c));
            }
        }
    }

    cancelDrop(name: string, revert?: boolean) {
        const bagReg = this.find(name);
        if (bagReg) {
            bagReg.drake.cancel(revert);
        }
    }

    private checkMovedItem(source: any, parentContainer: HTMLElement, parentContainerHeight: number, callback?: (animated: boolean) => void) {
        const offset = parentContainer.offsetTop;
        const sourceRect = source.getBoundingClientRect();
        const topRegion = 0.2 * parentContainerHeight;
        const bottomRegion = 0.8 * parentContainerHeight;
        const offsetFromCenter = sourceRect.top + sourceRect.height / 2 - offset;
        if ((offsetFromCenter < topRegion || offsetFromCenter > bottomRegion)) {    // e.which = 1 => click down !
            let distance = offsetFromCenter - parentContainerHeight / 2;
            distance = distance * 0.3; // <- velocity
            // mask.scrollTop(distance + mask.scrollTop());
            // animate scroll
            const targetScrollTop = distance + parentContainer.scrollTop;
            if (this.lastScrollTop !== targetScrollTop) {
                this.lastScrollTop = targetScrollTop;
                if (this.scrollCanceler) {
                    this.scrollCanceler();
                }
                // console.log('targetScrollTop:' + targetScrollTop);
                this.scrollCanceler = scrollToWithAnimation(parentContainer, targetScrollTop, 500, linear, callback);
            }
        }
    }

    private handleModels(name: string, drake: any, options: any) {
        let dragElm: any;
        let dragIndex: number;
        let dropIndex: number;
        let sourceModel: any;
        let mask: HTMLElement | undefined;
        let mirrorEl: any;
        drake.on('cancel', () => {
            if (this.scrollCanceler) {
                this.scrollCanceler();
            }
            if (mask) {
                removeAllListeners(mask, ['touchmove', 'mousemove', 'touchend']);
            }
        });
        drake.on('cloned', (mirror: any) => {
            mirrorEl = mirror;
        });
        drake.on('remove', (el: any, source: any) => {
            if (this.scrollCanceler) {
                this.scrollCanceler();
            }
            if (!drake.models) {
                return;
            }
            sourceModel = drake.models[drake.containers.indexOf(source)];
            sourceModel.splice(dragIndex, 1);
            // console.log('REMOVE');
            // console.log(sourceModel);
            this.removeModel.emit([name, el, source]);
        });
        drake.on('drag', (el: any, source: HTMLElement) => {
            dragElm = el;
            dragIndex = this.domIndexOf(el, source);
            if (options.autoScroll) {
                // you need to:
                // add a parent node as a mask of the dragula.container
                // and set its css with overflow:auto, max-height:somevalue
                mask = getParentScroll(source);
                if (mask) {
                  const h = mask.offsetHeight;
                  addListener(mask, ['touchend'], () => {
                    removeAllListeners(mask, ['touchmove']);
                  });
                  addListener(mask, ['mousemove', 'touchmove'], (e: any) => {
                    const handler = () => {
                      this.checkMovedItem(mirrorEl || el, mask as HTMLElement, h, (animated: boolean) => {
                        if (animated) {
                          handler();
                        }
                      });
                    };
                    handler();
                  });
                }
            }
        });
        drake.on('drop', (dropElm: any, target: any, source: any) => {
            if (this.scrollCanceler) {
                this.scrollCanceler();
            }
            dragElm = null;

            if (mask) {
                removeAllListeners(mask, ['touchmove', 'mousemove', 'touchend']);
            }
            if (!drake.models || !target) {
                return;
            }
            dropIndex = this.domIndexOf(dropElm, target);
            sourceModel = drake.models[drake.containers.indexOf(source)];
            // console.log('DROP');
            // console.log(sourceModel);
            if (target === source) {
                sourceModel.splice(dropIndex, 0, sourceModel.splice(dragIndex, 1)[0]);
            } else {
                const notCopy = dragElm === dropElm;
                const targetModel = drake.models[drake.containers.indexOf(target)];
                const dropElmModel = notCopy ? sourceModel[dragIndex] : JSON.parse(JSON.stringify(sourceModel[dragIndex]));

                if (notCopy) {
                    sourceModel.splice(dragIndex, 1);
                }
                targetModel.splice(dropIndex, 0, dropElmModel);
                target.removeChild(dropElm); // element must be removed for ngFor to apply correctly
            }
            this.dropModel.emit([name, dropElm, target, source]);
        });
    }

    private setupEvents(bag: any) {
        bag.initEvents = true;
        const that: any = this;
        const emitter = (type: any) => {
            function replicate() {
                const args = Array.prototype.slice.call(arguments);
                that[type].emit([bag.name].concat(args));
            }

            bag.drake.on(type, replicate);
        };
        this.events.forEach(emitter);
    }

    private domIndexOf(child: any, parent: any) {
        return Array.prototype.indexOf.call(parent.children, child);
    }


}
