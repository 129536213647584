import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cropperWidth'
})
export class PhotoPickerCropperWidthPipe implements PipeTransform {
    transform(minWidth: number | undefined, sourceWidth: number | undefined): string {
        return (sourceWidth ?? 0) < (minWidth ?? 0) ? `${minWidth}px` : 'none';
    }
}
