import { InjectionToken, Injector } from '@angular/core';
import { Observable } from 'rxjs';

export interface HttpModuleConfig {
    baseUrl: string;
    getStoredToken(injector: Injector): Observable<string | null>;
}

export let HTTP_CONFIG = new InjectionToken<HttpModuleConfig>(
  'HTTP_MODULE_CONFIG_TOKEN'
);
