import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformBackgroundTemplate'
})
export class TransformBackgroundTemplatePipe implements PipeTransform {
    transform(template: string | undefined): string | undefined {
        if (template) {
            template = template.replace(/<count-down[\s]*?\/>/gmi, '<count-down *ngIf="data.start && !data.startTimeDone" [showZero]="true" [showLabels]="true" [end]="data.start" (reached)="data.startTimeDone=true"></count-down>');
        }
        return template;
    }
}
