import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateService } from '@myia/ngx-localization';
import { IScheduleLiveStateMeetingTimer } from '../entities/scheduleLiveStateMeetingTimer.interface';

@Pipe({
    name: 'isCounterActive',
    pure: false  // update pipe even if timer instance reference is not changed
})
export class IsCounterActivePipe implements PipeTransform {
    constructor(private _formatDateService: FormatDateService) {
    }

    transform(value: IScheduleLiveStateMeetingTimer | null): boolean {
        if (value && value.start && value.end) {
            const startDate = this._formatDateService.parseDate(value.start);
            const endDate = this._formatDateService.parseDate(value.end);
            // console.log('isActive: ' + value.title + ' : ' + value.end  + ' - ' + this._formatDateService.isDateBetween(this._formatDateService.now(), startDate, endDate));
            return this._formatDateService.isDateBetween(this._formatDateService.now(), startDate, endDate);
        }
        return false;
    }

}
