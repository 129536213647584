import * as AngularCommon from '@angular/common';
import * as AngularCore from '@angular/core';
import { Injectable, NgModule, Type } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import * as LocalizationModule from '@myia/ngx-localization';

// https://github.com/nmarra/dynamic-module-loading/tree/master/main-app/src/app

// possible upgrade to systemjs v6 - https://github.com/anyOpsOS/angularSystemJsRemoteLazyLoad/

@Injectable({providedIn: 'root'})
export class DynamicModuleLoaderService {

  private loadSystemJs(systemJS: any, modulePath: string, deps?: Array<{ name: string, module: any }>, authToken?: string) {
    if (authToken) {
      systemJS.config({
        meta: {
          '*': {
            authorization: `Bearer ${authToken}`
          }
        }
      });
    }
    systemJS.set('@angular/core', systemJS.newModule(AngularCore));
    systemJS.set('@angular/common', systemJS.newModule(AngularCommon));
    systemJS.set('nxg-myia-localization', systemJS.newModule(LocalizationModule));
    if (deps) {
      deps.forEach(d => {
        systemJS.set(d.name, systemJS.newModule(d.module));
      });
    }
    return systemJS.load(modulePath);
  }

  loadModule(moduleUrl: string, moduleName: string, deps?: Array<{ name: string, module: any }>, authToken?: string): Observable<Type<NgModule>> {
    return new Observable((observer: Observer<Type<NgModule>>) => {
      // @ts-ignore
      import('systemjs').then(systemJS => systemJS.default).then((systemJS: any) => {
        systemJS._nodeRequire = undefined;
        try {
          this.loadSystemJs(systemJS, moduleUrl, deps, authToken)
            .then((pkg: any) => {
              console.log('Module loaded:' + moduleName);
              observer.next(pkg[moduleName]);
              observer.complete();
            })
            .catch((e: any) => {
              observer.error(e);
              observer.complete();
            });
        } catch (e: any) {
          observer.error(e);
          observer.complete();
        }
      });
    });
  }
}
