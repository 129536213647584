import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { IndividualConfigExtended } from './individualConfigExtended.interface';
import { IToastButton } from './toastShowOptions';

@Component({
  selector: '[svg-toast-component]',
  styleUrls: ['./svgToastComponent.component.scss'],
  template: `
    <svg-icon [prefix]="extendedOptions.svgIconPrefix" [name]="extendedOptions.svgIcon ?? ''" className="toastIcon"></svg-icon>
    <button *ngIf="options.closeButton" (click)="remove()" class="toast-close-button" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <div *ngIf="extendedOptions.buttons && extendedOptions.buttons.length" class="toastButtons">
      <button *ngFor="let btn of extendedOptions.buttons" (click)="toastClicked($event, btn)">{{btn.title}}</button>
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgToastComponent extends Toast {
  get extendedOptions(): IndividualConfigExtended {
    return this.options as IndividualConfigExtended;
  }

  // constructor is only necessary when not using AoT
  constructor(toastrService: ToastrService, toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  @HostListener('click', ['$event'])
  tapToastWithClick(event: MouseEvent) {
    event.stopPropagation();
    super.tapToast();
  }

  toastClicked($event: MouseEvent, btn: IToastButton) {
    btn.clickHandler(this);
  }
}
