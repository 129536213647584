<ng-container>
  <div #field class="pickerButton" [ngClass]="{disabled: disabled || selectedItem$?.isLoading}" (click)="toggle()" *ngLet="(selectedItem$ | async) as selectedItem$"><span
    *ngIf="selectedItem$?.value != undefined">{{selectedItem$?.value?.[dataTitle]}}</span><span
    *ngIf="selectedItemKey$.value == null && !selectedItem$?.isLoading">&nbsp;</span>
    <svg-icon *ngIf="!selectedItem$?.isLoading" name="drop-down"></svg-icon>
    <progress-indicator-bar *ngIf="selectedItem$?.isLoading" indicatorClass="pickerValuesLoader"></progress-indicator-bar>
  </div>
  <drop-down #popup [for]="field" [fullHeight]="false" className="staticDataDropDown"
             (visibilityChanged)="dropDownVisibilityChanged($event)">
    <ng-container *ngIf="dropDownVisible$ | async">
      <ng-container *ngLet="allItem$ | async as items">
        <div *ngIf="items.isLoading" class="itemsLoadingBlock">
          <div class="loadingText">{{'Loading' | trans}}</div>
          <progress-indicator-bar indicatorClass="pickerValuesLoader"></progress-indicator-bar>
        </div>
        <div class="dropDownContent" *ngIf="!items.isLoading">
          <div class="staticDataItem" *ngFor="let item of items?.value"
               [ngClass]="{selected: selectedItemKey$.value === item[dataKey]}"
               (click)="selectItem(item)">{{item[dataTitle]}}</div>
        </div>
      </ng-container>
    </ng-container>
  </drop-down>
</ng-container>
