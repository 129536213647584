import { InjectionToken } from '@angular/core';
import {
  ActivatedRoute, IsActiveMatchOptions, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Route,
  RouteConfigLoadEnd, RouteConfigLoadStart, Router, Routes, ROUTES, UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const dataRouteLevelKey = 'dataRouteLevel';

function levelRoutes(routes: Routes, level: string): Route[] {
  return routes.map(r => {
    if (r.children) {
      r.children = levelRoutes(r.children, level);
    }
    return r.loadChildren ? levelRoute(r, level) : r;
  });
}

function levelRoute(routeDef: Route, level: string): Route {
  return {...routeDef, data: {...routeDef.data, [dataRouteLevelKey]: level}};
}

export function getRouteByLevel(route: ActivatedRoute, level: string): ActivatedRoute | undefined {
  if (route.snapshot?.data[dataRouteLevelKey] === level) {
    return route;
  }
  if (route.children?.length) {
    let childFound = route.children.find(child => child.snapshot?.data[dataRouteLevelKey] === level);
    if (!childFound) {
      route.children.forEach(c => {
        childFound = getRouteByLevel(c, level);
      });
    }
    return childFound;
  }
  return undefined;
}

export function getRouteByComponent(route: ActivatedRoute, componentType: any): ActivatedRoute | undefined {
  if (route.component === componentType) {
    return route;
  }
  if (route.children?.length) {
    let childFound = route.children.find(child => child.component === componentType);
    if (!childFound) {
      route.children.forEach(c => {
        childFound = getRouteByComponent(c, componentType);
      });
    }
    return childFound;
  }
  return undefined;
}

export function lazyModuleLoadingChanged(router: Router, level: string): Observable<boolean> {
  let moduleLoadCount = 0;
  const loadedRoutes: Array<string> = [];
  return router.events.pipe(map((event) => {
      if (event instanceof RouteConfigLoadStart) {
        if (event.route.data && event.route.data[dataRouteLevelKey] === level) {
          moduleLoadCount++;
        }
      } else if (event instanceof RouteConfigLoadEnd) {
        if (event.route.data && event.route.data[dataRouteLevelKey] === level) {
          if (event.route.path && loadedRoutes.indexOf(event.route.path) === -1) {
            loadedRoutes.push(event.route.path);
          } else {
            moduleLoadCount--;
          }
        }
      }
      if (event instanceof NavigationStart) {
        moduleLoadCount = 0;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        moduleLoadCount--;
      }
      return moduleLoadCount > 0;
    }),
    distinctUntilChanged()
  );
}

export const ROUTES_LEVEL = new InjectionToken<any>('ROUTES_LEVEL');

export function provideModuleRoutes(routesLevelDef: any) {
  return levelRoutes(routesLevelDef.routes, routesLevelDef.level);
}

export function provideLazyRoutes(providedRoutes: Routes, level: string): any {
  return [
    // store routes and level to the ROUTES_LEVEL injection token to be able to pass it to the provideModuleRoutes factory
    {
      provide: ROUTES_LEVEL,
      useValue: {
        level,
        routes: providedRoutes
      }
    },
    {
      provide: ROUTES,
      multi: true,
      useFactory: provideModuleRoutes,
      deps: [ROUTES_LEVEL]
    },
  ];
}

export function isUrlTree(url: string | UrlTree): url is UrlTree {
  return (url as UrlTree).fragment !== undefined;
}

export function isRouteActive(router: Router, url: string | UrlTree, exact: boolean): boolean {
  const loginTree = isUrlTree(url) ? url : router.createUrlTree([url]);
  const matchOptions: IsActiveMatchOptions = exact ?
    {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}
    :
    {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'};
  return router.isActive(loginTree, matchOptions);
}
