import { InjectionToken } from '@angular/core';
import { Level } from './logger';

export interface ICoreModuleConfig {
    clientConfigKey?: string;
    logLevel?: Level;
}

export const CORE_CONFIGURATION = new InjectionToken<ICoreModuleConfig>(
    'CORE_CONFIGURATION'
);
