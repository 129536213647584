import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, HostBinding, Input, OnChanges,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILoadingState, NgChangesWithInputs } from '@myia/ngx-core';
import { BehaviorSubject, distinctUntilChanged, filter, mergeMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { PickerComponent } from './picker.component';

@Component({
  selector: 'static-data-picker',
  templateUrl: './staticDataPicker.component.html',
  styleUrls: ['./staticDataPicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StaticDataPickerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticDataPickerComponent<TData, TDataKey extends keyof TData = keyof TData, TDataTitle extends keyof TData = keyof TData> extends PickerComponent<TData> implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() dataKey!: TDataKey;
  @Input() dataTitle!: TDataTitle;
  @Input() selectedItemKey?: TData[TDataKey];
  @Input() item$!: Observable<ILoadingState<ReadonlyArray<TData>>>;
  @Input() getItem!: (key: TData[TDataKey]) => Observable<ILoadingState<TData> | null>;

  @HostBinding('class') hostClasses = 'staticDataPicker formControl';

  @ViewChild('field') field?: ElementRef;

  touched = false;
  disabled = false;

  allItem$?: Observable<ILoadingState<ReadonlyArray<TData>> | null>;
  selectedItem$: Observable<ILoadingState<TData> | null> = of(null);
  selectedItemKey$ = new BehaviorSubject<TData[TDataKey] | null>(null);

  private _onChange?: (item: TData[TDataKey] | null) => void;
  private _onTouched?: () => void;
  private _loadedItem$ = new BehaviorSubject<ReadonlyArray<TData> | null>(null);
  private _destroyed$ = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.selectedItem$ = this.selectedItemKey$.pipe(
      distinctUntilChanged(),
      mergeMap(selectedItemKey => {
        if (selectedItemKey) {
          return this._loadedItem$.pipe(
            mergeMap(items => {
              const item = items?.find(i => i[this.dataKey] === selectedItemKey);
              return item ? of({
                isLoading: false,
                value: item
              } as ILoadingState<TData>) : this.getItem(selectedItemKey).pipe(
                takeUntil(this._loadedItem$.pipe(
                  filter(i => !!i)
                ))
              );
            }),
          );
        } else {
          return of(null);
        }
      })
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngOnChanges(changes: NgChangesWithInputs<StaticDataPickerComponent<TData>>) {
    if (changes.selectedItemKey) {
      this.selectedItemKey$.next(changes.selectedItemKey.currentValue as TData[TDataKey]);
    }
    if (changes.item$) {
      if (changes.item$.currentValue) {
        this.allItem$ = changes.item$.currentValue.pipe(
          takeUntil(this._destroyed$),
          tap(itemsData => {
            if (!itemsData.isLoading) {
              this._loadedItem$.next(itemsData.value ?? null);
            }
          }),
          shareReplay(1),
        );
      } else {
        this._loadedItem$.next(null);
      }
    }
  }

  override toggle() {
    if (!this.disabled) {
      super.toggle();
    }
  }

  selectItem(item: TData | null) {
    this.markAsTouched();
    if (!this.disabled) {
      const selectedItemKey = item?.[this.dataKey] ?? null;
      this.emitChangeEvent(selectedItemKey);
      this.itemSelected.emit(item);
      this.selectedItemKey$.next(selectedItemKey);
    }
    this.hidePopup();
  }

  registerOnChange(onChange: (value: TData[TDataKey] | null) => void) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this._cdr.markForCheck();
  }

  writeValue(value: TData[TDataKey]): void {
    this.selectedItemKey$.next(value);
  }

  markAsTouched() {
    if (!this.touched) {
      if (this._onTouched) {
        this._onTouched();
      }
      this.touched = true;
    }
  }

  private emitChangeEvent(selectedItemKey: TData[TDataKey] | null) {
    // console.log(`emitChangeEvent: ${selectedItemKey}`);
    this._onChange?.(selectedItemKey);
  }
}
