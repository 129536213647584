export function splitToChunks(arr: Array<any>, n: number, transformChunk?: (chunk: Array<any>) => Array<any>) {
  const r = Array(Math.ceil(arr.length / n)).fill(null);
  return r.map((e, i) => {
    const slice = arr.slice(i * n, i * n + n);
    if (transformChunk) {
      return transformChunk(slice);
    }
    return slice;
  });
}

export function zipArrays(...a: any[]) {
  const merged = [];
  let cont;
  let index = 0;
  do {
    cont = false;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < a.length; i++) {
      const args = a[i];
      if (index < args.length) {
        cont = true;
        merged.push(args[index]);
      }
    }
    index++;
  } while (cont);
  return merged;
}

export function findLast<T>(array: ReadonlyArray<T>, predicate: (item: T) => boolean) {
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (predicate(x)) {
      return x;
    }
  }
  return null;
}

export function sortBy<T>(array: ReadonlyArray<T> | Array<T>, predicate: (item: T) => any, sortSourceArray?: boolean): ReadonlyArray<T> | Array<T> {
  return (sortSourceArray ? (array as Array<T>) : [...array]).sort((a, b) => {
    const valueA = predicate(a);
    const valueB = predicate(b);
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}

function reduceBy(reducer: (a: any, b: any) => boolean, acc?: any) {
  return (arr: ReadonlyArray<any> | Array<any>, propertySelector: (obj: any) => any) => {
    return arr[arr.reduce((arrAcc, v, i) => {
      const b = propertySelector(v);
      return reducer(arrAcc[0], b) ? [b, i] : arrAcc;
    }, acc || [arr.length ? propertySelector(arr[0]) : undefined, 0])[1]];
  };
}

export const maxBy = reduceBy((a, b) => {
  return a < b;
});

export const minBy = reduceBy((a, b) => {
  return a > b;
});

export const groupBy = <T extends {}>(arr: ReadonlyArray<T> | Array<T>, propertySelector: (obj: T) => any, withKeys: boolean): Array<[any, Array<T>]> | Array<Array<T>> => {
  const groups = arr.reduce((rv: any, x) => {
    const key = propertySelector(x);
    (rv[key] = rv[key] || []).push(x);
    return rv;
  }, {} as T);
  return withKeys ? Object.entries(groups) as Array<[any, Array<T>]> : Object.values(groups) as Array<Array<T>>;
};

export const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index?: number, array?: T[]) => Q) =>
  array.reduce((map, value, index, arr) => {
    const key = predicate(value, index, arr);
    const grp = map.get(key);
    if (grp) {
      grp.push(value);
    } else {
      map.set(key, [value]);
    }
    return map;
  }, new Map<Q, T[]>());

export const flattenArrays = (arr: ReadonlyArray<any> | Array<any>) => {
  return arr.reduce((rv: Array<any>, array: Array<any>) => {
    rv.push(...array);
    return rv;
  }, []);
};

