import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { HTTP_CONFIG, HttpModuleConfig } from './httpModuleConfig';
import { map, Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class JWTService {
  // temporary solution how to skip authorization header
  // see https://github.com/angular/angular/issues/18155
  private _noAuthUrls: Array<string> = [];

  constructor(@Inject(HTTP_CONFIG) private _httpConfig: HttpModuleConfig, private _injector: Injector) {
  }

  applyJWTHeaders(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (this._noAuthUrls.indexOf(req.url) === -1) {
      return this.getAccessToken().pipe(map(token => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: token
            }
          });
        }
        return req;
      }));
    }
    return of(req);
  }

  getAccessToken(): Observable<string | null> {
    return this._httpConfig.getStoredToken(this._injector).pipe(
      map(storedToken => {
        if (storedToken) {
          storedToken = `bearer ${storedToken}`;
        }
        return storedToken;
      })
    );
  }

  skipAuthorization(url: string) {
    if (this._noAuthUrls.indexOf(url) === -1) {
      this._noAuthUrls.push(url);
    }
  }
}
