import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateService } from '@myia/ngx-localization';
import { IScheduleLiveStateMeetingTimer } from '../entities/scheduleLiveStateMeetingTimer.interface';

@Pipe({
  name: 'CountersCompleted'
})
export class CountersCompletedPipe implements PipeTransform {
  constructor(private _formatDateService: FormatDateService) {
  }

  static isCompleted(timers: ReadonlyArray<IScheduleLiveStateMeetingTimer> | undefined, formatDateService: FormatDateService): boolean {
    if (timers && timers.length > 0) {
      const now = formatDateService.now();
      for (const timer of timers) {
        if (!timer.end) {
          return false;
        }
        const endDate = formatDateService.parseDate(timer.end);
        if (!formatDateService.isDateAfter(now, endDate)) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  transform(timers: ReadonlyArray<IScheduleLiveStateMeetingTimer> | undefined): boolean {
    return CountersCompletedPipe.isCompleted(timers, this._formatDateService);
  }

}
