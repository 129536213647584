import { readOnlyEntity } from '@myia/ngx-core';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { ICalendarSectionBlock } from './calendarSectionBlock.interface';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function CalendarSectionBlock(props: ICalendarSectionBlock): ICalendarSectionBlock {
    return readOnlyEntity({
      // @ts-ignore: TS2783
        id: props.id ? null : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.block),
      // @ts-ignore: TS2783
        from: null,
      // @ts-ignore: TS2783
        to: null,
      // @ts-ignore: TS2783
        tracks: null,
        ...props
    });
}

