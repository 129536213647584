import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'progress-indicator-circle',
    styleUrls: ['./progressIndicatorCircle.component.scss'],
    template: `
            <div class="circleLoader {{indicatorClass}}">
                <div class="inner"></div>
                <div class="innerSmall"></div>
            </div>
            `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressIndicatorCircleComponent {
    @Input() indicatorClass: string | undefined;

    @HostBinding('class') hostClasses = 'progressIndicatorCircle';
}

