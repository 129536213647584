import { Renderer2 } from '@angular/core';

export function setElementClass(renderer: Renderer2, element: any, className: string, add: boolean) {
  if (add) {
    renderer.addClass(element, className);
  } else {
    renderer.removeClass(element, className);
  }
}

