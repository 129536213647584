import { Directive, Output, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { isDescendant } from '@myia/ngx-core';

@Directive({
  selector: '[mousePan]'
})
export class MousePanDirective {
  @Output() mousePan = new EventEmitter<{ x: number, y: number }>();

  private _active = false;
  private _lastX = 0;
  private _lastY = 0;

  constructor(private _elementRef: ElementRef) {
  }

  @HostListener('document:mousedown', ['$event']) onMouseDown(event: any) {
    if (this._elementRef.nativeElement === event.target || isDescendant(this._elementRef.nativeElement, event.target)) {
      this._active = true;
      this._lastX = event.clientX;
      this._lastY = event.clientY;
      event.stopPropagation();
      event.preventDefault();
    }
  }

  @HostListener('document:mouseup') onMouseUp() {
    this._active = false;
  }

  @HostListener('document:mousemove', ['$event']) onMouseMove(event: any) {
    if (this._active) {
      this.mousePan.emit({
        x: event.clientX - this._lastX,
        y: event.clientY - this._lastY,
      });
      this._lastX = event.clientX;
      this._lastY = event.clientY;
    }
  }
}
