import produce, { Draft, setAutoFreeze, createDraft, current } from 'immer';
import { deepRemoveProperty } from './utils/dataHelper';

const immutablePropertyFlag = '_immutable';

export function setReadOnlyEntityProdMode() {
  setAutoFreeze(false); // improve performance
}

export function readOnlyEntity<T>(props: T): Readonly<T> {
  let result;
  if (Array.isArray(props)) {
    result = produce<Readonly<T>>([] as any, e => {
      // no initial changes
      (e as any).push(...props);
    });
  } else {
    result = produce<Readonly<T>>({} as any, e => {
      // no initial changes
      Object.assign(e, props);
      (e as any)[immutablePropertyFlag] = true;
    });
  }
  return result;
}

export function updateReadOnlyEntity<T>(entity: T, update: (draft: Draft<Readonly<T>>) => void): Readonly<T> {
  return produce<Readonly<T>>(entity, update);
}

export function toPlainObject<T>(entity: Readonly<T>): T {
  return current(entity);
}

export function getMutableReadOnlyEntity<T>(entity: Readonly<T>): Mutable<T> {
  const result = createDraft(entity) as T;
  deepRemoveProperty(result, immutablePropertyFlag);
  return result;
}

export function compareObjects(obj1: any, obj2: any, partial = false, ignoredProps?: Array<keyof typeof obj1 | keyof typeof obj2>) {
  if (obj1 === obj2) {
    return true;
  }
  if (!obj1 || !obj2) {
    return false;
  }
  // Loop through properties in object 1
  for (const p in obj1) {
    if (p === immutablePropertyFlag || ignoredProps?.includes(p)) {
      continue;
    }
    // Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
      return false;
    }

    switch (typeof (obj1[p])) {
      // Deep compare objects
      case 'object':
        if (!compareObjects(obj1[p], obj2[p])) {
          return false;
        }
        break;
      // Compare function code
      case 'function':
        if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
          return false;
        }
        break;
      // Compare values
      default:
        if (obj1[p] !== obj2[p]) {
          return false;
        }
    }
  }

  if (!partial) {
    // Check object 2 for any extra properties
    for (const p in obj2) {
      if (p === immutablePropertyFlag || ignoredProps?.includes(p)) {
        continue;
      }
      if (obj1[p] === obj2[p]) {
        continue;
      }
      if (typeof (obj1[p]) === 'undefined') {
        return false;
      }
    }
  }
  return true;
}

export type Mutable<BaseType, Keys extends keyof BaseType = keyof BaseType> =  {-readonly [KeyType in keyof Pick<BaseType, Keys>]: Pick<BaseType, Keys>[KeyType]};
