import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Optional, Output, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Coords } from '@floating-ui/core/src/types';
import { computePosition, flip, shift, autoUpdate, hide, Placement, offset, arrow, Side, Alignment } from '@floating-ui/dom';
import { closestParent, isDescendant } from '@myia/ngx-core';
import { RENDERER_TOKEN } from '@myia/ngx-core-ui';

@Component({
  selector: 'drop-down',
  template: `
    <ng-template #contentRef>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./dropDown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownComponent implements OnDestroy {

  @Input() for?: HTMLElement | ElementRef;
  @Input() defaultPlacement: Alignment | 'center' = 'start';
  @Input() defaultSide: Side = 'bottom';
  @Input() hideOnClickOutside = true;

  @Input() fullHeight = true;
  @Input() className = '';
  @Input() popupOffset = { x: 0, y: 0 };
  @Input() showArrow = false;

  @Input() openingMode: 'auto' | 'manual' = 'auto';

  @Output() dropDownSizeChanged = new EventEmitter<number>();
  @Output() visibilityChanged = new EventEmitter<boolean>();

  @ViewChild('contentRef') contentRef!: TemplateRef<any>;

  empty = true;

  dropDownContentEl?: HTMLElement;

  private get forElement(): HTMLElement | undefined {
    return this.for instanceof ElementRef ? this.for.nativeElement : this.for as HTMLElement;
  }

  private _watchedEl?: HTMLElement;
  private _changes?: MutationObserver;
  private _visible = false;
  private _initialized = false;
  private _autoUpdateSub?: () => void;
  private _placementClassName?: string;
  private _globalEventListeners: Array<() => void> = [];
  private _arrowElement?: HTMLElement;

  constructor(private _viewContainerRef: ViewContainerRef, private _renderer: Renderer2, @Optional() @Inject(RENDERER_TOKEN) private _rendererToken: Renderer2) {
  }

  ngOnDestroy() {
    this.destroyFloating();
  }

  initialized() {
    this._initialized = true;
    this.dropDownContentEl?.classList.add('initialized');
    this.update();
  }

  update() {
    // console.log('dropdown update');
    const dropDownContentEl = this.dropDownContentEl;
    if (!dropDownContentEl || !this.forElement) {
      return Promise.resolve();
    }

    // set min width
    dropDownContentEl.style.minWidth = `${this.forElement.offsetWidth}px`;

    // update content height
    const referenceBounds = this.forElement.getBoundingClientRect();
    const maxHeight = Math.max(referenceBounds.y, window.innerHeight - referenceBounds.bottom);
    this.dropDownSizeChanged.emit(maxHeight - 20); // margin 20px
    // compute position
    return computePosition(
      this.forElement,
      dropDownContentEl,
      {
        placement: (`${this.defaultSide}${this.defaultPlacement !== 'center' ? '-' + this.defaultPlacement : ''}`) as Placement,
        middleware: [
          ...(this._arrowElement ? [arrow({ element: this._arrowElement })] : []),
          hide(),
          flip(),
          shift({ padding: 5 }),
          offset({ crossAxis: this.popupOffset.x, mainAxis: this.popupOffset.y }),
        ],
      }
    ).then(({ x, y, placement, middlewareData }) => {

      // add placement class to dropdown content
      const placementClassName = `placement-${placement}`;
      if (this._placementClassName !== placementClassName) {
        if (this._placementClassName) {
          dropDownContentEl.classList.remove(this._placementClassName);
        }
        this._placementClassName = placementClassName;
        dropDownContentEl.classList.add(this._placementClassName);
      }

      dropDownContentEl.setAttribute('data-dropdown-placement', placement);

      Object.assign(dropDownContentEl.style, {
        left: `${x}px`,
        top: `${y}px`
      });

      if (this._arrowElement && middlewareData.arrow) {
        // arrow
        const { x: arrowX, y: arrowY } = middlewareData.arrow as Partial<Coords>;
        const { x: shiftX, y: shiftY } = middlewareData.shift as Partial<Coords>;

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]] as string;

        Object.assign(this._arrowElement.style, {
          left: arrowX != null ? `${arrowX - (shiftX ?? 0)}px` : '',
          top: arrowY != null ? `${arrowY - (shiftY ?? 0)}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });
      }

      if (middlewareData.hide?.referenceHidden) {
        this.hide();
      }
    });
  }

  show() {
    if (!this._visible) {
      this.createFloating();
      this._visible = true;
      this.forElement?.classList.add('opened');
      if (this.dropDownContentEl) {
        this.dropDownContentEl.style.display = 'block';
        this.dropDownContentEl.className = `dropDown opening${this.className ? ' ' + this.className : ''}${this.fullHeight ? ' fullHeight' : ''}${this._initialized ? ' initialized' : ''}`.trim();
        this.update().then(() => {
          this.forElement?.classList.add('withDropDown');
          this.visibilityChanged.emit(true);
          if (this.openingMode === 'auto') {
            this.showOpenedDropDown();
          }
        });
      }
    }
  }

  hide() {
    if (this._visible && this.dropDownContentEl) {
      this._visible = false;
      this.forElement?.classList.remove('opened');
      this.dropDownContentEl.style.display = 'none';
      this.forElement?.classList.remove('withDropDown');
      this.visibilityChanged.emit(false);
      this.destroyFloating();
    }
  }

  toggle() {
    if (this._visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  showOpenedDropDown() {
    this.dropDownContentEl?.classList.remove('opening');
  }

  private domChange(_: MutationRecord) {
    this.checkDropDownContent();
  }

  private checkDropDownContent() {
    this.empty = !this._watchedEl?.children.length;
    if (this.empty) {
      // console.log('Dropdown is empty => hide popup');
      this.hide();
    } else {
      // console.log('Dropdown is not empty.');
    }
  }

  private createFloating() {
    // Render the TemplateRef as a SIBLING to THIS component.
    const embeddedViewRef = this._viewContainerRef.createEmbeddedView(this.contentRef);
    // NOTE: I don't if this call is actually needed. It doesn't seem to make a
    // difference in this particular demo; however, it is called in the Angular
    // Material code, so I assume it is important (in at least some cases).
    embeddedViewRef.detectChanges();
    this.dropDownContentEl = (this._rendererToken ?? this._renderer).createElement('div') as HTMLElement;
    document.body.appendChild(this.dropDownContentEl);
    // At this point, the embedded-view DOM (Document Object Model) branch has been
    // wired-together, complete with view-model bindings. We can now move the DOM
    // nodes - which, in this case, is made up of the NgContent-projected nodes -
    // into the BODY without breaking the template bindings.
    for (const node of embeddedViewRef.rootNodes) {
      this.dropDownContentEl.appendChild(node);
    }

    const dropDownContent = this.dropDownContentEl;
    if (dropDownContent && this._watchedEl !== dropDownContent) {
      dropDownContent.style.display = 'none';
      this._changes?.disconnect();
      this._watchedEl = dropDownContent;
      this._changes = new MutationObserver((mutations: MutationRecord[]) => {
          mutations.forEach((mutation: MutationRecord) => this.domChange(mutation));
        }
      );
      this._changes.observe(dropDownContent, {
        childList: true
      });

      if (this.forElement) {
        this._autoUpdateSub = autoUpdate(this.forElement, dropDownContent, this.update.bind(this, true));
      }
      this.checkDropDownContent();
    }

    if (this.showArrow) {
      this._arrowElement = document.createElement('div');
      this._arrowElement.className = 'arrow';
      this.dropDownContentEl.appendChild(this._arrowElement);
    }

    this._globalEventListeners.push(this._renderer.listen('document', 'touchend', this.hideOnClickOutsideHandler.bind(this)));
    this._globalEventListeners.push(this._renderer.listen('document', 'click', this.hideOnClickOutsideHandler.bind(this)));

  }

  private destroyFloating() {
    this._changes?.disconnect();
    if (this.dropDownContentEl) {
      this.dropDownContentEl.parentElement?.removeChild(this.dropDownContentEl);
    }
    if (this._autoUpdateSub) {
      this._autoUpdateSub();
    }
    this._clearGlobalEventListeners();
  }

  private _clearGlobalEventListeners() {
    this._globalEventListeners.forEach(evt => {
      if (evt && typeof evt === 'function') {
        evt();
      }
    });
    this._globalEventListeners.length = 0;
  }

  private hideOnClickOutsideHandler($event: MouseEvent): void {
    // The '.dropDownContent' selector prevents closing parent dropdown when clicked on nested dropdown
    if (!this.hideOnClickOutside || isDescendant(this.dropDownContentEl, $event.target) || this.forElement === $event.target || isDescendant(this.forElement, $event.target) || closestParent($event.target, '.dropDownContent')) {
      return;
    }
    this.hide();
  }

}
