import { AbstractControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs';

type ExpandRecursively<T> = T extends object
  ? T extends infer O ? { [K in keyof O]: ExpandRecursively<O[K]> } : never
  : T;

export function watchFormChanges(formGroup: FormGroup, options?: { valueConverter?: (value: any) => any }) {
  const initialValue = formGroup.value;
  return formGroup.valueChanges.pipe(map(value => {
    return !areFormValuesEqual(initialValue, value, options);
  }));
}

export function validateFormGroup(formGroup: FormGroup) {
  for (const prop in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(prop)) {
      const item = formGroup.controls[prop];
      if (item instanceof FormGroup) {
        validateFormGroup(item);
      } else {
        // mask as 'touched' to show validation errors
        item.markAsTouched();
      }
    }
  }
  formGroup.updateValueAndValidity();
}

export function clearFormGroup(formGroup: FormGroup) {
  for (const prop in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(prop)) {
      const item = formGroup.controls[prop];
      if (item instanceof FormGroup) {
        clearFormGroup(item);
      } else {
        // clear 'touched' property to hide validation errors
        item.markAsUntouched();
        item.markAsPristine();
      }
    }
  }
  formGroup.markAsUntouched();
  formGroup.markAsPristine();
}

export function getGroupControlValue(
  formGroup: FormGroup,
  controlName: string
): any {
  return formGroup.controls.hasOwnProperty(controlName) &&
  formGroup.controls[controlName]
    ? formGroup.controls[controlName].value
    : null;
}

export function getFormControlErrors(control: AbstractControl): Array<any> {
  if (control.touched && control.errors) {
    return Object.keys(control.errors);
  }
  return [];
}

function areFormValuesEqual(prevValue: any, currentValue: any, options: { valueConverter?: (value: any) => any } | undefined) {
  const normalizedPrevValue = removeEmpty(prevValue);
  const normalizedCurrentValue = removeEmpty(currentValue);
  const normalizedPrevValueKeys = Object.keys(normalizedPrevValue);
  const normalizedCurrentValueKeys = Object.keys(normalizedCurrentValue);
  return normalizedPrevValueKeys.length === normalizedCurrentValueKeys.length && normalizedPrevValueKeys.every(key => {
    if (Array.isArray(normalizedPrevValue[key])) {
      return normalizedPrevValue[key].length === (normalizedCurrentValue[key]?.length ?? 0) && normalizedPrevValue[key].every((v: any, index: number) => areFormValuesEqual(v, normalizedCurrentValue[key][index], options));
    }
    return options?.valueConverter ? (options.valueConverter(currentValue[key]) === options.valueConverter(prevValue[key])) : normalizedCurrentValue[key] === normalizedPrevValue[key];
  });
}

function removeEmpty<T extends {}>(obj: T): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v as T) : v])
  ) as Partial<T>;
}
