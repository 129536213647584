import { Pipe, PipeTransform } from '@angular/core';
import { ICalendarSectionBlockTrack } from '../entities/calendarSectionBlockTrack.interface';

@Pipe({
    name: 'anySectionTrackHasTitle',
})
export class CalendarEventSectionTrackHasTitlePipe implements PipeTransform {
    transform(tracks: Array<ICalendarSectionBlockTrack>): any {
        if (!tracks) {
            return false;
        }
        return tracks.find(t => !!t.title);
    }
}
