import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable } from 'rxjs';

@Pipe({
    name: 'falsy',
})
export class FalsyPipe implements PipeTransform {
    transform(value: Observable<any>): Observable<boolean> {
        return value.pipe(
            map(v => !v)
        );
    }
}
