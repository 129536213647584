import { InjectionToken, Provider } from '@angular/core';

import { ILocalizationConfig } from './ILocalizationConfig.interface';

export interface ILocalizationModuleConfig {
    config?: Provider;
}

export const LOCALIZATION_CONFIGURATION = new InjectionToken<
    ILocalizationConfig
>('LOCALIZATION_CONFIGURATION');
