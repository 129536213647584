import { readOnlyEntity } from '@myia/ngx-core';
import { ICalendarEventOne2One } from './calendarEventOne2One.interface';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { CalendarUtilsInternal } from './calendarUtilsInternal';


export function CalendarEventOne2One(props: ICalendarEventOne2One): ICalendarEventOne2One {
  return readOnlyEntity({
    // @ts-ignore: TS2783
        id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.event),
    // @ts-ignore: TS2783
        type: undefined,
    // @ts-ignore: TS2783
        start: undefined,
        end: undefined,
        title: undefined,
        color: undefined,
        icon: undefined,
        template: undefined,
        meta: undefined,
        //
    // @ts-ignore: TS2783
        one2OneSlotId: undefined,
    // @ts-ignore: TS2783
        planned: false,
        plannedColor: undefined,
        plannedTitle: undefined,
        meetingLocation: undefined,
        meetingDesc: undefined,
        profileId: undefined,
        //
        updating: false,
        isStopped: false,
        ...props
    });
}

