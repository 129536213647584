import { Observable } from 'rxjs';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JWTService } from './JWTService';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  constructor(private _jwtService: JWTService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._jwtService.applyJWTHeaders(req).pipe(mergeMap((reqWithJWT) => {
      return next.handle(reqWithJWT);
    }));
  }
}
