import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges,
  OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CultureService, FormatDateService } from '@myia/ngx-localization';
import { OnlineMeetingProvider } from '../entities/onlineMeetingProvider';
import { IScheduleLiveStateMeetingTimer } from '../entities/scheduleLiveStateMeetingTimer.interface';
import { JitsiInstance } from '../jitsiInstance';
import { CountersCompletedPipe } from './countersCompletedPipe';
import { JitsiAPIService } from '../services/jitsiAPIService';
import { fadeAnimation } from './fadeAnimation';

@Component({
  selector: 'jitsi-meet',
  animations: [
    trigger('fade', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: {
            opacityTo: 1,
            time: '1s'
          }
        })
      ]),
    ])
  ],
  template: `
    <div class="topBlock" [ngClass]="{waiting: !room?.id && !(timers | CountersCompleted)}" *ngIf="!hideTopBlock">
      <div class="title" *ngIf="!helpDeskRoomActive">
        <div [innerHTML]="(title || (defaultTitle | trans)) | sanitizeHtml"></div>
        <div class="subTitle" *ngIf="subTitle">{{subTitle}}</div>
      </div>
      <div class="title" *ngIf="helpDeskRoomActive">{{'Live.Help_Desk_Room' | trans}}</div>
      <div class="timers" *ngIf="!isLoading">
        <div class="timer" *ngFor="let timer of timers; last as isLast; trackBy: trackTimer" [ngClass]="{noLabel: !timer.title}">
          <div *ngIf="timer | isCounterActive">
            <div *ngIf="timer.title" class="timerTitle">{{timer.title}}</div>
            <count-down *ngIf="timer.end" [end]="timer.end" [warningDuration]="isLast? 60 : 0" [noFinalFade]="true" [showZero]="true" [showLabels]="false" (reached)="meetingTimerReached(timer)"></count-down>
          </div>
        </div>
      </div>
      <div class="meetingCompleted" *ngIf="timers | CountersCompleted" [@fade]>
        <svg-icon name="check"></svg-icon>
        <span>{{'Live.Live_Meeting_Completed'|trans}}</span>
      </div>
    </div>
    <div #jitsiContainer *ngIf="room?.id && !(timers | CountersCompleted) && userDisconnectedRoomId !== room?.id && (userConnectedRoomId === room?.id || autoConnect)" class="jitsiContainer" [ngClass]="{loading: isLoading}">
      <div class="jitsiLoadingBlock" *ngIf="isLoading">
        <progress-indicator-circle></progress-indicator-circle>
        <div class="msg">{{(helpDeskRoomActive ? 'Live.Help_Desk_Connecting' : 'Live.Live_Meeting_Connecting')|trans}}</div>
      </div>
      <div class="jitsiIframe"></div>
    </div>
    <!--        <iframe *ngIf="meetingUrl && !(timers | CountersCompleted)" allow="microphone; camera" [src]="meetingUrl" (load)="frameLoaded()"></iframe>-->
    <div class="noMeetingNow" *ngIf="!room?.id && !(timers | CountersCompleted)">
      <div class="infoTxt" [innerHTML]="'Live.Live_Other_Meetings'|trans"></div>
    </div>
    <div class="disConnectedBlock" *ngIf="room?.id && !(timers | CountersCompleted) && userDisconnectedRoomId === room?.id" [ngClass]="{autoConnect: autoConnect}">
      <div class="infoTxt noBG" *ngIf="autoConnect" [innerHTML]="'Live.Live_Disconnected'|trans"></div>
      <button class="reconnectBtn" (click)="reconnect()">{{'Live.Live_Reconnect'|trans}}</button>
    </div>
    <div class="connectBlock" *ngIf="!autoConnect && room?.id && !(timers | CountersCompleted) && !userConnectedRoomId && !userDisconnectedRoomId">
      <button class="reconnectBtn" (click)="reconnect()">{{'Live.Live_Reconnect'|trans}}</button>
    </div>
    <div class="bottomBlock" *ngIf="showHelpDeskSupportLink">
      <div *ngIf="!helpDeskRoomActive">
        <div class="desc">{{'Live.Help_Desk_Connect_Title'|trans}}</div>
        <button (click)="showHelpDeskRoom()">{{'Live.Help_Desk_Connect'|trans}}</button>
      </div>
      <div *ngIf="helpDeskRoomActive">
        <button (click)="leaveHelpDeskRoom()">{{'Live.Leave_Help_Desk'|trans}}</button>
      </div>
    </div>
  `,
  styleUrls: ['./jitsiMeet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitsiMeetComponent implements OnInit, OnChanges, OnDestroy {

  // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe

  @Input() provider: OnlineMeetingProvider | undefined = OnlineMeetingProvider.Jitsi;

  @Input() helpDeskRoomId = 'helpDesk';

  @Input() room: { id?: string; token?: string; domain?: string } | undefined;

  @Input() startWithVideoMuted = false;

  @Input() title: string | undefined;

  @Input() subTitle: string | undefined;

  @Input() nick: string | undefined;

  @Input() hideTopBlock = false;

  @Input() autoConnect = false;

  @Input() enableChat = false;

  @Input() enableClosedCaptions = false;

  @Input() enableDownload = false;

  @Input() enableMuteEveryone = false;

  @Input() enableMuteVideoEveryone = false;

  @Input() enableInviteFunctions = false;

  @Input() disableProfile = false;

  @Input() enableFeedback = false;

  @Input() enableSharedVideo = false;

  @Input() enableSelectBackground = false;

  @Input() enableVideoQuality = false;

  @Input() enableLiveStreaming = false;

  @Input() enableRecording = false;

  @Input() enableEmbedding = false;

  @Input() enableSecurity = false;

  @Input() defaultTitle: string | undefined;

  @Input() avatarUrl: string | undefined;

  @Input() showHelpDeskSupportLink = false;

  @Input() useTileView = false;

  @Input() timers: ReadonlyArray<IScheduleLiveStateMeetingTimer> | undefined;

  @Input() defaultJitsiDomain = 'meet.jit.si';

  @Output()
  timerElapsed = new EventEmitter<IScheduleLiveStateMeetingTimer>();

  @Output()
  loaded = new EventEmitter<void>();

  @Output()
  hangUp = new EventEmitter<void>();

  @Output()
  passwordRequired = new EventEmitter<void>();

  @ViewChild('jitsiContainer', {static: false})
  set jitsiContainer(value: ElementRef) {
    const oldEl = this._jitsiContainer ? this._jitsiContainer.nativeElement : null;
    const newEl = value ? value.nativeElement : null;
    this._jitsiContainer = value;
    if (oldEl !== newEl) {
      this.connect();
    }
  };


  isLoading = false;

  userDisconnectedRoomId: string | undefined;

  userConnectedRoomId: string | undefined;

  helpDeskRoomActive = false;

  @HostBinding('class.completed')
  get meetingCompleted() {
    return CountersCompletedPipe.isCompleted(this.timers, this._formatDateService);
  }

  // get meetingUrl(): SafeResourceUrl {
  //     if (!this.roomId) {
  //         return null;
  //     }
  //     const url = `https://${this.meetDomain || this.defaultMeetDomain}/${this.roomId}#config.startWithVideoMuted=${!!this.startWithVideoMuted}`;
  //     return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  // }

  private get defaultMeetDomain(): string {
    return this.provider === OnlineMeetingProvider.Jitsi ? this.defaultJitsiDomain : '8x8.vc';
  }

  private _jitsiInstance: JitsiInstance | undefined;
  private _jitsiContainer: ElementRef | undefined;

  constructor(private _sanitizer: DomSanitizer, private _formatDateService: FormatDateService, private _cdr: ChangeDetectorRef, private _jitsiAPIService: JitsiAPIService, private _cultureService: CultureService) {
  }

  ngOnInit() {
    if (this.autoConnect) {
      this.connect();
    }
  }

  ngOnDestroy() {
    this.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let shouldConnectToRoom = false;
    // eslint-disable-next-line guard-for-in
    for (const propName in changes) {
      if (propName === 'meetDomain' || propName === 'roomId' || propName === 'jwt') {
        shouldConnectToRoom = true;
      }

      if (propName === 'nick' && this._jitsiInstance) {
        this._jitsiInstance.displayName = changes[propName].currentValue;
      }

      if (propName === 'avatarUrl' && this._jitsiInstance) {
        this._jitsiInstance.avatarUrl = changes[propName].currentValue;
      }
    }
    if (shouldConnectToRoom && this.autoConnect) {
      this.connect();
    }
  }

  frameLoaded() {
    // this.isLoading = false;
    // this._cdr.markForCheck();
    console.log('Jitsi iframe loaded.');
    if (this.helpDeskRoomActive) {
      // room in lobby mode dont send 'participantJoined' event, before admin dont confirms join request
      this.onJitsiRoomLoaded();
    }
  }

  meetingTimerReached(timer: IScheduleLiveStateMeetingTimer) {
    this.timerElapsed.emit(timer);
    setTimeout(() => {
      // modify timers to allow change detection for connected pure pipes
      if (this.timers) {
        this.timers = [
          ...this.timers
        ];
      }
      this._cdr.markForCheck();
    });
  }

  trackTimer(index: number, timer: IScheduleLiveStateMeetingTimer) {
    return `${timer.title || ''}_${timer.duration}`;
  }

  reconnect() {
    this.userDisconnectedRoomId = undefined;
    this.userConnectedRoomId = undefined;
    this.connect();
  }

  showHelpDeskRoom() {
    this.disconnect();
    this.helpDeskRoomActive = true;
    this.reconnect();
  }

  leaveHelpDeskRoom() {
    this.disconnect();
    this.helpDeskRoomActive = false;
    this.reconnect();
  }

  // pass rtmpStreamKey in format {rtmp url}/{key}
  startStreaming(provider: 'youtube' | 'other', rtmpStreamKey: string, rtmpBroadcastID?: string) {
    const baseOptions = {
      mode: `stream`,
      // dropboxToken: string, //dropbox oauth2 token.
      shouldShare: true, // whether the recording should be shared with the participants or not. Only applies to certain jitsi meet deploys.
      // rtmpStreamKey, // the RTMP stream key.
      // rtmpBroadcastID, // the RTMP broadcast ID.
      // youtubeStreamKey: string, //the youtube stream key.
      // youtubeBroadcastID: string //the youtube broadcast ID.
    };
    const options = provider === 'youtube' ? {
      ...baseOptions,
      youtubeStreamKey: rtmpStreamKey, // the youtube stream key.
      youtubeBroadcastID: rtmpBroadcastID // the youtube broadcast ID.
    } : {
      ...baseOptions,
      rtmpStreamKey, // the youtube stream key.
      rtmpBroadcastID // the youtube broadcast ID.
    };
    this._jitsiInstance?.executeCommand('startRecording', options);
  }

  stopStreaming() {
    this._jitsiInstance?.executeCommand('stopRecording', 'stream');
  }


  private connect() {
    if (this.room && this.userDisconnectedRoomId === this.room?.id) {
      return;
    }
    this.userDisconnectedRoomId = undefined;
    this.disconnect();
    this.userConnectedRoomId = this.room?.id ?? undefined;
    this._cdr.markForCheck();
    if (this.room && this._jitsiContainer) {
      this.isLoading = true;
      this._cdr.markForCheck();
      this._cdr.detectChanges();
      if (this.provider === OnlineMeetingProvider.Jaas && !this.room) {
        console.log(`Waiting for jwt token ...`);
        return;
      }
      console.log(`Opening room: ${this.room?.id}`);
      setTimeout(() => {
        const jitsiIframe = this._jitsiContainer!.nativeElement.querySelector('.jitsiIframe');
        jitsiIframe.innerHTML = '';
        this._jitsiAPIService.connectToRoom(jitsiIframe,
          {
            roomName: (this.helpDeskRoomActive ? this.helpDeskRoomId : this.room?.id) ?? undefined,
            jwt: this.room?.token,
            onload: this.frameLoaded.bind(this),
            applyCustomConfigs: !this.helpDeskRoomActive, // !this.meetDomain,
            customConfigs: {
              disableProfile: this.disableProfile,
              disableInviteFunctions: !this.enableInviteFunctions,
              defaultLanguage: this._cultureService.currentCulture // https://developer.8x8.com/jaas/docs/customize-ui-default-language
            },
            buttonsConfig: {
              chat: this.enableChat,
              closedcaptions: this.enableClosedCaptions,
              download: this.enableDownload,
              'mute-everyone': this.enableMuteEveryone,
              'mute-video-everyone': this.enableMuteVideoEveryone,
              feedback: this.enableFeedback,
              sharedvideo: this.enableSharedVideo,
              'select-background': this.enableSelectBackground,
              security: this.enableSecurity,
              embedmeeting: this.enableEmbedding,
              videoquality: this.enableVideoQuality,
              recording: this.enableRecording,
              livestreaming: this.enableLiveStreaming
            },
            userInfo: {
              displayName: this.nick
            }
          },
          this.room?.domain || this.defaultMeetDomain).subscribe(jitsiInstance => {

          this._jitsiInstance = jitsiInstance;

          jitsiInstance.joined.subscribe(() => {
            jitsiInstance.subject = ' '; // hide meeting room name in jitsi component
            this.onJitsiRoomLoaded();
          });
          jitsiInstance.kickedOut.subscribe(() => {
            if (this.helpDeskRoomActive) {
              this.leaveHelpDeskRoom();
            }
          });
          jitsiInstance.passwordRequired.subscribe(() => {
            this.passwordRequired.emit();
          });

          jitsiInstance.hangUp.subscribe(() => {
            console.log('Hang up');
            if (this.helpDeskRoomActive) {
              this.leaveHelpDeskRoom();
            } else {
              this.userDisconnectedRoomId = jitsiInstance.roomName;
              this.userConnectedRoomId = undefined;
              this._cdr.markForCheck();
            }
            this.hangUp.emit();
          });

          jitsiInstance.subject = ' '; // hide meeting room name in jitsi component

        });
      });
    }
  }

  private disconnect() {
    if (this._jitsiInstance) {
      this._jitsiInstance.close();
      this._jitsiInstance = undefined;
    }
  }

  private onJitsiRoomLoaded() {
    console.log('Joined to room');
    if (this._jitsiInstance) {
      this._jitsiInstance.avatarUrl = this.avatarUrl;
      this.loaded.emit();
      this.isLoading = false;
      this._cdr.markForCheck();
      if (this.useTileView && !this.helpDeskRoomActive) {
        this._jitsiInstance.toggleTileView();
      }
      if (this.helpDeskRoomActive) {
        this._jitsiInstance.toggleChat();
      }
    }
  }
}
