import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ILoadingState } from '../interfaces/loadingState.interface';

export function withLoadingState<T>(): (
  source: Observable<T>
) => Observable<ILoadingState<T>>;
export function withLoadingState<T, K>(
  mapResult: (result: T) => K
): (source: Observable<T>) => Observable<ILoadingState<K>>;
export function withLoadingState<T, K>(
  mapResult: (result: T) => K
): (source: Observable<T>) => Observable<ILoadingState<K>>;
export function withLoadingState<T, K>(
  mapResult?: (result: T) => K
): (
  source: Observable<T>
) => Observable<ILoadingState<T | K>> {
  return (source: Observable<T>) =>
    source.pipe(
      map((result) =>
          ({ isLoading: false, value: result } as ILoadingState<T>)
      ),
      startWith({ isLoading: true } as ILoadingState<T>)
    );
}
