import { Inject, Injectable, Optional } from '@angular/core';
import { CORE_CONFIGURATION, ICoreModuleConfig } from './coreModuleConfig';

/**
 * The available options to set the Level of the Logger.
 */
export enum Level {
  OFF = 'OFF',
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  LOG = 'LOG'
}

@Injectable({providedIn: 'root'})
export class Logger {
  level: Level;

  constructor(@Optional() @Inject(CORE_CONFIGURATION) coreConfig: ICoreModuleConfig) {
    const configLevel = coreConfig?.logLevel;
    this.level = configLevel ? Level[configLevel] : Level.OFF;
  }

  log(message: string) {
    this.logWithLevel(message, Level.LOG);
  }

  debug(message: string) {
    this.logWithLevel(message, Level.DEBUG);
  }

  info(message: string) {
    this.logWithLevel(message, Level.INFO);
  }

  warn(message: string) {
    this.logWithLevel(message, Level.WARN);
  }

  error(message: string) {
    this.logWithLevel(message, Level.ERROR);
  }

  logWithLevel(message: string, level: Level) {
    if (level <= this.level) {
      console.log(message);
    }
  }
}
