import {
  AfterViewInit, ApplicationRef, ChangeDetectionStrategy, Component, ComponentRef, EnvironmentInjector, Injector, Input,
  OnChanges, ViewChild, ViewContainerRef
} from '@angular/core';
import { NgChanges } from '@myia/ngx-core';
import {
  AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe, I18nPluralPipe, I18nSelectPipe, JsonPipe, KeyValuePipe, LowerCasePipe,
  NgClass, NgComponentOutlet, NgForOf, NgIf, NgPlural, NgPluralCase, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault,
  NgTemplateOutlet, PercentPipe, SlicePipe, TitleCasePipe, UpperCasePipe
} from '@angular/common';

@Component({
  selector: 'dynamic-template',
  template: `
    <div class="container" #container></div>
  `,
  styleUrls: ['dynamicTemplate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTemplateComponent implements AfterViewInit, OnChanges {

  constructor(private _environmentInjector: EnvironmentInjector, private _injector: Injector, private _applicationRef: ApplicationRef) {
  }

  @Input()
  content: string | undefined;

  @Input()
  imports: Array<any> | undefined;

  @Input()
  data: any | undefined;

  @ViewChild(
    'container',
    {read: ViewContainerRef, static: false}
  ) container: ViewContainerRef | undefined;

  private _componentRef: ComponentRef<any> | undefined;

  private static getStyles(content: string, stylesReg: RegExp) {
    let m;
    const styles: Array<string> = [];
    // eslint-disable-next-line no-cond-assign
    while ((m = stylesReg.exec(content)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === stylesReg.lastIndex) {
        stylesReg.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      if (m.length > 1) {
        styles.push(m[1]);
      }
    }
    return styles;
  }

  ngAfterViewInit() {
    this.compileContent();
  }

  ngOnChanges(changes: NgChanges<DynamicTemplateComponent>) {
    const compileContent = changes?.content && !changes.content.firstChange;
    if (!compileContent) {
      if (this._componentRef && changes?.data && !changes.data.firstChange) {
        // Modifying the property and triggering change detection.
        this._componentRef.instance.data = changes.data.currentValue;
        this._componentRef.changeDetectorRef.markForCheck();
      }
    } else {
      this.compileContent();
    }
  }

  private compileContent() {
    if (!this.content) {
      throw new Error('No dynamic content found!');
    }
    if (!this.container) {
      throw new Error('No container for dynamic content found!');
    }
    const data = this.data;
    const stylesReg = /<style>([^<]*)<\/style>/gis;
    const styles = DynamicTemplateComponent.getStyles(this.content, stylesReg);
    const content = this.content.replace(stylesReg, ''); // remove styles from content
    const component = Component({
      template: content,
      standalone: true,
      imports: [
        // CommonModule,
        // Following module components/pipes.directives must be implicitly imported - https://github.com/angular/angular/issues/44660
        NgClass, NgComponentOutlet, NgForOf, NgIf, NgTemplateOutlet, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault, NgPlural,
        NgPluralCase, AsyncPipe,UpperCasePipe,LowerCasePipe,JsonPipe,SlicePipe,DecimalPipe,PercentPipe,TitleCasePipe,CurrencyPipe,
        DatePipe,I18nPluralPipe,I18nSelectPipe,KeyValuePipe,

        ...(this.imports ? this.imports : []),
      ],
      host: {class: 'templateContent'},
      styles,
    })(class {

      data: any = data;
    });

    // remove current component
    if (this._componentRef) {
      this.container.clear();
      this._componentRef.destroy();
      this._componentRef = undefined;
    }

    // Create the component and add to the view.
    this._componentRef = this.container.createComponent(component, {
      environmentInjector: this._environmentInjector
    });

    this._componentRef.changeDetectorRef.markForCheck();
  }
}
