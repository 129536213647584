import { Observable, Observer, of } from 'rxjs';
import { saveAs } from 'file-saver';

export interface IReadFile {
  name: string;
  size: number;
  type: string;
  readMode: ReadMode;
  content: any;
  underlyingFile: File;
}

export enum ReadMode {
  arrayBuffer,
  binaryString,
  dataURL,
  text
}

export class ReadFileImpl implements IReadFile {
  get name(): string {
    return this._underlyingFile.name;
  }

  get size(): number {
    return this._underlyingFile.size;
  }

  get type(): string {
    return this._underlyingFile.type;
  }

  get readMode(): ReadMode {
    return this._readMode;
  }

  get content(): any {
    return this._content;
  }

  get underlyingFile(): File {
    return this._underlyingFile;
  }

  constructor(
    private _underlyingFile: File,
    private _readMode: ReadMode,
    private _content: any
  ) {
  }
}

export function readFile(file: File, readMode: ReadMode): Observable<IReadFile> {
  return new Observable((observer: Observer<IReadFile>) => {
    const reader = new FileReader();

    reader.onload = (loaded: ProgressEvent) => {
      const fileReader = loaded.target as FileReader;
      const readFileImpl = new ReadFileImpl(file, readMode, fileReader.result);

      observer.next(readFileImpl);
      observer.complete();
    };

    reader.onerror = (err) => {
      observer.error(err);
      observer.complete();
    };

    switch (readMode) {
      case ReadMode.arrayBuffer:
        reader.readAsArrayBuffer(file);
        break;
      case ReadMode.binaryString:
        reader.readAsBinaryString(file);
        break;
      case ReadMode.text:
        reader.readAsText(file);
        break;
      case ReadMode.dataURL:
      default:
        reader.readAsDataURL(file);
        break;
    }
  });
}

export function getFileDataUrl(file: any): Observable<string> {
  return new Observable((observer: Observer<string>) => {
    // URL.createObjectURL don't work in some cases
    // if (typeof(URL) === 'undefined') {
    // locally convert do dataUrl to display in page
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const photoUrl = e.target.result;
      observer.next(photoUrl);
      observer.complete();
    };
    reader.onerror = (err: any) => {
      observer.error(err);
    };
    reader.readAsDataURL(file);
    // }
    // else {
    //     const photoUrl = URL.createObjectURL(file);
    //     observer.next(photoUrl);
    //     observer.complete();
    // }
  });
}

export function getFileTextContent(file: any): Observable<string> {
  if (typeof file === 'string') { // there could be sent directly file content for debugging purposes
    return of(file);
  }
  return new Observable((observer: Observer<string>) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result;
      observer.next(fileContent);
      observer.complete();
    };
    reader.onerror = (err: any) => {
      observer.error(err);
    };
    reader.readAsText(file);
  });
}

export function getFileObjectUrl(file: any): Observable<string> {
  return of(URL.createObjectURL(file));
}

export function revokeFileObjectUrl(objectUrl: string) {
  URL.revokeObjectURL(objectUrl);
}

export function saveFileAs(blob: Blob, fileName: string) {
  saveAs(blob, fileName);
}
