<div class="colorPickerContentBlock" #field (click)="toggle()">
  <ng-content></ng-content>
</div>
<drop-down #popup [for]="field" [fullHeight]="false" [popupOffset]="{x: 0, y: 10}" [showArrow]="true" className="colorDropDown" [defaultSide]="popupSide" (visibilityChanged)="pickerVisibilityChanged($event)">
  <div class="dropDownContent">
    <div class="topBlock">
      <div class="leftBlock">
        <color-wheel-picker [color]="selectedColor" (colorChanged)="pickerColorChanged($event)" [width]="200" [height]="200"></color-wheel-picker>
        <div class="hex">
          <label for="hexColor">HEX</label>
          <div class="hexColorControls"><span class="hexSign">#</span><input id="hexColor" type="text" [ngModel]="selectedColor | hexColorValue" (ngModelChange)="pickerColorChanged('#' + $event)"><button class="colorCopyBtn iconBtn" (click)="copyHexColorToClipboard()"></button></div>
        </div>
      </div>
      <div class="rightBlock">
        <div class="rbgBlock">
          <div class="R">
            <label for="RColor">R</label>
            <input id="RColor" type="text" [ngModel]="editedRGBValue?.r" (ngModelChange)="rgbColorChanged({r: $event})">
          </div>
          <div class="G">
            <label for="GColor">G</label>
            <input id="GColor" type="text" [ngModel]="editedRGBValue?.g" (ngModelChange)="rgbColorChanged({g: $event})">
          </div>
          <div class="B">
            <label for="BColor">B</label>
            <input id="BColor" type="text" [ngModel]="editedRGBValue?.b" (ngModelChange)="rgbColorChanged({b: $event})">
          </div>
        </div>
        <button type="button" class="clearBtn" *ngIf="nullable && selectedColor" (click)="clear($event)"></button>
      </div>
    </div>
    <div class="bottomBlock">
      <div class="palette" *ngIf="palette?.length">
        <div class="color" *ngFor="let color of palette" [ngStyle]="{backgroundColor: color}" (click)="selectColor(color)"></div>
      </div>
    </div>
  </div>
</drop-down>
