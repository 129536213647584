import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { DialogTemplateStore } from './dialogTemplatesStore';

@Directive({
    selector: '[dialogIdentifier]'
})
export class DialogIdentifierDirective implements OnInit, OnDestroy {
    @Input() dialogIdentifier!: string;

    constructor(private _templateRef: TemplateRef<any>, private _dialogTemplateStore: DialogTemplateStore) {
    }

    ngOnInit() {
        this._dialogTemplateStore.addTemplate(this.dialogIdentifier, this._templateRef);
    }

    ngOnDestroy() {
        this._dialogTemplateStore.removeTemplate(this.dialogIdentifier);
    }

}
