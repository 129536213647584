import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnChanges } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerComponent } from './picker.component';

@Component({
  selector: 'enum-picker',
  templateUrl: './enumPicker.component.html',
  styleUrls: ['./enumPicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnumPickerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnumPickerComponent<TEnum, TEnumKey extends (keyof TEnum) & string = keyof TEnum & string> extends PickerComponent<TEnumKey> implements ControlValueAccessor, OnChanges {
  @Input() selectedItem?: TEnumKey & string;
  @Input() enumType?: TEnum;
  @Input() prefix = '';

  items?: Array<TEnumKey & string>;

  @HostBinding('class') hostClasses = 'enumPicker formControl';

  touched = false;
  disabled = false;

  private _onChange?: (item: TEnumKey) => void;
  private _onTouched?: () => void;

  override toggle() {
    if (!this.disabled) {
      super.toggle();
    }
  }

  ngOnChanges(changes: NgChanges<EnumPickerComponent<TEnum>, EnumPickerComponent<TEnum>>) {
    if (changes.enumType) {
      const enumType = changes.enumType.currentValue as any;
      this.items = Object.keys(enumType).map(k => enumType[k])
    }
  }

  selectEnumItem(item: TEnumKey) {
    this.markAsTouched();
    if (!this.disabled) {
      this.selectedItem = item;
      if (this.enumType) {
        const selectedEnumValue = this.enumType[item] as any;
        if (this._onChange) {
          this._onChange(selectedEnumValue);
        }
        this.itemSelected.emit(selectedEnumValue);
      }
    }
    this.hidePopup();
  }

  registerOnChange(onChange: (value: TEnumKey) => void) {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(value: TEnumKey): void {
    this.selectedItem = value;
  }

  markAsTouched() {
    if (!this.touched) {
      if (this._onTouched) {
        this._onTouched();
      }
      this.touched = true;
    }
  }
}
