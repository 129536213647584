import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';

import { IReduxConfig } from './IReduxConfig';
import { reduxStorage } from './reduxStorage';
import { REDUX_MODULE_CONFIG, REDUX_MODULE_PERSISTED_REDUCERS, REDUX_MODULE_REDUCERS, REDUX_PERSIST_CONFIG } from './reduxStore.config';
import { filterPrefixedPropertiesTransform, setReduxStorageKeys } from './reduxStore.persistance';

export interface ReduxModuleConfig {
  config?: Provider;
}

function persistenceReconciler<State>(inboundState: State, originalState: State, reducedState: State): State {
  // use state reduced by reducer when REHYDRATE action is called (it could merge persisted state and current state by custom logic)
  return reducedState;
}

export function getReduxPersistConfig(reduxConfig: IReduxConfig, persistedReducersProviders: Array<Array<string>>) {
  const persistedReducers = ([] as Array<string>).concat(...persistedReducersProviders);
  const keyPrefix = reduxConfig.reduxStorageKeyPrefix || '';
  setReduxStorageKeys(reduxConfig.reduxStorageKey, keyPrefix);
  return {
    keyPrefix: '',
    key: reduxConfig.reduxStorageKey,
    whitelist: persistedReducers, // these store states will persisted
    transforms: [filterPrefixedPropertiesTransform()],
    stateReconciler: persistenceReconciler,
    storage: reduxStorage.config('auto', reduxConfig.reduxStorageKeyPrefix ?? null)
  };
}

export function provideReducers(providedReducers: () => Array<any>): Array<any> {
  return [
    {
      provide: REDUX_MODULE_REDUCERS,
      multi: true,
      useFactory: providedReducers
    }
  ];
}

export function providePersistedReducersNames(providedReducersNames?: () => Array<string>): Array<any> {
  return [
    providedReducersNames ? {
      provide: REDUX_MODULE_PERSISTED_REDUCERS,
      multi: true,
      useFactory: providedReducersNames
    } : {
      provide: REDUX_MODULE_PERSISTED_REDUCERS,
      multi: true,
      useValue: undefined
    }
  ];
}


@NgModule({
  imports: [
    CoreModule
  ]
})
export class ReduxModule {
  static forRoot(
    reducers: () => Array<any>,
    persistedReducers?: () => Array<string>,
    reduxConfigFactory?: ReduxModuleConfig
  ): ModuleWithProviders<ReduxModule> {
    return {
      ngModule: ReduxModule,
      providers: [
        provideReducers(reducers),
        providePersistedReducersNames(persistedReducers),
        reduxConfigFactory?.config,
        {
          provide: REDUX_PERSIST_CONFIG,
          useFactory: getReduxPersistConfig,
          deps: [REDUX_MODULE_CONFIG, REDUX_MODULE_PERSISTED_REDUCERS]
        },
      ] as Provider[]
    };
  }

  static forChild(
    reducers: () => Array<any>,
    persistedReducers?: () => Array<string>
  ): ModuleWithProviders<ReduxModule> {
    return {
      ngModule: ReduxModule,
      providers: [
        provideReducers(reducers),
        providePersistedReducersNames(persistedReducers)
      ]
    };
  }
}

