import { Pipe, PipeTransform } from '@angular/core';
import { TinyColor } from '@ctrl/tinycolor';
import { getOppositeColor } from '../utils/colorUtils';

@Pipe({
  name: 'oppositeColor'
})
export class OppositeColorPipe implements PipeTransform {
  transform(inputColor: string | undefined, bw = false): string {
    if (!inputColor) {
      return '#000';
    }
    const color = new TinyColor(inputColor);
    if (bw) {
      return color.isDark() ? '#ffffff' : '#000000'
    }
    return getOppositeColor(color.toRgb());
  }
}
