import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@myia/ngx-core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { DialogModule } from '@myia/ngx-dialog';
import { LocalizationModule } from '@myia/ngx-localization';
import { TooltipModule } from '@myia/ngx-tooltip';
import { DropDownComponent } from './components/dropDown.component';
import { EnumPickerComponent } from './components/enumPicker.component';
import { InputErrorComponent } from './components/inputError.component';
import { StaticDataPickerComponent } from './components/staticDataPicker.component';
import { ErrorFocusInDirective } from './directives/errorFocusInDirective';
import { FormFieldDirective } from './directives/formFieldDirective';
import { HighlightInvalidFieldsDirective } from './directives/highlightInvalidFieldsDirective';
import { ShowInputErrorsDirective } from './directives/showInputErrorsDirective';
import { ShowInputLabelDirective } from './directives/showInputLabelDirective';
import { FormDataChangedPipe } from './pipes/formDataChangedPipe';

// @dynamic
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    CoreUIModule,
    DialogModule,
    ReactiveFormsModule,
    LocalizationModule,
    TooltipModule,
  ],
  declarations: [
    DropDownComponent,
    EnumPickerComponent,
    ErrorFocusInDirective,
    FormDataChangedPipe,
    FormFieldDirective,
    HighlightInvalidFieldsDirective,
    InputErrorComponent,
    ShowInputErrorsDirective,
    ShowInputLabelDirective,
    StaticDataPickerComponent,
  ],
  exports: [
    DropDownComponent,
    EnumPickerComponent,
    ErrorFocusInDirective,
    FormDataChangedPipe,
    FormFieldDirective,
    HighlightInvalidFieldsDirective,
    InputErrorComponent,
    ShowInputErrorsDirective,
    ShowInputLabelDirective,
    StaticDataPickerComponent
  ]
})
export class FormsModule {
  static forRoot(): ModuleWithProviders<FormsModule> {
    return {
      ngModule: FormsModule
    };
  }
}
