import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CultureService, FormatDateService } from '@myia/ngx-localization';
import { Subscription } from 'rxjs';
import { openNewWindow } from '@myia/ngx-core';
import { CalendarUtils } from '../entities/calendarUtils';
import { ICalendarEvent } from '../entities/calendarEvent.interface';
import { CalendarEventActions } from '../entities/calendarEventActions';
import { ICalendarEventOne2One } from '../entities/calendarEventOne2One.interface';
import { ICalendarEventReservation } from '../entities/calendarEventReservation.interface';

@Component({
  selector: 'calendar-template-default-component',
  styleUrls: ['./calendarTemplateDefault.component.scss'],
  animations: [
    trigger('reservedAnim', [
      transition(':enter', [
        style({transform: 'scaleY(0)'}),
        animate(350)
      ])
    ]),
  ],
  template: `
    <div class="content" [@.disabled]="initializing" *ngIf="event">
      <div class="reserved" *ngIf="event.type === 'Reservation' && eventReservation.reserved" [@reservedAnim] [ngStyle]="{'-webkit-text-stroke-color': color}">
        <div class="fg">{{'CalendarReservation.CalendarReservation_Reserved'|trans}}</div>
        {{'CalendarReservation.CalendarReservation_Reserved'|trans}}</div>
      <div class="planned" *ngIf="event.type === 'One2One' && eventMeeting.planned" [@reservedAnim] [ngStyle]="{'-webkit-text-stroke-color': color}">
        <div class="fg">{{'CalendarReservation.CalendarMeeting_Planned'|trans}}</div>
        {{'CalendarReservation.CalendarMeeting_Planned'|trans}}</div>
      <div class="title" *ngIf="event?.icon || (event|calendarEventTitle) ">
        <svg-icon *ngIf="event?.icon" prefix="calendarIcon" className="eventIcon" [name]="event.icon"></svg-icon>
        <span [innerHTML]="event|calendarEventTitle|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: (event|calendarEventTitle|trans:{_lang_: defaultLanguage, _keyPrefix_: transKeyPrefix, _nullValue_: '', _suffix_: ' <span class=\\'defLang\\'>(' + defaultLanguage + ')</span>'})}"></span><span class="subTitle" *ngIf="event.meta?.subTitle" [innerHTML]="event.meta?.subTitle|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''}"></span><span class="description" *ngIf="event.meta?.description" [innerHTML]="event.meta?.description|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''}"></span>
      </div>
      <div *ngIf="event.meta?.speaker" class="speaker" [innerHTML]="event.meta?.speaker|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''}"></div>
      <div *ngIf="event.type === 'Reservation'" class="reservationBlock">
        <div *ngIf="eventReservation?.reservationDesc" class="reservationDesc">
          <svg-icon name="arrow"></svg-icon>
          <span>{{eventReservation.reservationDesc|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''} }}</span></div>
        <div *ngIf="eventReservation?.reservationLocation" class="reservationLocation">
          <svg-icon name="arrow"></svg-icon>
          <span>{{eventReservation.reservationLocation|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''} }}</span>
        </div>
        <button *ngIf="!eventReservation.reserved" (click)="showReservation()">{{'CalendarReservation.CalendarReservation_Show'|trans}}</button>
        <button *ngIf="eventReservation.reserved" (click)="cancelReservation()" [disabled]="eventReservation.updating">{{'CalendarReservation.CalendarReservation_Cancel'|trans}}</button>
      </div>
      <div *ngIf="event.type === 'One2One'" class="meetingBlock">
        <div *ngIf="eventMeeting?.meetingDesc" class="meetingDesc">
          <svg-icon name="user"></svg-icon>
          <span>{{eventMeeting.meetingDesc|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''} }}</span></div>
        <div *ngIf="eventMeeting?.meetingLocation" class="meetingLocation">
          <svg-icon name="arrow"></svg-icon>
          <span>{{eventMeeting.meetingLocation|trans:{_lang_: language, _keyPrefix_: transKeyPrefix, _nullValue_: ''} }}</span></div>
        <button *ngIf="eventMeeting?.planned && !eventMeeting.isStopped" (click)="moveMeeting($event)" [disabled]="eventMeeting.updating">{{'CalendarReservation.Meeting_Moving'|trans}}</button>
        <button *ngIf="eventMeeting?.planned && !eventMeeting.isStopped" (click)="cancelMeeting($event)" [disabled]="eventMeeting.updating">{{'CalendarReservation.Meeting_Cancel'|trans}}</button>
      </div>
      <div class="time">
        <svg-icon name="clock" prefix="calendarIcon"></svg-icon>
        <span class="timeParts"><span class="timePart">{{event.start | formatDate: localTimeFormat | async }}</span><span class="timePart second">{{event.end | formatDate: localTimeFormat | async}}</span></span>
        <div class="recordingUrl" *ngIf="event.recordingUrl"><button (click)="playRecording($event, event)"><svg-icon name="player-btn"></svg-icon><span>{{'Calendar.Calendar_Recording_Play'|trans}}</span></button></div>
      </div>
    </div>
  `
})
export class CalendarTemplateDefaultComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() event?: ICalendarEvent | ICalendarEventReservation;
  @Input() language?: string;
  @Input() defaultLanguage?: string;
  @Input() edit = false;

  localTimeFormat = 'hh:mm A';

  get eventReservation(): ICalendarEventReservation {
    return this.event as ICalendarEventReservation;
  }

  get eventMeeting(): ICalendarEventOne2One {
    return this.event as ICalendarEventOne2One;
  }

  get color(): string | undefined {
    if (this.event) {
      let color = this.event.color;
      switch (this.event.type) {
        case 'One2One':
          const meeting = this.event as ICalendarEventOne2One;
          color = meeting.planned ? (typeof meeting.plannedColor === 'string' ? meeting.plannedColor : meeting.color) : meeting.color;
      }
      return color;
    }
    return undefined;
  }

  @Output() actionCalled = new EventEmitter<{ action: CalendarEventActions, data?: any }>();

  initializing = false;

  @HostBinding('style')
  get myStyle(): SafeStyle | undefined {
    if (this.event) {
      const styles = `background: ${this.color};`;
      return this.sanitizer.bypassSecurityTrustStyle(styles);
    }
    return undefined;
  }

  @HostBinding('class') get className(): string {
    return `calendarEvent${this.event && this.event.meta && this.event.meta.css ? ' ' + this.event.meta.css : ''}`;
  }

  @HostBinding('class.selected') get isSelected() {
    return this.event && (this.event.selected || this.eventReservation.reserved || this.eventMeeting.planned);
  }

  @HostBinding('class.selectionDisabled') get isSelectionDisabled() {
    return !this.edit && this.event && (this.event.type === 'Reservation' || this.event.type === 'One2One' || this.event.type === 'Info');
  }

  transKeyPrefix = CalendarUtils.TRANSLATION_KEY_PREFIX;

  private _onCultureChange: Subscription | undefined;

  constructor(private sanitizer: DomSanitizer, private _formatDateService: FormatDateService, private _cultureService: CultureService) {
  }

  ngOnInit() {
    this.initializing = true;
    this.updateLocalTimeFormat();
    this._onCultureChange = this._cultureService.onChange.subscribe(() => {
      this.updateLocalTimeFormat();
    });
  }

  ngOnDestroy() {
    if (this._onCultureChange) {
      this._onCultureChange.unsubscribe();
      this._onCultureChange = undefined;
    }
  }



  ngAfterViewInit() {
    this.initializing = false;
  }

  @HostListener('click', [])
  onClick() {
    if (!this.isSelectionDisabled) {
      this.actionCalled.emit({action: CalendarEventActions.SelectionChanged, data: !this.event?.selected});
    } else {
      this.actionCalled.emit({action: CalendarEventActions.InActiveEventClicked});
    }
  }

  showReservation() {
    this.actionCalled.emit({action: CalendarEventActions.CreateReservation, data: this.eventReservation.reservationId});
  }

  cancelReservation() {
    this.actionCalled.emit({action: CalendarEventActions.CancelReservation, data: this.eventReservation.reservationId});
  }

  cancelMeeting($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.actionCalled.emit({action: CalendarEventActions.CancelMeeting, data: this.eventMeeting});
  }

  moveMeeting($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.actionCalled.emit({action: CalendarEventActions.MoveMeeting, data: this.eventMeeting});
  }

  playRecording($event: MouseEvent, event: ICalendarEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    openNewWindow(event.recordingUrl as string);
  }

  private updateLocalTimeFormat() {
    this.localTimeFormat = this._formatDateService.getCurrentLocalTimeFormat({withZeroPadding: true});
  }

}
