import { readOnlyEntity } from '@myia/ngx-core';
import { ICalendar } from './calendar.interface';
import { CalendarEvent } from './calendarEvent';
import { CalendarEventOne2One } from './calendarEventOne2One';
import { ICalendarEventOne2One } from './calendarEventOne2One.interface';
import { CalendarEventReservation } from './calendarEventReservation';
import { ICalendarEventReservation } from './calendarEventReservation.interface';
import { CalendarIdPrefixes } from './calendarIdPrefixes';
import { CalendarSection } from './calendarSection';
import { CalendarSectionBlock } from './calendarSectionBlock';
import { CalendarSectionBlockTrack } from './calendarSectionBlockTrack';
import { CalendarUtilsInternal } from './calendarUtilsInternal';

export function Calendar(props: ICalendar): ICalendar {
  return readOnlyEntity({
    // @ts-ignore: TS2783
    id: props.id ? undefined : CalendarUtilsInternal.newCalendarEntityId(CalendarIdPrefixes.calendar),
    eventTemplates: undefined,
    translations: undefined,
    // @ts-ignore: TS2783
    sections: undefined,
    ...props
  });
}

// @dynamic
export class CalendarFactory {
  static fromJS(calendar: ICalendar): ICalendar {
    return Calendar({
      id: calendar.id,
      eventTemplates: calendar.eventTemplates,
      translations: calendar.translations,
      sections: calendar.sections.map(section =>
        CalendarSection({
          ...section,
          blocks: section.blocks.map(block =>
            CalendarSectionBlock({
              ...block,
              tracks: block.tracks.map(track =>
                CalendarSectionBlockTrack({
                  ...track,
                  events: track.events.map(event => {
                      switch (event.type) {
                        case 'Reservation':
                          return CalendarEventReservation(event as ICalendarEventReservation);
                        case 'One2One':
                          const meetingEv = event as ICalendarEventOne2One;
                          return CalendarEventOne2One(meetingEv);
                      }
                      return CalendarEvent(event);
                    }
                  )
                })
              )

            })
          )
        })
      )
    });
  }
}

