import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { NgChanges } from '@myia/ngx-core';

@Component({
  selector: 'svg-icon-data',
  styleUrls: ['./svgIconData.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``
})
export class SvgIconDataComponent implements OnInit, OnChanges {

  @Input() data: string | undefined;
  @Input() className: string | undefined;

  @HostBinding('class') get fullClassName(): string {
    return `iconData ${this.className ? ' ' + this.className : ''}`;
  }

  constructor(private _elementRef: ElementRef) {
  }

  ngOnInit() {
    this.createIcon(this.data);
  }

  ngOnChanges(changes: NgChanges<SvgIconDataComponent>) {
    if (changes.data && changes.data.currentValue) {
      this.createIcon(changes.data.currentValue);
    }
  }

  private createIcon(data: string | undefined) {
      this._elementRef.nativeElement.innerHTML = data?.replace(/fill="([^"]*)"fill\s?=\s?["']([^"']*)["']/gm, 'fill="currentColor"');
  }
}
