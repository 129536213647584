import { Pipe, PipeTransform } from '@angular/core';
import { ICalendarEvent } from '../entities/calendarEvent.interface';

@Pipe({
  name: 'calendarTemplate',
})
export class CalendarEventTemplatePipe implements PipeTransform {

  transform(event: ICalendarEvent, templates: Array<any> | undefined): any {
    let defaultTemplate = null;
    const template = templates?.find(t => {
      if (t.default) {
        defaultTemplate = t;
      }
      return event && t.name === event.template;
    }) || defaultTemplate;
    return template.component;
  }
}
