import {
  AfterViewChecked, AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output
} from '@angular/core';
import { getElementOffset, getParentScroll, NgChanges } from '@myia/ngx-core';
import { ToolTipDirective } from '@myia/ngx-tooltip';

@Directive({
  selector: '[draggableItem]'
})
export class DraggableItemDirective implements OnChanges, AfterViewChecked, AfterViewInit, OnDestroy {

  @Input() left = 0;
  @Input() top = 0;
  @Input() width = 100;
  @Input() height = 10;
  @Input() handleHeight = 4;
  @Input() leftPadding? = 0;
  @Input() snapStepsHorizontal?: Array<number>;
  @Input() snapHeight = 10;
  @Input() parent?: HTMLElement;
  @Input() trackBorderWidth = 0;
  @Input() topPadding = 0;
  @Input() edit = false;

  @Output() dragStart: EventEmitter<void> = new EventEmitter<void>();
  @Output() move: EventEmitter<{ left: number, top: number }> = new EventEmitter<{ left: number, top: number }>();
  @Output() dragEnd: EventEmitter<{ left: number, top: number }> = new EventEmitter<{ left: number, top: number }>();
  @Output() overflowChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() resizeUp: EventEmitter<number> = new EventEmitter<number>();
  @Output() resizeDown: EventEmitter<number> = new EventEmitter<number>();
  @Output() resizeUpEnd: EventEmitter<number> = new EventEmitter<number>();
  @Output() resizeDownEnd: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('class.overflow') isOverflow = false;

  private _draggable: any;
  private _resizeUpDraggable: any;
  private _resizeDownDraggable: any;
  private _resizeStartPos?: number;

  private _draggableViewRect = { left: 0, top: 0 };
  private _overflow = false;

  private _resizeUpHandle?: HTMLElement;
  private _resizeDownHandle?: HTMLElement;

  constructor(private _element: ElementRef, private toolTip: ToolTipDirective) {
  }

  ngAfterViewInit() {
    this.initializeCalendarElement();
  }

  ngOnDestroy(): void {
    if (this._draggable) {
      this._draggable.remove();
      this._draggable = null;
    }
    if (this._resizeUpDraggable) {
      this._resizeUpDraggable.remove();
      this._resizeUpDraggable = null;
    }
    if (this._resizeDownDraggable) {
      this._resizeDownDraggable.remove();
      this._resizeDownDraggable = null;
    }
  }

  ngAfterViewChecked() {
    if (this._draggable) {
      this.updateOptions();
      // setTimeout(() => {
      //   this._draggable.position();
      // }, 0)
    }
  }

  ngOnChanges(changes: NgChanges<DraggableItemDirective>) {
    if (this._draggable) {
      if (changes.left) {
        this._draggable.left = this.left;
      }
      if (changes.topPadding || changes.snapHeight) {
        this._draggable.snap = this.getSnap();
      }
      if (changes.top) {
        this._draggable.top = this.top;
        this._resizeUpDraggable.top = this.top;
        this._resizeDownDraggable.top = this.top + this.height + this.handleHeight;
        // console.log(`item top: ${this.top}`);
      }
      if (changes.edit) {
        this._draggable.disabled = !this.edit;
        this._resizeUpDraggable.disabled = !this.edit;
        this._resizeDownDraggable.disabled = !this.edit;
      }
      if (changes.width) {
        this.setWidth(changes.width.currentValue);
      }
      if (changes.height) {
        this.setHeight(changes.height.currentValue);
        this._resizeDownDraggable.top = this.top + this.height + this.handleHeight;
      }
      this._draggable.position();
    }
  }

  private initializeCalendarElement() {
    // @ts-ignore
    import('plain-draggable').then(({default: plainDraggable}) => {
      this._draggable = new plainDraggable(this._element.nativeElement);
      this.updateOptions();

      this._resizeUpHandle = this._element.nativeElement.querySelector('.itemResizeHandle.up');
      this._resizeUpDraggable = new plainDraggable(this._resizeUpHandle, {
        containment: this.parent,
        top: this.top,
        leftTop: true,
        onDragStart: this.onResizeStart.bind(this),
        onDrag: this.onResizeUpDrag.bind(this),
        onMove: this.onResizeUpMove.bind(this),
        onDragEnd: this.onResizeUpEnd.bind(this),
      });
      this._resizeDownHandle = this._element.nativeElement.querySelector('.itemResizeHandle.down');
      this._resizeDownDraggable = new plainDraggable(this._resizeDownHandle, {
        containment: this.parent,
        top: this.top + this.height + this.handleHeight,
        onDragStart: this.onResizeStart.bind(this),
        onDrag: this.onResizeDownDrag.bind(this),
        onMove: this.onResizeDownMove.bind(this),
        onDragEnd: this.onResizeDownEnd.bind(this),
        leftTop: true
      });
      this._resizeUpDraggable.disabled = !this.edit;
      this._resizeDownDraggable.disabled = !this.edit;

      const parentScroll = getParentScroll(this._element.nativeElement);
      parentScroll?.addEventListener('scroll', () => {
        this.setHeight(this.height); // overwrite height reset by plain-draggable scroll event handler
      });
    });
  }

  private updateOptions() {
    // console.log(`updateOptions: ${this.top}`);
    this._draggable.setOptions({
      containment: this.parent,
      left: this.left,
      top: this.top,
      snap: this.getSnap(),
      onDragStart: this.onDragStart.bind(this),
      onDrag: this.onDrag.bind(this),
      onMove: this.onMove.bind(this),
      onDragEnd: this.onDragEnd.bind(this)
    });
    this._draggable.disabled = !this.edit;
    this._draggable.position();
    this.setWidth(this.width);
    this.setHeight(this.height);
  }

  private onDragStart(startPosition: any) {
    this.dragStart.emit();
    this.hoverItemEl(true);
  }

  private onDrag(newPosition: any) {
    this._draggableViewRect = this.parent ? getElementOffset(this.parent) as any : {left: 0, top: 0};
    const minY = this.topPadding + (this._draggableViewRect ? this._draggableViewRect.top : 0);
    const minX = this._draggableViewRect ? this._draggableViewRect.left : 0;
    // const lPos = newPosition.left - minX + (this._element.nativeElement.offsetWidth * (this.width + this.leftPadding) / 100 / 2);
    // let stepIndex = this.snapStepsHorizontal.findIndex(s => s > lPos);
    // if (stepIndex === -1) {
    //   stepIndex = this.snapStepsHorizontal.length - 1;
    // } else {
    //   if (stepIndex > 0) {
    //     stepIndex--;
    //   }
    // }
    //  console.log(`stepIndex: ${newPosition.left} -> ${stepIndex}, lPos: ${lPos}, snapStepsHorizontal: ${JSON.stringify(this.snapStepsHorizontal)}`);
    //  // newPosition.left = minX + this.snapStepsHorizontal[stepIndex] + stepIndex * this.trackBorderWidth;

    const minXContent = minX + (this.snapStepsHorizontal?.[0] as number);
    if (newPosition.left < minXContent) {
      newPosition.left = minXContent;
    }
    if (newPosition.top < minY) {
      newPosition.top = minY;
    }
  }

  private onMove(newPosition: any) {
    this.move.emit(newPosition);
  }

  private onDragEnd(newPosition: any) {
    this.hoverItemEl(false);
    this.dragEnd.emit(newPosition);
  }

  private onResizeStart(startPosition: any) {
    // console.log(`onResizeStart`);
    this.hoverItemEl(true);
    this._element.nativeElement.classList.add('resizing');
    this.toolTip.tooltipTrigger = 'none';
  }

  private onResizeUpDrag(newPosition: any) {
    if (this.height - (newPosition.top - this.top) < this.snapHeight) {
      newPosition.top = this.top;
    }
    setTimeout(() => {
      this.toolTip.update();
    });
  }

  private onResizeDownDrag(newPosition: any) {
    if (this.height - (this.top - newPosition.top) < this.snapHeight) {
      newPosition.top = this.top;
    }
  }

  private onResizeUpMove(newPosition: any) {
    // console.log(`onResizeUpMove: ${newPosition.top}`);
    this.resizeUp.emit(newPosition.top);
  }

  private onResizeUpEnd(newPosition: any) {
    // console.log(`onResizeUpEnd: ${newPosition.top}`);
    this.hoverItemEl(false);
    this._element.nativeElement.classList.remove('resizing');
    this.resizeUpEnd.emit(newPosition.top);
    this.toolTip.tooltipTrigger = 'hover';
    if (this.toolTip.enable) {
      this.toolTip.enable = false;
      setTimeout(() => {
        this.checkOverflow();
      });
    }
  }

  private onResizeDownMove(newPosition: any) {
    // console.log(`onResizeDownMove: ${newPosition.top}`);
    this.resizeDown.emit(newPosition.top + this._resizeDownHandle?.offsetHeight);
  }

  private onResizeDownEnd(newPosition: any) {
    // console.log(`onResizeDownEnd: ${newPosition.top}`);
    this.hoverItemEl(false);
    this._element.nativeElement.classList.remove('resizing');
    this.resizeDownEnd.emit(newPosition.top + this._resizeDownHandle?.offsetHeight);
    this.toolTip.tooltipTrigger = 'hover';
    if (this.toolTip.enable) {
      this.toolTip.enable = false;
      setTimeout(() => {
        this.checkOverflow();
      });
    }
  }

  private getSnap() {
    return {
      y: {
        start: this.topPadding,
        step: this.snapHeight
      },
      // targets: this.snapStepsHorizontal.map(s => {
      //   return {
      //     x: s,
      //     y: {
      //       start: this.topPadding,
      //       step: this.snapHeight
      //     }
      //   };
      // }),
      gravity: 2000 // To avoid staying between "repeated coordinates", you can specify a number greater than or equal to the half of the step. Or, onDrag option also can be used for the same purpose.
    };
  }

  private setWidth(width: number) {
    this._element.nativeElement.style.width = `${width}%`;
  }

  private setHeight(height: number) {
    this._element.nativeElement.style.height = `${height}px`;
    setTimeout(() => {
      this.checkOverflow();
    });
  }

  private checkOverflow() {
    const isOverflow = this._element.nativeElement.scrollHeight > this._element.nativeElement.offsetHeight;
    this.toolTip.enable = isOverflow;
    // if (this.isOverflow !== isOverflow) {
    //   this.isOverflow = isOverflow;
    //   this.overflowChanged.emit(isOverflow);
    // }
  }

  private hoverItemEl(hover: boolean) {
    const eventEl = this._element.nativeElement.querySelector('.calendarEvent');
    if (eventEl) {
      if (hover) {
        eventEl.classList.add('hover');
      } else {
        eventEl.classList.remove('hover');
      }
    }
  }
}
