import { IHSLColor, IHSVColor, IRGBColor } from './colorTypes';
import { getHexString, getRgbString, hexStringToRgb, hslToHsv, rgbToHsv } from './colorUtils';

// https://github.com/jaames/iro.js

export class Color {
  colorChanged: ((newValue: IHSVColor | undefined, oldValue: IHSVColor | undefined, changed: { h: boolean, s: boolean, v: boolean }) => void) | undefined;

  get hsv(): IHSVColor | undefined {
    return this._hsv;
  }

  set hsv(newValue: IHSVColor | undefined) {
    if (newValue !== this._hsv) {
      let changed: { h: boolean, s: boolean, v: boolean } & { [key: keyof IHSVColor]: boolean } = {
        h: false,
        s: false,
        v: false
      };
      if (newValue) {
        // loop through the channels and check if any of them have changed
        if (this._oldHsv) {
          for (const channel in this._oldHsv) {
            if (this._oldHsv.hasOwnProperty(channel)) {
              if (!newValue.hasOwnProperty(channel)) {
                newValue[channel] = this._oldHsv[channel];
              }
              changed[channel] = newValue[channel] !== this._oldHsv[channel];
            }
          }
        } else {
          changed = { h: true, s: true, v: true };
        }
      }
      this._hsv = newValue;
      // if the value has changed, call hook callback
      if ((changed.h || changed.s || changed.v) && this.colorChanged) {
        this.colorChanged(newValue, this._oldHsv, changed);
      }
      // update the old value
      this._oldHsv = newValue;
    }
  }

  get hexString(): string | undefined {
    return this.rgb ? getHexString(this.rgb) : undefined;
  }

  set hexString(str: string | undefined) {
    this.setFromString(str as string);
  }

  get rgb(): IRGBColor | undefined {
    if (!this.hsv) {
      return undefined;
    }
    const hsv = this.hsv;
    // eslint-disable-next-line one-var
    let r: number, g: number, b: number, i: number, f: number, p: number, q: number, t: number;
    const h = (hsv.h ?? 0) / 360;
    const s = (hsv.s ?? 0) / 100;
    const v = (hsv.v ?? 0) / 100;
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
      case 0:
        r = v; g = t; b = p;
        break;
      case 1:
        r = q; g = v; b = p;
        break;
      case 2:
        r = p; g = v; b = t;
        break;
      case 3:
        r = p; g = q; b = v;
        break;
      case 4:
        r = t; g = p; b = v;
        break;
      case 5:
        r = v; g = p; b = q;
        break;
      default:
        throw new Error('Unsupported color !');
    }
    // eslint-disable-next-line no-bitwise
    return { r: ~~(r * 255), g: ~~(g * 255), b: ~~(b * 255) };
  }

  set rgb(val: IRGBColor | undefined) {
    this.hsv = rgbToHsv(val as IRGBColor);
  }

  get rgbString(): string {
    return getRgbString(this.rgb);
  }

  set rgbString(str: string) {
    this.setFromString(str);
  }

  get hsl(): IHSLColor {
    const hsv = this.hsv;
    let s = (hsv?.s ?? 0) / 100;
    const v = (hsv?.v ?? 0) / 100;
    const p = (2 - s) * v;
    s = s === 0 ? 0 : s * v / (p < 1 ? p : 2 - p);
    // eslint-disable-next-line no-bitwise
    return { h: (hsv?.h ?? 0), s: ~~(s * 100), l: ~~(p * 50) };
  }

  set hsl(val: IHSLColor) {
    this.hsv = hslToHsv(val);
  }

  get hslString(): string {
    const hsl = this.hsl;
    return [hsl.a ? 'hsla' : 'hsl', '(', hsl.h, ', ', hsl.s, '%, ', hsl.l, '%', hsl.a ? ', ' + hsl.a : '', ')'].join('');
  }

  set hslString(str: string) {
    this.setFromString(str);
  }

  private _browserIsIE: boolean;
  private _oldHsv: IHSVColor | undefined;
  private _hsv?: IHSVColor;

  constructor() {
    this._browserIsIE = /MSIE ([0-9]+)/g.test(navigator.userAgent);
  }

  private setFromString(str: string) {
    // (try to) detect the type of the color string using regex -- needs a lof of improvement
    const parsed = str.match(/(^rgba?|^hsla?)(?=\(.*?\))|(^#)(?=[a-f0-9])/i);
    let parsedStr: any;
    // once we have an idea of what the string type is, we can then parse it:
    switch (parsed ? parsed[0] : null) {
      // HEX color notation; e.g #ffff00, #FFFF00, #ff0, etc...
      case '#':
        this.hsv = rgbToHsv(hexStringToRgb(str));
        break;
      // rgb color notation; e.g rgb(255, 255, 0), rgba(255, 255, 0, 1)
      case 'rgb':
      case 'rgba':
        parsedStr = str.match(/(rgba?)\((\d+)(?:\D+?)(\d+)(?:\D+?)(\d+)(?:\D+?)?([0-9\.]+?)?\)/i);
        this.hsv = rgbToHsv({
          // eslint-disable-next-line radix
          r: parseInt(parsedStr[2]),
          // eslint-disable-next-line radix
          g: parseInt(parsedStr[3]),
          // eslint-disable-next-line radix
          b: parseInt(parsedStr[4])
        });
        break;
      // hsl color notation; e.g hsl(60, 100%, 50%), hsla(60, 100%, 50%, 1)
      case 'hsl':
      case 'hsla':
        parsedStr = str.match(/(hsla?)\((\d+)(?:\D+?)(\d+)(?:\D+?)(\d+)(?:\D+?)?([0-9\.]+?)?\)/i);
        this.hsv = hslToHsv({
          // eslint-disable-next-line radix
          h: parseInt(parsedStr[2]),
          // eslint-disable-next-line radix
          s: parseInt(parsedStr[3]),
          // eslint-disable-next-line radix
          l: parseInt(parsedStr[4])
        });
        break;
      default:
        console.warn('Error: \'', str, '\' could not be parsed as a CSS color');
    }
  }
}
