import { AfterViewInit, ComponentRef, Directive, Input, OnChanges, OnDestroy, SimpleChange, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CultureService } from '@myia/ngx-localization';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { InputErrorComponent } from '../components/inputError.component';


@Directive({
  selector: '[showInputErrors]'
})
export class ShowInputErrorsDirective implements AfterViewInit, OnDestroy, OnChanges {
  @Input() showInputErrors: boolean | null = true;
  @Input() errorsIndicator: 'sign' | 'text' | '' = 'sign';
  @Input() formControl?: FormControl;

  private _formControlStateSub?: Subscription;
  private _destroy$ = new Subject<void>();
  private errorElRef: ComponentRef<InputErrorComponent>;

  constructor(private _cultureService: CultureService, vc: ViewContainerRef) {
    // update text when culture changed
    this._cultureService.onChange.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      setTimeout(() => {
        this.checkFormControlValid(this.formControl);
      });
    });
    this.errorElRef = vc.createComponent(InputErrorComponent)
  }

  ngOnChanges(changes: Partial<Record<keyof ShowInputErrorsDirective, SimpleChange>>) {
    if (changes.formControl) {
      this._formControlStateSub?.unsubscribe();
      const formControl = changes.formControl.currentValue as FormControl;
      this._formControlStateSub = formControl.statusChanges.pipe(
        takeUntil(this._destroy$)
      ).subscribe(_ => {
        this.checkFormControlValid(formControl);
      });
      this.checkFormControlValid(formControl);
    }
    if (changes.showInputErrors) {
      this.checkFormControlValid(this.formControl);
    }
  }

  ngAfterViewInit() {
    this.checkFormControlValid(this.formControl);
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private checkFormControlValid(formControl?: FormControl) {
    if (formControl?.valid || !this.showInputErrors) {
      this.errorElRef.instance.errors = null;
    } else {
      this.errorElRef.instance.errors = formControl?.errors ?? null;
    }
    if (this.errorsIndicator) {
      this.errorElRef.instance.indicatorKind = this.errorsIndicator;
    }
    this.errorElRef.changeDetectorRef.markForCheck();
  }
}
