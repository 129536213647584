import { Observable } from 'rxjs';

import { TranslateLoader } from '@ngx-translate/core';

export class TranslateJSONLoader implements TranslateLoader {
  constructor(private loader: (lang: string) => Observable<any>) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.loader(lang);
  }

}
