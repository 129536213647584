import { RandomOptions } from '@ctrl/tinycolor/dist/random';
import { IHSLColor, IHSVColor, IRGBColor } from './colorTypes';
import { random, TinyColor } from '@ctrl/tinycolor';

// convert rgb to hsv:
// e,g, {r:68, g:255, b:155} -> {h:147, s:73, v:100}
// modified from https://gist.github.com/mjackson/5311256#file-color-conversion-algorithms-js-L84
export function rgbToHsv(val: IRGBColor): IHSVColor {
  let r = val.r;
  let g = val.g;
  let b = val.b;
  r /= 255; g /= 255; b /= 255;

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h = 0;
  let s = 0;
  const v = max;

  if (min !== max) {
    s = (delta / max);

    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }

    h /= 6;
  }
  return {h: h * 360, s: s * 100, v: v * 100};
}

export function hsvToRgb(hI: number | IHSVColor, sI?: number, vI?: number) {
  let h = hI as number;
  let s = sI as number;
  let v = vI as number;
  if (arguments.length === 1) {
    const hsv = hI as IHSVColor;
    s = (hsv.s ?? 0)/100; v = (hsv.v ?? 0)/100; h = (hsv.h ?? 0)/360;
  }
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  let r = 0;
  let g = 0;
  let b = 0;
  switch (i % 6) {
    case 0: r = v; g = t; b = p; break;
    case 1: r = q; g = v; b = p; break;
    case 2: r = p; g = v; b = t; break;
    case 3: r = p; g = q; b = v; break;
    case 4: r = t; g = p; b = v; break;
    case 5: r = v; g = p; b = q; break;
  }
  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
}

// convert hsl to hsv:
// e,g, {h:50, s:100, l: 50} -> {h:50, s:100, v:100}
// modified from https://gist.github.com/xpansive/1337890
export function hslToHsv(val: IHSLColor): IHSVColor {
  let s = val.s / 100;
  const l = val.l / 100;
  s *= l < 0.5 ? l : 1 - l;
  return {
    h: val.h,
    // eslint-disable-next-line no-bitwise
    s: ~~((2 * s / (l + s)) * 100),
    // eslint-disable-next-line no-bitwise
    v: ~~((l + s) * 100)
  };
}

// convert a HEX color code to RGB:
// e.g. #FF0000 -> {r:255, g:255, b:255}
export function hexStringToRgb(hex: string): IRGBColor {
  // strip "#"
  hex = hex.replace(/#/g, '');
  // convert to an integer
  // eslint-disable-next-line no-bitwise
  const int = ~~('0x' + hex);
  // if hex is in shorthand format, we need to multiply each channel value by 17
  return hex.length === 3 ? {
    // eslint-disable-next-line no-bitwise
    r: (int >> 8) * 17,
    // eslint-disable-next-line no-bitwise
    g: (int >> 4 & 0xF) * 17,
    // eslint-disable-next-line no-bitwise
    b: (int & 0xF) * 17
  } : {
    // eslint-disable-next-line no-bitwise
    r: int >> 16,
    // eslint-disable-next-line no-bitwise
    g: int >> 8 & 0xFF,
    // eslint-disable-next-line no-bitwise
    b: int & 0xFF
  };
}

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number): string {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function getHexString(rgb: IRGBColor, useShort?: boolean) {
  let int;
  let len;
  // check if value can be compressed to shorthand format (if useShort === true)
  // in shorthand, all channels should be able to be divided by 17 cleanly
  if (useShort && (rgb.r % 17 === 0) && (rgb.g % 17 === 0) && (rgb.b % 17 === 0)) {
    // eslint-disable-next-line no-bitwise
    int = (rgb.r / 17) << 8 | (rgb.g / 17) << 4 | (rgb.b / 17);
    len = 4;
  } else {
    // eslint-disable-next-line no-bitwise
    int = rgb.r << 16 | rgb.g << 8 | rgb.b;
    len = 7;
  }
  const hexString = int.toString(16);
  return '#' + new Array(len - hexString.length).join('0') + hexString;
}

export function getRgbString(rgb: IRGBColor | undefined): string {
  return rgb ? [rgb.a ? 'rgba' : 'rgb', '(', rgb.r, ', ', rgb.g, ', ', rgb.b, rgb.a ? ', ' + rgb.a : '', ')'].join('') : '';
}

export function getOppositeColor(color: { r: number, g: number, b: number }): string {
  // eslint-disable-next-line no-bitwise
  return '#' + ('000000' + ((0xffffff ^ ((color.r << 16) + (color.g << 8) + color.b)).toString(16))).slice(-6);
}

export function randomColor(options?: RandomOptions): string {
  return random(options).toHexString();
}

export function lightenColor(color: string, amount: number): string {
  return new TinyColor(color).lighten(amount).toHexString();
}

