import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { Logger } from '@myia/ngx-core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Observable, Subject } from 'rxjs';

@Component({
    selector: 'modal-dialog',
    template: `
        <ngx-smart-modal #dialog [identifier]="identifier" [customClass]="customClass" [dismissable]="dismissible" [escapable]="dismissible" [closable]="false" class="modal{{customClass ? '-' + customClass : ''}}">
            <ng-container *ngIf="visible" [ngTemplateOutlet]="dialogTemplate || contentTemplate" [ngTemplateOutletContext]="{$implicit: modalDialog}"></ng-container>
        </ngx-smart-modal>

    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent<TDialogData, TDialogResult> {
    @Input() identifier!: string;
    @Input() customClass = '';
    @Input() dismissible = false;
    @Input() canDeactivate:  undefined | (() => Observable<boolean> | boolean);

    dialogData: TDialogData | undefined;
    dialogTemplate: TemplateRef<any> | undefined;
    allowRouting = false;

    result!: Observable<TDialogResult>;
    opened = new Subject<void>();
    closed = new Subject<void>();

    @ViewChild('dialog', {static: true}) ngxModal!: NgxSmartModalComponent;
    @ContentChild(TemplateRef, {static: true}) contentTemplate!: TemplateRef<any>;

    destroyed = false;

    get visible() {
        return this.ngxModal && this.ngxModal.isVisible();
    }

    get data() {
        return this.ngxModal && this.ngxModal.getData();
    }

    get modalDialog() {
        return this;
    }

    constructor(private _logger: Logger) {
    }

    close(result?: TDialogResult) {
        this.ngxModal.setData(result);
        this.ngxModal.close();
    }
}

